import { UserPermissionFeatures, UserPermissionRoles } from '~/api/types';

export enum DefaultGroupNames {
    ADMIN = 'admin',
    MOBILE_MERCHANDIZER = 'mobile merchandizer',
    MOBILE_MANAGER = 'mobile manager'
}

/**
 * A mapping of user permission roles data when managing roles in the `Users and Permissions` page
 */
export const MANAGED_USER_PERMISSION_ROLES = {
    [UserPermissionRoles.EDITOR]: {
        name: UserPermissionRoles.EDITOR,
        icon: 'edit'
    },
    [UserPermissionRoles.VIEWER]: {
        name: UserPermissionRoles.VIEWER,
        icon: 'visibleOn'
    },
    [UserPermissionRoles.NO_ACCESS]: {
        name: UserPermissionRoles.NO_ACCESS,
        icon: 'errorOutline'
    }
};

/**
 * A minimum list of user permission features that will be preset when creating a new user group
 *
 * Some features are intentionally not included
 */
export const CREATE_GROUP_USER_PERMISSION_FEATURES = [
    UserPermissionFeatures.DRIVER_MANAGEMENT,
    UserPermissionFeatures.MOBILE_MANAGER,
    UserPermissionFeatures.MOBILE_MERCHANDISER,
    UserPermissionFeatures.OPTIMIZATION_SETTINGS,
    UserPermissionFeatures.PLANNING,
    UserPermissionFeatures.TASK_MANAGEMENT
];

/**
 * A list of user permission features that will be managed by the `Users and Permissions` page
 *
 * Some features are intentionally not included
 */
export const MANAGED_USER_PERMISSION_FEATURES = [
    UserPermissionFeatures.DRIVER_BOOKING,
    ...CREATE_GROUP_USER_PERMISSION_FEATURES
];

/**
 * A list of user permission features that will be set for the client's admin user group
 *
 * Some features are intentionally not included
 */
export const ADMIN_USER_PERMISSION_FEATURES = [
    UserPermissionFeatures.USER_MANAGEMENT,
    UserPermissionFeatures.DRIVER_MANAGEMENT,
    UserPermissionFeatures.OPTIMIZATION_SETTINGS,
    UserPermissionFeatures.PLANNING,
    UserPermissionFeatures.TASK_MANAGEMENT
];
