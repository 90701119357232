import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { ChangeTaskStatusModalReviseTimeField } from '../ChangeTaskStatusModalReviseTimeField';
import { convertDateTimeToUTCISOString } from '~/utils/date-utils';
import { ChangeTaskStatusModalReviseTimeProps } from './types';
import { AssignmentStatus } from '~/api/types';

import './ChangeTaskStatusModalReviseTime.scss';

export const ChangeTaskStatusModalReviseTime = ({
    status,
    currentArrival,
    currentCompleted,
    revisedArrival,
    revisedCompleted,
    setRevisedArrival,
    setRevisedCompleted
}: ChangeTaskStatusModalReviseTimeProps) => {
    const ROOT_CLASS_NAME = 'change-task-status-modal-revise-time';
    const ALLOW_REVISE_ARRIVAL = [
        AssignmentStatus.IN_PROGRESS,
        AssignmentStatus.COMPLETED,
        AssignmentStatus.CANCELED
    ];
    const ALLOW_REVISE_COMPLETED = [
        AssignmentStatus.COMPLETED,
        AssignmentStatus.CANCELED
    ];
    const showReviseArrival = ALLOW_REVISE_ARRIVAL.includes(status as number);
    const showReviseCompleted = ALLOW_REVISE_COMPLETED.includes(
        status as number
    );
    const showTimeFields = showReviseArrival || showReviseCompleted;

    const { t } = useTranslation('changeTaskStatus');

    const initialDateTime = useMemo(() => {
        const arrivalDateTime = DateTime.fromISO(currentArrival || '');
        const completedDateTime = DateTime.fromISO(currentCompleted || '');

        const isValidArrival = arrivalDateTime.isValid;
        const isValidCompleted = completedDateTime.isValid;

        const now = DateTime.now();

        const defaultArrival = convertDateTimeToUTCISOString(now);
        const initialArrival = isValidArrival
            ? (currentArrival as string)
            : defaultArrival;

        const defaultCompleted = isValidArrival
            ? convertDateTimeToUTCISOString(arrivalDateTime.plus({ hour: 1 }))
            : convertDateTimeToUTCISOString(now.plus({ hour: 1 }));
        const initialCompleted = isValidCompleted
            ? (currentCompleted as string)
            : defaultCompleted;

        return {
            initialArrival,
            initialCompleted
        };
    }, [currentArrival, currentCompleted]);

    if (!showTimeFields) {
        return null;
    }

    return (
        <div
            className={`${ROOT_CLASS_NAME} _fd-column`}
            data-testid={ROOT_CLASS_NAME}
        >
            <div className={`${ROOT_CLASS_NAME}__fields _d-flex`}>
                {showReviseArrival && (
                    <ChangeTaskStatusModalReviseTimeField
                        label={t('reviseTime.arrivalTime')}
                        initialTime={initialDateTime.initialArrival}
                        revisedTime={revisedArrival}
                        setRevisedTime={setRevisedArrival}
                        data-testid={`${ROOT_CLASS_NAME}__arrival`}
                    />
                )}
                {showReviseCompleted && (
                    <ChangeTaskStatusModalReviseTimeField
                        label={t('reviseTime.completedTime')}
                        initialTime={initialDateTime.initialCompleted}
                        revisedTime={revisedCompleted}
                        setRevisedTime={setRevisedCompleted}
                        data-testid={`${ROOT_CLASS_NAME}__completed`}
                    />
                )}
            </div>
        </div>
    );
};
