import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    ManageStopControlMenu,
    StopModalMenuButton,
    TooltipButton
} from '~/ui';
import { resetSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { setSelectedTaskRowId } from '~/reducers/selectedTaskRowIdSlice';

import './ManageStopControlMultiple.scss';

export const ManageStopControlMultiple = ({
    stopList,
    children,
    className,
    controlTitle,
    controlHelp,
    footerButtonTitle,
    footerButtonIcon,
    onClickFooterButton = () => {},
    isTwoPartTask = false,
    ...extra
}) => {
    const ROOT_CLASS_NAME = 'manage-stop-control-multiple';
    const dataTestId = extra['data-testid'] || ROOT_CLASS_NAME;

    const elementClassName = classNames(
        ROOT_CLASS_NAME,
        '_fd-column',
        className
    );
    const mainClassName = classNames(`${ROOT_CLASS_NAME}__main`, {
        [`${className}__main`]: className
    });
    const stopListContainerClassName = classNames(
        `${ROOT_CLASS_NAME}__stop-list-container`,
        '_fd-column',
        {
            [`${className}__stop-list-container`]: className
        }
    );
    const stopListClassName = classNames(`${ROOT_CLASS_NAME}__stop-list`, {
        [`${className}__stop-list`]: className
    });
    const viewMoreClassName = classNames(
        'managestopcontrol__view-more',
        `${ROOT_CLASS_NAME}__view-more`,
        {
            [`${className}__view-more`]: className
        }
    );
    const footerClassName = classNames(`${ROOT_CLASS_NAME}__footer`, {
        [`${className}__footer`]: className
    });

    const dispatch = useDispatch();
    const { t } = useTranslation('translation');

    const handleClickViewMore = useCallback(() => {
        const { taskId } = stopList[0].props.selectedStop;
        // strip the `pickup` or `delivery` prefix in cases of a two-part task being selected
        const rootTaskId = taskId.replace(/(pickup|delivery)_/, '');

        dispatch(resetSelectedMapStops());
        dispatch(setSelectedTaskRowId(rootTaskId));
    }, [stopList, dispatch]);

    if (!stopList) return null;

    return (
        <div className={elementClassName} data-testid={dataTestId}>
            <div className={mainClassName}>
                {controlTitle && (
                    <span
                        className="_text-2"
                        data-testid={`${dataTestId}__title`}
                    >
                        {controlTitle}
                    </span>
                )}
                {controlHelp && (
                    <div className="_d-flex _ai-center">
                        <span
                            className="_text-3-alt"
                            data-testid={`${dataTestId}__help`}
                        >
                            {controlHelp}
                        </span>
                    </div>
                )}
                <div className={stopListContainerClassName}>
                    <div
                        className={stopListClassName}
                        data-testid={`${dataTestId}__stop-list`}
                    >
                        {stopList}
                    </div>
                </div>
                {isTwoPartTask && (
                    <TooltipButton
                        className={viewMoreClassName}
                        variant={TooltipButton.variants.LINK}
                        onClick={handleClickViewMore}
                        data-testid={`${dataTestId}__view-more`}
                    >
                        {t('Collapsible.view_more')}
                    </TooltipButton>
                )}
            </div>
            {children}
            {footerButtonTitle && (
                <ManageStopControlMenu
                    className={footerClassName}
                    data-testid={`${dataTestId}__footer`}
                >
                    <StopModalMenuButton
                        buttonIcon={footerButtonIcon}
                        buttonText={footerButtonTitle}
                        onClick={onClickFooterButton}
                        data-testid={`${dataTestId}__footer-button`}
                    />
                </ManageStopControlMenu>
            )}
        </div>
    );
};
