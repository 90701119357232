import React from 'react';
import classNames from 'classnames';
import dateUtils from '~/utils/date-utils';
import { IconButton } from '~/ui';
import { IconSizes } from '~/ui/components/Icon/IconSizes';
import { DateTimePickerHeaderProps } from './types';
import './DateTimePickerHeader.scss';

const ROOT_CLASS_NAME = 'date-time-picker-header';

export const DateTimePickerHeader = ({
    date,
    decreaseYear,
    increaseYear,
    decreaseMonth,
    increaseMonth,
    prevYearButtonDisabled,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    nextYearButtonDisabled,
    hideYearButtons,
    className,
    'data-testid': dataTestId
}: DateTimePickerHeaderProps) => {
    const elementClassName = classNames(ROOT_CLASS_NAME, '_d-flex', className);
    const titleClassName = `${ROOT_CLASS_NAME}__title`;
    const buttonClassName = `${ROOT_CLASS_NAME}__button`;
    const buttonDecreaseClassName = `${ROOT_CLASS_NAME}__button-decrease _d-flex`;
    const buttonIncreaseClassName = `${ROOT_CLASS_NAME}__button-increase _d-flex`;

    const buttonDecreaseYearClassName = classNames(
        buttonClassName,
        `${ROOT_CLASS_NAME}__button-decrease-year`
    );
    const buttonDecreaseMonthClassName = classNames(
        buttonClassName,
        `${ROOT_CLASS_NAME}__button-decrease-month`
    );
    const buttonIncreaseMonthClassName = classNames(
        buttonClassName,
        `${ROOT_CLASS_NAME}__button-increase-month`
    );
    const buttonIncreaseYearClassName = classNames(
        buttonClassName,
        `${ROOT_CLASS_NAME}__button-increase-year`
    );

    const elementDataTestId = dataTestId || ROOT_CLASS_NAME;
    const buttonDecreaseYearDataTestId = `${elementDataTestId}__button-decrease-year`;
    const buttonDecreaseMonthDataTestId = `${elementDataTestId}__button-decrease-month`;
    const buttonIncreaseMonthDataTestId = `${elementDataTestId}__button-increase-month`;
    const buttonIncreaseYearDataTestId = `${elementDataTestId}__button-increase-year`;

    return (
        <div className={elementClassName} data-testid={elementDataTestId}>
            <div className={buttonDecreaseClassName}>
                {!hideYearButtons && (
                    <IconButton
                        className={buttonDecreaseYearClassName}
                        data-testid={buttonDecreaseYearDataTestId}
                        icon="chevronLeftDouble"
                        iconSize={IconSizes.M}
                        iconColor="galaxy-500"
                        onClick={decreaseYear}
                        disabled={prevYearButtonDisabled}
                    />
                )}
                <IconButton
                    className={buttonDecreaseMonthClassName}
                    data-testid={buttonDecreaseMonthDataTestId}
                    icon="chevronLeft"
                    iconSize={IconSizes.M}
                    iconColor="galaxy-500"
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                />
            </div>
            <div className={titleClassName}>
                {dateUtils.formatMonthYear(date)}
            </div>
            <div className={buttonIncreaseClassName}>
                <IconButton
                    className={buttonIncreaseMonthClassName}
                    data-testid={buttonIncreaseMonthDataTestId}
                    icon="chevronRight"
                    iconSize={IconSizes.M}
                    iconColor="galaxy-500"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                />
                {!hideYearButtons && (
                    <IconButton
                        className={buttonIncreaseYearClassName}
                        data-testid={buttonIncreaseYearDataTestId}
                        icon="chevronRightDouble"
                        iconSize={IconSizes.M}
                        iconColor="galaxy-500"
                        onClick={increaseYear}
                        disabled={nextYearButtonDisabled}
                    />
                )}
            </div>
        </div>
    );
};
