import React, { WheelEvent } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { selectShowStopLabel } from '~/reducers/mapSettingsSlice';
import { useMapUtils } from '~/hooks';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { StackPinMarkerPopupProps } from './types';
import { MarkerPopupFooter } from './MarkerPopupFooter';

import './StackPinMarkerPopup.scss';

export const StackPinMarkerPopup = ({
    tasksCount,
    children
}: StackPinMarkerPopupProps) => {
    const rootClassName = 'stacked-stop-pin-popup';
    const stackedStops = `${rootClassName}__stacked-stops`;
    const { mapRouteMode } = useMapUtils();
    const isShowStopLabel = useSelector(
        selectShowStopLabel(mapRouteMode as ConfigurableMapRouteMode)
    );

    const handleWheel = (event: WheelEvent<HTMLDivElement>) => {
        /**
         * This function stops the map zooming when scrolling the Pins inside the Popup
         */
        event.stopPropagation();
    };

    const getScrollableClassName = (boxClassName: string) => {
        const conditionalClasses = {
            [`${boxClassName}__scrollable`]: tasksCount > 5,
            [`${boxClassName}--with-labels`]: isShowStopLabel
        };
        return classNames(boxClassName, conditionalClasses);
    };

    return (
        <div className={getScrollableClassName(rootClassName)}>
            <div
                className={getScrollableClassName(stackedStops)}
                onWheel={handleWheel}
                data-testid={stackedStops}
            >
                {children}
            </div>
            <MarkerPopupFooter numTasks={tasksCount} />
        </div>
    );
};
