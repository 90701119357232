import React, { Fragment, useCallback } from 'react';
import LiveDriver from '~/data-classes/dispatched/LiveDriver';
import { groupScheduleByDepots } from '~/components/MapPageDrawers/utils';
import { colorUtils } from '~/utils/color-utils';
import { ApiLiveDriver } from '~/api/types';
import { idUtils } from '~/utils/id-utils';
import { DriverLocationMarker, RouteLine } from '~/ui';
import { selectIsClusteringStops } from '~/reducers/mapSettingsSlice';
import { useMapUtils } from '~/hooks';
import { useSelector } from 'react-redux';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { selectMainClient } from '~/reducers/mainClientSlice';

interface MakeLiveRoutesStopComponentsProps {
    selectedDriver: ApiLiveDriver;
}

export const useMakeLiveRouteLineAndDriverMarker = () => {
    const { mapRouteMode } = useMapUtils();
    const isClusteringStops = useSelector(
        selectIsClusteringStops(mapRouteMode as ConfigurableMapRouteMode)
    );
    const mainClient = useSelector(selectMainClient);
    const { driverPrivacy } = mainClient?.preferences || {};

    const makeLiveRoutesStopComponents = useCallback(
        ({ selectedDriver }: MakeLiveRoutesStopComponentsProps) => {
            const driver = new LiveDriver(selectedDriver);
            const { schedule, clientDriverId } = driver;
            const groupedSchedules = groupScheduleByDepots(schedule);
            const driverColor = colorUtils.getWebColorsForId(clientDriverId);

            const routeLines = groupedSchedules.map((schedules, index) => {
                const [firstStop] = schedules;

                const liveDriver = new LiveDriver({
                    clientId: driver.clientId,
                    id: driver.driverId,
                    stats: {
                        currentStop: 0
                    },
                    cepLocation: firstStop.location,
                    schedule: schedules.map(({ isDepot, location }) => ({
                        isDepot,
                        location: { location: { ...location } }
                    }))
                } as ApiLiveDriver);

                const key = idUtils.getCombinedId(
                    liveDriver.id,
                    index.toString()
                );
                return (
                    <RouteLine
                        key={key}
                        driver={liveDriver}
                        showHistory
                        colorCSS={driverColor}
                        mapRouteMode={mapRouteMode}
                    />
                );
            });

            const driverMarker = (
                <DriverLocationMarker
                    key={driver.id}
                    direction={driver.direction}
                    color={driverColor.backgroundColor}
                    lat={driver.location.lat}
                    lng={driver.location.lng}
                />
            );

            return {
                routeLines: isClusteringStops ? [] : routeLines,
                driverMarker:
                    isClusteringStops || driverPrivacy ? (
                        <Fragment key={driver.id} />
                    ) : (
                        driverMarker
                    )
            };
        },
        [mapRouteMode, isClusteringStops, driverPrivacy]
    );

    return {
        makeLiveRoutesStopComponents
    };
};
