import i18n from 'i18next';
import { ApiInventoryItemStatus, TaskTypes } from '~/api/types';
import { DownloadableReportGroupsMap } from '../types/mapping';
import { partition } from 'lodash';

export const getReportOrder = (reportTypes: string[]) => {
    const reportOrder = Object.keys(DownloadableReportGroupsMap);

    if (!reportTypes.length) return reportOrder;

    const [knownReports, unknownReports] = partition(reportTypes, (type) =>
        reportOrder.includes(type)
    );

    const knownReportsOrder = reportOrder.filter((type) =>
        knownReports.includes(type)
    );
    const unknownReportsOrder = unknownReports.sort();
    const updatedOrder = [...knownReportsOrder, ...unknownReportsOrder];

    return updatedOrder;
};

export const getLocalizedInventoryStatus = (status?: string) => {
    switch (status) {
        case ApiInventoryItemStatus.UNCONFIRMED:
        case ApiInventoryItemStatus.CONFIRMED:
        case ApiInventoryItemStatus.CANCELLED:
        case ApiInventoryItemStatus.PARTIAL:
        case ApiInventoryItemStatus.TRY_LATER:
            return i18n.t(`common:inventoryItemStatus.${status}`);
        default:
            return status;
    }
};

export const getLocalizedTaskType = (status: string) => {
    switch (status) {
        case TaskTypes.DELIVERY:
        case TaskTypes.PICKUP:
        case TaskTypes.TWOPART:
            return i18n.t(`common:taskType.${status}`);
        default:
            return status;
    }
};

export const getLocalizedCompletionPercentageNotProvided = () => {
    return i18n.t('reports:liveDriver.completionPercentageNotProvided');
};
