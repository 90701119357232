/**
 * Enum of supported icon sizes
 */
export enum IconSizes {
    /**
     * 0.6rem x 0.6rem
     */
    XXXS = 'xxxs',
    /**
     * 1rem x 1rem
     */
    XXS = 'xxs',
    /**
     * 1.2rem x 1.2rem
     */
    XS = 'xs',
    /**
     * 1.6rem x 1.6rem
     */
    S = 's',
    /**
     * 2rem x 2rem
     */
    M = 'm',
    /**
     * 2.4rem x 2.4rem
     */
    L = 'l',
    /**
     * 3.6rem x 3.6rem
     */
    XL = 'xl',
    /**
     * 4.8rem x 4.8rem
     */
    XXL = 'xxl'
}

export type IconSize = `${IconSizes}`;
