import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '~/ui';
import { RouteCardExportPillProps } from './types';
import './RouteCardExportPill.scss';

export const RouteCardExportPill = ({
    lastExport
}: RouteCardExportPillProps) => {
    const { t } = useTranslation('translation');
    const rootClassName = 'route-card-export-pill';
    const tooltipMsg = lastExport
        ? t('lastExported', { date: lastExport })
        : '';

    return (
        <div className={rootClassName} data-testid={rootClassName}>
            <Tooltip content={tooltipMsg} placement="top">
                <span
                    className={`${rootClassName}__text`}
                    data-testid={`${rootClassName}-text`}
                >
                    {t('exported')}
                </span>
            </Tooltip>
        </div>
    );
};
