import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ResequenceModalStopList from '~/components/MapPage/ResequenceModal/ResequenceModalStopList';
import { useOnDemandDispatchUnassignTasks, useToastContext } from '~/hooks';
import { selectIsOpenUnassignedTasksDrawer } from '~/reducers/mapDrawerSettingsSlice';
import {
    replaceSelectedMapStop,
    selectSelectedMapStops
} from '~/reducers/selectedMapStopsSlice';
import {
    ManageStopControlMenu,
    ManageStopControlMultiple,
    StopModalMenuButton
} from '~/ui';
import constants from '~/utils/constants';
import { mapPlanStopUtils } from '~/utils/map';
import { useLiveStopsControlActions } from '../useLiveStopsControlActions';
import { MarkEndOfShiftControl } from '~/components/MapPage/MarkEndOfShiftControl';
import { ChangeTaskStatusControl } from '../ChangeTaskStatusControl';

import './LiveMultiStopsControl.scss';

function LiveMultiStopsControl({
    selectedStops,
    stopIdPropertyName,
    footerButtonTitle,
    footerButtonIcon,
    onClickFooterButton,
    onSwitchView,
    isTwoPartTask
}) {
    const dispatch = useDispatch();
    const isOpenUnassignedTasksDrawer = useSelector(
        selectIsOpenUnassignedTasksDrawer
    );
    const selectedMapStopIds = useSelector(selectSelectedMapStops);

    const [dragStartIndex, setDragStartIndex] = useState(null);

    const { t } = useTranslation('translation');

    const { cancelTasks, unassignTasks } = useOnDemandDispatchUnassignTasks();
    const { addToast } = useToastContext();

    const [endOfShiftTask] = selectedStops.filter(
        (selectedStop) => selectedStop.type === constants.taskTypes.DELIVERY
    );

    const { getMultiLiveStopActions } = useLiveStopsControlActions();
    const {
        showResequence,
        showUnassign,
        showCancel,
        showChangeTaskStatus,
        showMarkEndOfShift
    } = getMultiLiveStopActions({
        selectedStops,
        isTwoPartTask
    });

    const handleClickLiveStopUnassign = () => {
        unassignTasks({
            onSuccess: () => {
                addToast({
                    message: t('PlanStopsControl.toast.onSuccessUnassign', {
                        count: selectedMapStopIds.length
                    }),
                    variant: 'success'
                });
            },
            onError: (error) => {
                if (error?.data || error?.response?.data) {
                    console.error(error);
                }
                addToast({
                    message: t('PlanStopsControl.toast.onErrorUnassign', {
                        count: selectedMapStopIds.length
                    }),
                    variant: 'error'
                });
            }
        });
    };

    const handleClickLiveStopCancel = () => {
        cancelTasks({
            onSuccess: () => {
                addToast({
                    message: t('PlanStopsControl.toast.onSuccessCancel', {
                        count: selectedMapStopIds.length
                    }),
                    variant: 'success'
                });
            },
            onError: (error) => {
                if (error?.data || error?.response?.data) {
                    console.error(error);
                }
                addToast({
                    message: t('PlanStopsControl.toast.onErrorCancel', {
                        count: selectedMapStopIds.length
                    }),
                    variant: 'error'
                });
            }
        });
    };

    const handleDrop = useCallback(
        (e, index) => {
            const reorderedStops = mapPlanStopUtils.processStopsReorder(
                e,
                selectedStops,
                stopIdPropertyName,
                dragStartIndex,
                index,
                isOpenUnassignedTasksDrawer
            );
            dispatch(replaceSelectedMapStop(reorderedStops));
        },
        [
            dispatch,
            dragStartIndex,
            isOpenUnassignedTasksDrawer,
            selectedStops,
            stopIdPropertyName
        ]
    );

    const stopList = useMemo(() => {
        return selectedStops.map((selectedStop, index) => (
            <ResequenceModalStopList
                key={selectedStop[stopIdPropertyName]}
                stopIdPropertyName={stopIdPropertyName}
                selectedStop={selectedStop}
                handleDragStart={() => setDragStartIndex(index)}
                handleDragOver={(e) => e.preventDefault()}
                handleDrop={(e) => handleDrop(e, index)}
            />
        ));
    }, [selectedStops, handleDrop, stopIdPropertyName]);

    return (
        <ManageStopControlMultiple
            className="live-multi-stops-control"
            controlTitle={t('PlanStopsControl.title.no_of_stops_selected', {
                length: selectedMapStopIds.length
            })}
            controlHelp={t('PlanStopsControl.title.selection_info')}
            stopList={stopList}
            footerButtonTitle={footerButtonTitle}
            footerButtonIcon={footerButtonIcon}
            onClickFooterButton={onClickFooterButton}
            isTwoPartTask={isTwoPartTask}
        >
            {showResequence && (
                <>
                    <ManageStopControlMenu>
                        <StopModalMenuButton
                            buttonIcon="iconReorder"
                            buttonText={t('PlanStopsControl.button.resequence')}
                            onClick={() =>
                                onSwitchView(
                                    constants.stopsControl.views.RESEQUENCE
                                )
                            }
                            showButtonArrow
                            data-testid="button-resequence"
                        />
                    </ManageStopControlMenu>
                    <div className="_d-flex _ai-center live-multi-stops-control__selected-stops-info">
                        <span className="_text-3-alt">
                            {t('PlanStopsControl.title.selected_stops_info')}
                        </span>
                    </div>
                </>
            )}

            <ManageStopControlMenu>
                {showUnassign && (
                    <StopModalMenuButton
                        onClick={handleClickLiveStopUnassign}
                        buttonIcon="unassign"
                        buttonText={t('PlanStopsControl.button.unassign')}
                        data-testid="button-live-stop-unassign_button"
                    />
                )}

                {showCancel && (
                    <StopModalMenuButton
                        onClick={handleClickLiveStopCancel}
                        buttonIcon="iconRouteless"
                        buttonText={t('PlanStopsControl.button.cancel')}
                        data-testid="button-cancel"
                    />
                )}
                {showChangeTaskStatus && (
                    <ChangeTaskStatusControl stopData={selectedStops} />
                )}
                {showMarkEndOfShift && (
                    <MarkEndOfShiftControl stopData={endOfShiftTask} />
                )}
            </ManageStopControlMenu>
        </ManageStopControlMultiple>
    );
}

export default LiveMultiStopsControl;
