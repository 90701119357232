import React from 'react';
import classNames from 'classnames';
import { TimeInput } from '~/ui';
import { DateTimePickerTimeInputProps } from './types';
import './DateTimePickerTimeInput.scss';

const ROOT_CLASS_NAME = 'date-time-picker-time-input';

export const DateTimePickerTimeInput = ({
    label,
    value,
    onChange,
    children,
    className,
    'data-testid': dataTestId
}: DateTimePickerTimeInputProps) => {
    const elementClassName = classNames(ROOT_CLASS_NAME, className);
    const labelClassName = `${ROOT_CLASS_NAME}__label`;
    const inputClassName = `${ROOT_CLASS_NAME}__input`;

    const elementDataTestId = dataTestId || ROOT_CLASS_NAME;
    const inputTestId = `${elementDataTestId}__input`;

    return (
        <div className={elementClassName} data-testid={elementDataTestId}>
            {label && <div className={labelClassName}>{label}</div>}
            <TimeInput
                variant="form"
                value={value}
                onChange={onChange}
                className={inputClassName}
                data-testid={inputTestId}
            />
            {children}
        </div>
    );
};
