import React, {
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import Supercluster from 'supercluster';
import { useTranslation } from 'react-i18next';

import { markerMaker } from '~/utils/map';
import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';
import { ApiTask, Coordinates } from '~/api/types';
import { usePlanMapEventsContext } from '~/components/MapPage/PlanMap/PlanMapEventsContext';
import { RoutePolygon } from '~/ui/components/RoutePolygon';
import { useSelector } from 'react-redux';
import { selectLiveDriverById } from '~/reducers/liveDriversSlice';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { useMapUtils } from '~/hooks';
import { selectDateOnly } from '~/reducers/selectedDateSlice';
import makeStopMarkers, { makeRouteLines } from '~/utils/map-modes/stops-mode';
import { selectAllSelectedDrawerCardData } from '~/reducers/selectedDrawerCardDataSlice';
import {
    selectIsClusteringStops,
    selectedIsClusteringUnassignedTasks
} from '~/reducers/mapSettingsSlice';
import makeStopAndClusterMarkers from '~/utils/map-modes/stops-clusters-mode';
import makeUnassignedClusterMarkers from '~/utils/map-modes/unassigned-tasks-cluster-mode';
import { selectUnassignedPlanTasks } from '~/reducers/tasksSlice';
import { selectHiddenRoutes } from '~/reducers/hiddenRoutesSlice';
import { selectIsOpenUnassignedTasksDrawer } from '~/reducers/mapDrawerSettingsSlice';
import constants from '~/utils/constants';
import { selectLastPlanMapZoom } from '~/reducers/lastPlanMapZoomSlice';
import { useRoutePlanStopEffects } from '~/components/MapPage/PlanMap/useRoutePlanStopEffects';
import { useSelectedMapRoutes } from '~/components/MapPage/useSelectedMapRoutes';
import { selectLastPlanMapBounds } from '~/reducers/lastPlanMapBoundsSlice';
import { makeUnassignedStopMarkerEffects } from '../makeUnassignedStopMarkerEffects';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { useMapEngineContext } from '~/components/MapEngineProvider';
import taskUtils from '~/utils/task-utils';
import { PlanRoute } from '~/data-classes';
import { idUtils } from '~/utils/id-utils';

type UsePlannedModeMapMarkersReturnProps = {
    routeLevelCoordinatesRef: MutableRefObject<Coordinates[]>;
    stopLevelCoordinatesRef: MutableRefObject<Coordinates[]>;
    superClusters: Supercluster.AnyProps[];
    unassignedSuperClusters: Supercluster.AnyProps[];
};

interface UsePlannedModeMapMarkersReturnValue {
    routeMarkers: JSX.Element[];
    routeLines: JSX.Element[];
    routeStopMarkers: JSX.Element[];
    routeCoordinates: Coordinates[];
    polygons: JSX.Element[];
}

const usePlannedModeMapMarkers = ({
    routeLevelCoordinatesRef,
    stopLevelCoordinatesRef,
    superClusters,
    unassignedSuperClusters
}: UsePlannedModeMapMarkersReturnProps): UsePlannedModeMapMarkersReturnValue => {
    const [routeMarkers, setRouteMarkers] = useState<JSX.Element[]>([]);
    const [polygons, setPolygons] = useState<JSX.Element[]>([]);
    const [routeStopMarkers, setRouteStopMarkers] = useState<JSX.Element[]>([]);
    const [routeLines, setRouteLines] = useState<JSX.Element[]>([]);
    const [routeCoordinates] = useState<Coordinates[]>([]);

    const { t } = useTranslation('common');

    // @TODO type PlanMapPropsContext https://wisesys.atlassian.net/browse/RP-840
    const {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        visibleOnMapRouteData,
        routesLevelData,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mapsAPI
    } = usePlanMapPropsContext();
    const { emittedEventHandler } = usePlanMapEventsContext();
    const { mapInstance } = useMapEngineContext();
    const {
        isRoutesMode,
        isPlanRouteMode,
        mapMarkerMode,
        isStopsClustersMode,
        mapRouteMode
    } = useMapUtils();

    const isClusteringUnassignedTasks = useSelector(
        selectedIsClusteringUnassignedTasks(
            mapRouteMode as ConfigurableMapRouteMode
        )
    );
    const selectedDrawerRouteId = useSelector(selectSelectedDrawerCardId);
    const serialSelectedLiveDriver = useSelector(
        selectLiveDriverById(selectedDrawerRouteId)
    );
    const selectedDate = useSelector(selectDateOnly);
    const allSelectedDrawerCardData = useSelector(
        selectAllSelectedDrawerCardData
    );
    const isClusteringStops = useSelector(
        selectIsClusteringStops(mapRouteMode as ConfigurableMapRouteMode)
    );
    const unassignedPlanTasks = useSelector(selectUnassignedPlanTasks);
    const hiddenRoutes = useSelector(selectHiddenRoutes);
    const isOpenUnassignedTasksDrawer = useSelector(
        selectIsOpenUnassignedTasksDrawer
    );
    const lastPlanMapZoom = useSelector(selectLastPlanMapZoom);
    const lastPlanMapBounds = useSelector(selectLastPlanMapBounds);

    const filteredUnassignedPlanTasks = useMemo(
        () =>
            taskUtils.filterTasksByRouteDate(unassignedPlanTasks, selectedDate),
        [unassignedPlanTasks, selectedDate]
    );

    const {
        getParentClientRouteId,
        clientRouteIds: clientRouteIdsForSelectedMapRoutes
    } = useSelectedMapRoutes({
        planRoutes: routesLevelData
    });

    const { visibleOnMapPlanStops } = useRoutePlanStopEffects({
        getParentClientRouteId,
        clientRouteIdsForSelectedMapRoutes
    });

    const filterHiddenUnassignedPlanTasks = useCallback(() => {
        return filteredUnassignedPlanTasks.filter((task: ApiTask) => {
            const { client: clientId } = task as ApiTask;
            const clientRouteId = `${clientId}_${constants.entityStates.UNPLANNED}`;
            return !hiddenRoutes[clientRouteId];
        });
    }, [filteredUnassignedPlanTasks, hiddenRoutes]);

    useEffect(() => {
        if (isRoutesMode) {
            setRouteMarkers([]);
        } else {
            setRouteLines([]);
            setRouteStopMarkers([]);
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [selectedDate]);

    // route markers
    useEffect(() => {
        if (!isPlanRouteMode) {
            return;
        }
        const routeMarkerEffects: JSX.Element[] = [];
        const polygonEffects: JSX.Element[] = [];
        routeLevelCoordinatesRef.current = [];
        visibleOnMapRouteData.forEach(
            (routeLevelData: PlanRoute, index: number) => {
                const {
                    markerCoordinates,
                    perimeterCoordinates,
                    colorCSS,
                    clientRouteId
                } = routeLevelData;
                const uniqueKey = idUtils.getUniqueKeyForDuplicatedItem(
                    clientRouteId,
                    index
                );

                const routeMarker = markerMaker.makeRouteMarker(
                    routeLevelData,
                    emittedEventHandler,
                    uniqueKey
                );
                routeMarkerEffects.push(routeMarker);
                routeLevelCoordinatesRef.current.push(markerCoordinates);

                const polygon = (
                    <RoutePolygon
                        key={uniqueKey}
                        clientRouteId={clientRouteId}
                        perimeterCoordinates={perimeterCoordinates}
                        options={{ color: colorCSS.backgroundColor }}
                    />
                );
                polygonEffects.push(polygon);
            }
        );
        setRouteMarkers(routeMarkerEffects);
        setPolygons(polygonEffects);
    }, [
        setPolygons,
        routeLevelCoordinatesRef,
        emittedEventHandler,
        visibleOnMapRouteData,
        mapsAPI,
        isPlanRouteMode,
        serialSelectedLiveDriver
    ]);

    // route lines
    useEffect(() => {
        if (!isPlanRouteMode || !mapInstance) {
            return;
        }

        const allSelectedRouteIds = allSelectedDrawerCardData
            .map(({ data: selectedDrawerCardData }) => {
                const { route } = selectedDrawerCardData as {
                    route?: { routeId: string };
                };
                const { routeId } = route ?? {};
                return routeId;
            })
            .filter(Boolean);
        const routePlans = !allSelectedRouteIds.length
            ? routesLevelData
            : routesLevelData.filter(({ routeId }) =>
                  allSelectedRouteIds.includes(routeId)
              );

        const newPlannedRouteLines = makeRouteLines({
            mapInstance,
            routePlans,
            allPlannedStops: visibleOnMapPlanStops.flat()
        });

        setRouteLines(isClusteringStops ? [] : newPlannedRouteLines);
    }, [
        allSelectedDrawerCardData,
        isClusteringStops,
        mapInstance,
        isPlanRouteMode,
        visibleOnMapPlanStops,
        routesLevelData
    ]);

    useEffect(() => {
        if (!isStopsClustersMode || isOpenUnassignedTasksDrawer) {
            return;
        }
        stopLevelCoordinatesRef.current = [];

        for (const planStopRoute of visibleOnMapPlanStops) {
            for (const planStop of planStopRoute) {
                if (planStop.isDepot) {
                    continue;
                }
                const { markerCoordinates } = planStop;
                stopLevelCoordinatesRef.current.push(markerCoordinates);
            }
        }
    }, [
        stopLevelCoordinatesRef,
        visibleOnMapPlanStops,
        isStopsClustersMode,
        isOpenUnassignedTasksDrawer
    ]);

    const getClusters = useCallback(
        (superCluster) => {
            if (!lastPlanMapBounds) {
                return [];
            }
            return superCluster.getClusters(lastPlanMapBounds, lastPlanMapZoom);
        },
        [lastPlanMapBounds, lastPlanMapZoom]
    );

    // route stops or clusters
    useEffect(() => {
        if (!isPlanRouteMode) {
            return;
        }
        const stopClusterMarkerEffects = [];

        if (isOpenUnassignedTasksDrawer) {
            const filteredPlanTasks = filterHiddenUnassignedPlanTasks();
            if (!filteredPlanTasks.length) {
                setRouteStopMarkers([]);
                return;
            }

            stopLevelCoordinatesRef.current = [];
            const stopMarkerEffects = makeUnassignedStopMarkerEffects({
                isClusteringStops: isClusteringUnassignedTasks,
                unassignedSuperClusters,
                getClusters,
                makeUnassignedClusterMarkers,
                emittedEventHandler,
                planTasks: filteredUnassignedPlanTasks
            });

            setRouteStopMarkers(stopMarkerEffects);
            stopMarkerEffects.forEach((marker: JSX.Element) => {
                stopLevelCoordinatesRef.current.push({
                    lat: marker.props.lat,
                    lng: marker.props.lng
                });
            });

            return;
        }

        for (let index = 0; index < superClusters.length; index++) {
            const superCluster = superClusters[index];
            let markerComponents = [];
            const geoJSONFeatures = getClusters(superCluster);

            if (isClusteringStops) {
                markerComponents = makeStopAndClusterMarkers(
                    superCluster,
                    geoJSONFeatures,
                    index,
                    emittedEventHandler,
                    t
                );
            } else {
                markerComponents = makeStopMarkers(
                    superCluster,
                    geoJSONFeatures,
                    emittedEventHandler,
                    t
                );
            }
            stopClusterMarkerEffects.push(...markerComponents);
        }
        setRouteStopMarkers(stopClusterMarkerEffects);
    }, [
        emittedEventHandler,
        hiddenRoutes,
        superClusters,
        isPlanRouteMode,
        mapMarkerMode,
        isClusteringStops,
        filteredUnassignedPlanTasks,
        getClusters,
        unassignedSuperClusters,
        isClusteringUnassignedTasks,
        stopLevelCoordinatesRef,
        isOpenUnassignedTasksDrawer,
        filterHiddenUnassignedPlanTasks,
        t
    ]);

    return {
        routeMarkers,
        routeLines,
        routeStopMarkers,
        routeCoordinates,
        polygons
    };
};

export default usePlannedModeMapMarkers;
