import {
    getParamErrorAssignmentId,
    getParamErrorAssignmentStatus,
    getParamErrorTimestamp
} from '../utils/validate';
import {
    StringParamValidationCallback,
    AssignmentStatusParamValidationCallback
} from '../utils/types';
import { AssignmentApiStatusParams } from './types';

/**
 * Describes the valid types for the AssignmentApi validation callbacks
 */
type AssignmentApiValidationCallbacks =
    | StringParamValidationCallback
    | AssignmentStatusParamValidationCallback;

/**
 * A mapping of parameter keys to a validation callback
 */
const validationCallbacks: Record<string, AssignmentApiValidationCallbacks> = {
    assignmentId: getParamErrorAssignmentId,
    status: getParamErrorAssignmentStatus,
    timestamp: getParamErrorTimestamp
};

/**
 * Validates provided AssignmentApi.status() parameters
 *
 * @param params The AssignmentApi.status() parameters to test
 * @returns The error messages
 */
export const getAssignmentApiStatusParamErrors = (
    params: AssignmentApiStatusParams
) => {
    const paramsEntries = Object.entries(params);
    const paramsErrors = paramsEntries
        .map(([paramKey, paramValue]) => {
            const validationFn = validationCallbacks[paramKey];

            return validationFn(paramValue);
        })
        .filter(Boolean);

    if (paramsErrors.length) return new Error(paramsErrors.join('; '));
};
