import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { resetSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { resetSelectedMapRoutes } from '~/reducers/selectedMapRoutesSlice';
import {
    setIsOpenSuggestDrawer,
    setIsOpenUnassignedTasksDrawer
} from '~/reducers/mapDrawerSettingsSlice';
import { useIsolatedRoutes, useMapUtils } from '~/hooks';
import {
    setMapMarkerMode,
    setShowUnassignedTasks
} from '~/reducers/mapSettingsSlice';
import constants from '~/utils/constants';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';

export const useSelectRoutesView = () => {
    const { isPlanRouteMode, mapRouteMode } = useMapUtils();
    const { isolateMultipleRoutes } = useIsolatedRoutes();
    const dispatch = useDispatch();

    const selectRoutesView = useCallback(() => {
        dispatch(resetSelectedMapStops());
        dispatch(resetSelectedMapRoutes([]));
        dispatch(setIsOpenUnassignedTasksDrawer(false));
        dispatch(
            setShowUnassignedTasks({
                mode: mapRouteMode as ConfigurableMapRouteMode,
                value: false
            })
        );
        dispatch(setIsOpenSuggestDrawer(false));
        isolateMultipleRoutes(false);

        if (isPlanRouteMode) {
            dispatch(setMapMarkerMode(constants.mapMarkerModes.ROUTES));
        }
    }, [isPlanRouteMode, mapRouteMode, dispatch, isolateMultipleRoutes]);

    return {
        selectRoutesView
    };
};
