import { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setCurrentPage } from '~/reducers/currentPageSlice';
import constants from '~/utils/constants';
import { UsePageNavigationReturn, AppHistoryState } from './types';

const defaultPrevPage = constants.url.MAP;

export const usePageNavigation = (): UsePageNavigationReturn => {
    const [prevPage, setPrevPage] = useState<string>(defaultPrevPage);
    const history = useHistory<AppHistoryState>();
    const dispatch = useDispatch();

    const goToPage = useCallback(
        (pathname: string, search?: Record<string, string>) => {
            dispatch(setCurrentPage(pathname));

            const query = search && new URLSearchParams(search);
            const searchValue = query && query.toString();
            // only add to the history when pathname changed or no state
            const noState = !history?.location?.state;
            const isChangedPathname = history?.location?.pathname !== pathname;

            if (noState || isChangedPathname) {
                history.push({
                    pathname,
                    search: searchValue,
                    state: {
                        pathname: history.location.pathname,
                        search: history.location.search
                    }
                });
            }
        },
        [dispatch, history]
    );

    const goToPrevPage = useCallback(() => {
        if (history?.location?.state) {
            // go back to the previous history location state
            history.goBack();
            return;
        }

        // go back to the predefined previous page (fallback)
        goToPage(prevPage);
    }, [prevPage, history, goToPage]);

    const goToMapPage = useCallback(() => {
        goToPage(constants.url.MAP);
    }, [goToPage]);

    useEffect(() => {
        // set previous page's pathname to state as a fallback
        const prevPathname = history?.location?.state?.pathname;
        if (!prevPathname) return;

        setPrevPage(prevPathname);
        goToPage(history.location.pathname);
    }, [history, setPrevPage, goToPage]);

    return { goToPage, goToPrevPage, goToMapPage };
};
