import React from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentCustomer } from '~/reducers/customersSlice';
import { clearCustomerSearchResults } from '~/reducers/customerSearchResultsSlice';
import { Highlight, Icon } from '~/ui';
import localizationUtils from '~/utils/localization-utils';
import './CustomerSearchResult.scss';

const CustomerSearchResult = ({ data, searchTerm, handleOnClick }) => {
    const dispatch = useDispatch();

    const handleNavigateToCustomerDetails = () => {
        dispatch(setCurrentCustomer(data));
        const { name: customerName, client: customerId, address, euid } = data;

        const {
            addressLine1: addressStreet,
            addressLine2: addressApt,
            city: addressCity,
            state: addressState,
            zipcode: addressZipCode,
            geoLocation: { coordinates }
        } = address;

        const [longitude, latitude] = coordinates;

        const addressInfo = {
            customerName,
            customerId,
            addressStreet,
            addressApt,
            addressCity,
            addressState,
            addressZipCode,
            longitude,
            latitude,
            euid
        };
        handleOnClick(addressInfo);
        dispatch(clearCustomerSearchResults());
    };

    return (
        <button
            type="button"
            data-testid="customer-search-result"
            className="customer-search-result _fd-column"
            onClick={handleNavigateToCustomerDetails}
        >
            <div
                className="customer-search-result__name"
                data-testid="customer-search-result__name"
            >
                <Highlight
                    searchText={searchTerm}
                    inputText={data.name || ''}
                    fontWeight="700"
                />
            </div>
            <div
                data-testid="customer-search-result__address"
                className="customer-search-result__address _fd-row _text-3-alt"
            >
                <Icon
                    className="customer-search-result__icon"
                    data-testid="address-icon"
                    icon="pin"
                    color="galaxy-500"
                    size="s"
                />
                {localizationUtils.formatAddress(data.address)}
            </div>
            {data.euid && (
                <div
                    data-testid="customer-search-result__euid"
                    className="customer-search-result__euid _fd-row _ai-center _text-3-alt"
                >
                    <Icon
                        data-testid="euid-icon"
                        icon="infoLine"
                        color="galaxy-500"
                        size="s"
                    />
                    <Highlight
                        searchText={searchTerm}
                        inputText={data.euid || ''}
                        fontWeight="700"
                        className="_text-3"
                    />
                </div>
            )}
        </button>
    );
};

export default CustomerSearchResult;
