import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { RadioInputData } from '~/ui/components/RadioGroup';
import { RadioGroup } from '~/ui';
import { DuplicateTaskModalFormProps, ItemTypes } from '../types';
import { DuplicateTaskModalInvoices } from '../DuplicateTaskModalInvoices';
import { DuplicateTaskModalCollapsible } from '../DuplicateTaskModalCollapsible';

import './DuplicateTaskModalChooseItems.scss';

export const DuplicateTaskModalChooseItems = () => {
    const rootClassName = 'duplicate-task-modal-choose-items';
    const { t } = useTranslation('duplicateTaskModal');
    const { values } = useFormikContext<DuplicateTaskModalFormProps>();

    const radioData: RadioInputData<ItemTypes>[] = [
        {
            id: `${rootClassName}-${ItemTypes.INVOICE}`,
            label: t('items.invoices'),
            value: ItemTypes.INVOICE
        }
    ];

    return (
        <div className={`${rootClassName} _fd-column`}>
            <div className={`${rootClassName}__header _fd-column`}>
                <span
                    className={`${rootClassName}__title`}
                    data-testid={`${rootClassName}__title`}
                >
                    {t('settings.moveInvoice.label')}
                </span>
                <span
                    className={`${rootClassName}__description`}
                    data-testid={`${rootClassName}__description`}
                >
                    {t('settings.moveInvoice.description')}
                </span>
                <RadioGroup
                    className={`${rootClassName}__radio _d-none`}
                    radioData={radioData}
                    value={values.itemType}
                    name="itemType"
                />
            </div>
            <DuplicateTaskModalCollapsible title={t('action.title')}>
                <DuplicateTaskModalInvoices />
            </DuplicateTaskModalCollapsible>
        </div>
    );
};
