import { useCallback } from 'react';
import { ChangeTaskStatusParams } from './types';
import { useUpdateAssignmentStatus, useWebInterval } from '~/hooks';
import { getErrorMessage } from '~/utils/errorUtils';

export const useChangeTaskStatus = () => {
    const { updateAssignmentStatusInSequence } = useUpdateAssignmentStatus();
    const { refetch } = useWebInterval();

    const changeTaskStatus = useCallback(
        async ({ payload }: ChangeTaskStatusParams) => {
            try {
                await updateAssignmentStatusInSequence(payload);
                refetch();
            } catch (error) {
                const message = getErrorMessage(error);
                console.error(message);
            }
        },
        [updateAssignmentStatusInSequence, refetch]
    );

    return { changeTaskStatus };
};
