import React, { useCallback, useMemo } from 'react';
import { theme } from '~/ui';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    ApiLiveStop,
    Coordinates,
    DeliveryTask,
    PickupTask
} from '~/api/types';
import { LiveStop, Task } from '~/data-classes';
import { selectSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import constants from '~/utils/constants';
import { StackPinMarkerProps } from '../types';
import { idUtils } from '~/utils/id-utils';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { useMapUtils } from '~/hooks';
import { StopMarkerNameAndLabels } from '~/ui/components/StopMarker/StopMarkerNameAndLabels';
import { selectShowStopLabel } from '~/reducers/mapSettingsSlice';

export const UnassignedStopMarker = ({
    task,
    emittedEventHandler
}: StackPinMarkerProps) => {
    const { t } = useTranslation('common');
    const { mapRouteMode } = useMapUtils();
    const isShowStopLabel = useSelector(
        selectShowStopLabel(mapRouteMode as ConfigurableMapRouteMode)
    );

    const selectedMapStops = useSelector(selectSelectedMapStops);

    const unPlannedCSS = {
        backgroundColor: theme.colors['galaxy-600'],
        color: theme.colors.comet
    };
    const taskObj = new Task(task);
    const {
        isDelivery: isDeliveryTask,
        isPlanned: isPlannedTask,
        isTwoPart,
        id,
        name,
        labels
    } = taskObj;

    const singleTaskType = isDeliveryTask
        ? constants.taskTypes.DELIVERY
        : constants.taskTypes.PICKUP;

    const clientRouteTaskId = idUtils.getCombinedId(
        task.client,
        isPlannedTask
            ? constants.entityStates.PLANNED
            : constants.entityStates.UNPLANNED,
        singleTaskType,
        task.id
    );

    const isSelected = useMemo(() => {
        return selectedMapStops.includes(clientRouteTaskId);
    }, [clientRouteTaskId, selectedMapStops]);

    const handleClick = useCallback(() => {
        const { location } = isDeliveryTask
            ? (task as DeliveryTask).deliveryLocation
            : (task as PickupTask).pickupLocation;

        emittedEventHandler({
            event: constants.mapChildEvents.STOP_MOUSEUP,
            payload: {
                isSelected,
                isTwoPart,
                id: clientRouteTaskId,
                selectedMapStops,
                taskId: id,
                stopLevelData: task as unknown as ApiLiveStop,
                stopMarkerData: task as unknown as LiveStop,
                location: location as Coordinates,
                stopId: id
            }
        });
    }, [
        selectedMapStops,
        emittedEventHandler,
        task,
        isSelected,
        isDeliveryTask,
        clientRouteTaskId,
        id,
        isTwoPart
    ]);

    const getClassName = () => {
        const boxClassName = 'stopmarker';

        const conditionalClasses = {
            stopmarker_selected: isSelected
        };
        return classNames(boxClassName, conditionalClasses);
    };

    return (
        <div className="_d-flex _fd-column _jc-center">
            <button
                type="button"
                className={getClassName()}
                data-testid={`popup-pin-${task.id}`}
                onClick={handleClick}
            >
                <div className="stopmarker-body">
                    <div
                        className="_p-relative"
                        data-testid="default-stop-marker"
                    >
                        <span
                            className="stopmarker-circle"
                            data-testid="stop-marker-circle"
                            style={unPlannedCSS}
                        >
                            {isDeliveryTask
                                ? t('unassignedTaskIcons.delivery')
                                : t('unassignedTaskIcons.pickup')}
                        </span>
                    </div>
                </div>
            </button>
            {isShowStopLabel && (
                <StopMarkerNameAndLabels name={name} labels={labels} />
            )}
        </div>
    );
};
