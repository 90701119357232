import React from 'react';
import classNames from 'classnames';
import { Icon, RouteCard, RouteCardMarker, RouteCardTitle, theme } from '~/ui';
import { PreviewSingleStopCardProps, PreviewStopCardProps } from './types';
import { TaskTypes } from '~/api/types';
import './PreviewStopCard.scss';

export const PreviewSingleStopCard = ({
    stopData,
    taskType,
    isSelected,
    isHidden,
    iconColor = theme.colors.comet,
    iconStyle = { backgroundColor: theme.colors['galaxy-500'] }
}: PreviewSingleStopCardProps) => {
    const { stopNumber, stopName } = stopData;

    const ROOT_CLASS_NAME = 'preview-single-stop-card';
    const elementClassName = classNames(ROOT_CLASS_NAME, '_d-flex', {
        [`${ROOT_CLASS_NAME}--${taskType}`]: taskType
    });
    const elementDataTestId = taskType
        ? `${ROOT_CLASS_NAME}--${taskType}`
        : ROOT_CLASS_NAME;

    return (
        <div className={elementClassName} data-testid={elementDataTestId}>
            <RouteCard
                className={`${ROOT_CLASS_NAME}__route-card`}
                data-testid={`${ROOT_CLASS_NAME}__route-card`}
                isRouteSelected={isSelected}
                isRouteHidden={isHidden}
            >
                <RouteCardMarker
                    className={`${ROOT_CLASS_NAME}__route-marker`}
                    data-testid={`${ROOT_CLASS_NAME}__route-marker`}
                    text={`${stopNumber}`}
                    textColor={iconColor}
                    iconStyle={iconStyle}
                    disabled
                />
                <div
                    className={`${ROOT_CLASS_NAME}__stop-data _fd-column`}
                    data-testid={`${ROOT_CLASS_NAME}__stop-data`}
                >
                    <div className="_jc-space-between">
                        <RouteCardTitle
                            title={stopName}
                            className={`${ROOT_CLASS_NAME}__stop-name`}
                            data-testid={`${ROOT_CLASS_NAME}__stop-name`}
                        />
                    </div>
                </div>
            </RouteCard>
        </div>
    );
};

export const PreviewStopCard = ({
    stopData,
    selectedStopId,
    iconColor,
    iconStyle,
    className,
    'data-testid': dataTestId
}: PreviewStopCardProps) => {
    const { pickup: pickupStopData, delivery: deliveryStopData } = stopData;
    const ROOT_CLASS_NAME = 'preview-stop-card';
    const isTwoPart = Boolean(pickupStopData && deliveryStopData);
    const elementClassName = classNames(
        ROOT_CLASS_NAME,
        '_fd-column',
        className
    );
    const elementDataTestId = dataTestId || ROOT_CLASS_NAME;

    const isPickupSelected = isTwoPart
        ? pickupStopData?.id === selectedStopId
        : Boolean(pickupStopData);

    const isDeliverySelected = isTwoPart
        ? deliveryStopData?.id === selectedStopId
        : Boolean(deliveryStopData);

    return (
        <div className={elementClassName} data-testid={elementDataTestId}>
            {pickupStopData && (
                <PreviewSingleStopCard
                    stopData={pickupStopData}
                    iconColor={iconColor}
                    iconStyle={iconStyle}
                    taskType={TaskTypes.PICKUP}
                    isSelected={isPickupSelected}
                    isHidden={isDeliverySelected}
                />
            )}
            {isTwoPart && (
                <div
                    className={`${ROOT_CLASS_NAME}__chain`}
                    data-testid={`${elementDataTestId}__chain`}
                >
                    <Icon
                        icon="twoPartStopCircle"
                        className={`${ROOT_CLASS_NAME}__chain-icon`}
                        data-testid={`${elementDataTestId}__chain-icon`}
                    />
                </div>
            )}
            {deliveryStopData && (
                <PreviewSingleStopCard
                    stopData={deliveryStopData}
                    iconColor={iconColor}
                    iconStyle={iconStyle}
                    taskType={TaskTypes.DELIVERY}
                    isSelected={isDeliverySelected}
                    isHidden={isPickupSelected}
                />
            )}
        </div>
    );
};
