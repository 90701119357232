import React, { useCallback } from 'react';
import Supercluster from 'supercluster';
import { useSelector } from 'react-redux';

import { markerMaker, markerUtils } from '~/utils/map';
import { idUtils } from '~/utils/id-utils';
import { StackPinMarker } from '~/ui';
import { OnDemandDispatchMarkerEventHandler } from '~/ui/components/LiveStopMarker/types';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import {
    selectedIsClusteringUnassignedTasks,
    selectIsClusteringStops
} from '~/reducers/mapSettingsSlice';
import { HookOnDemandDispatchMarkerEventHandler, useMapUtils } from '~/hooks';
import { EmittedEventHandler } from '~/components/MapPage/PlanMap/types';
import { useDispatchedGetClusters } from '~/components/MapPage/PlanMap/useDispatchedModeMapMarkers/useDispatchedGetClusters';

import { ApiTask } from '~/api/types';
import { Feature } from 'geojson';

interface MakeLiveStopMarkersProps {
    onDemandDispatchMarkerEventHandler: HookOnDemandDispatchMarkerEventHandler;
    emittedEventHandler: EmittedEventHandler;
    liveStopsSuperClusters: Supercluster.AnyProps[];
    unassignedSuperClusters?: Supercluster.AnyProps[];
    unassignedTasks?: ApiTask[];
}

export const useMakeLiveStopMarkers = () => {
    const { mapRouteMode } = useMapUtils();
    const isClusteringStops = useSelector(
        selectIsClusteringStops(mapRouteMode as ConfigurableMapRouteMode)
    );
    const isClusteringUnassignedTasks = useSelector(
        selectedIsClusteringUnassignedTasks(
            mapRouteMode as ConfigurableMapRouteMode
        )
    );

    const { getClusters } = useDispatchedGetClusters();

    const groupStopMarkers = useCallback(
        (liveStopsSuperClusters) => {
            const allStopLeaves = [];
            for (const superCluster of liveStopsSuperClusters) {
                const geoJSONFeatures = getClusters(superCluster);
                const liveStopLeaves = geoJSONFeatures.flatMap(
                    (geoJSONFeature) => {
                        const isCluster = geoJSONFeature.properties.cluster;
                        const leaves = isCluster
                            ? superCluster.getLeaves(
                                  geoJSONFeature.id,
                                  Infinity,
                                  0
                              )
                            : [geoJSONFeature];
                        return leaves.map((leaf: Feature) => {
                            // if stop clustering is enabled, use cluster coordinates for every cluster leaf
                            // cluster leaves will be grouped together in StackPinMarker
                            if (isClusteringStops && isCluster) {
                                const clusterCoordinates =
                                    geoJSONFeature.geometry.coordinates;
                                return {
                                    ...leaf,
                                    geometry: {
                                        ...leaf.geometry,
                                        coordinates: clusterCoordinates
                                    }
                                };
                            }
                            return leaf;
                        });
                    }
                );
                allStopLeaves.push(...liveStopLeaves);
            }
            return markerUtils.groupLeavesByLocation(allStopLeaves);
        },
        [isClusteringStops, getClusters]
    );

    const groupUnassignedTasks = useCallback(
        (unassignedSuperClusters: Supercluster.AnyProps[], unassignedTasks) => {
            const allUnassignedLeaves = [];
            if (isClusteringUnassignedTasks) {
                for (const unassignedSuperCluster of unassignedSuperClusters) {
                    const geoJSONFeatures = getClusters(unassignedSuperCluster);
                    const unassignedTaskLeaves = geoJSONFeatures.flatMap(
                        (geoJSONFeature) => {
                            const isCluster = geoJSONFeature.properties.cluster;
                            const leaves = isCluster
                                ? unassignedSuperCluster.getLeaves(
                                      geoJSONFeature.id,
                                      Infinity,
                                      0
                                  )
                                : [geoJSONFeature];
                            return leaves.map((leaf: Feature) => {
                                // if task clustering is enabled, use cluster coordinates for every cluster leaf
                                // cluster leaves will be grouped together in StackPinMarker
                                if (isClusteringUnassignedTasks && isCluster) {
                                    const clusterCoordinates =
                                        geoJSONFeature.geometry.coordinates;
                                    return {
                                        ...leaf,
                                        geometry: {
                                            ...leaf.geometry,
                                            coordinates: clusterCoordinates
                                        }
                                    };
                                }
                                return leaf;
                            });
                        }
                    );
                    allUnassignedLeaves.push(...unassignedTaskLeaves);
                }
                return markerUtils.groupLeavesByLocation(allUnassignedLeaves);
            }
            return markerUtils.groupUnassignedTasksByLocation(unassignedTasks);
        },
        [isClusteringUnassignedTasks, getClusters]
    );

    const makeLiveStopMarkers = useCallback(
        ({
            onDemandDispatchMarkerEventHandler,
            emittedEventHandler,
            liveStopsSuperClusters,
            unassignedSuperClusters = [],
            unassignedTasks = []
        }: MakeLiveStopMarkersProps) => {
            const stopMarkers: JSX.Element[] = [];
            let unassignedTasksGroupedByLocation = {};

            const shouldShowUnassignedTasks = Boolean(
                unassignedTasks?.length && unassignedSuperClusters?.length
            );

            if (shouldShowUnassignedTasks) {
                unassignedTasksGroupedByLocation = groupUnassignedTasks(
                    unassignedSuperClusters,
                    unassignedTasks
                );
            }

            const liveStopsGroupedByLocation = groupStopMarkers(
                liveStopsSuperClusters
            );

            const joinedByLocation = markerUtils.joinByLocation(
                liveStopsGroupedByLocation,
                unassignedTasksGroupedByLocation
            );

            for (const locationKey in joinedByLocation) {
                // eslint-disable-next-line
                // @ts-ignore
                const tasksByType = joinedByLocation[locationKey];
                const { live, unassigned } = tasksByType;
                const allTasks = [...live, ...unassigned];
                const totalTasks = allTasks.length;
                if (totalTasks < 2) {
                    const isLiveStop = Boolean(live.length);
                    if (isLiveStop) {
                        const marker = markerMaker.makeLiveStopMarker({
                            stopData: live[0],
                            mapRouteMode,
                            onDemandDispatchMarkerEventHandler,
                            emittedEventHandler
                        });
                        stopMarkers.push(marker);
                    } else {
                        const task = unassigned[0];
                        const taskMarkers =
                            markerMaker.makeUnassignedTaskMarkers(
                                task,
                                emittedEventHandler
                            );
                        stopMarkers.push(
                            ...(taskMarkers as unknown as JSX.Element[])
                        );
                    }
                } else {
                    const numClustered = totalTasks;
                    const { lat, lng } =
                        markerUtils.getLocationCoordinates(locationKey);
                    const id = idUtils.getCombinedId(
                        numClustered.toString(),
                        locationKey
                    );

                    const marker = (
                        <StackPinMarker
                            key={id}
                            numClustered={numClustered}
                            emittedEventHandler={emittedEventHandler}
                            onDemandDispatchMarkerEventHandler={
                                onDemandDispatchMarkerEventHandler as OnDemandDispatchMarkerEventHandler
                            }
                            tasks={allTasks}
                            lat={lat}
                            lng={lng}
                        />
                    );
                    stopMarkers.push(marker);
                }
            }
            return stopMarkers;
        },
        [groupUnassignedTasks, groupStopMarkers, mapRouteMode]
    );

    return {
        makeLiveStopMarkers
    };
};
