import React, { useMemo } from 'react';
import { FieldArray } from 'formik';
import { ApiInventoryItem, TwoPartTaskProps } from '~/api/types';
import { useDuplicateTaskModalContext } from '../DuplicateTaskModalContext';
import { DuplicateTaskModalInvoice } from './DuplicateTaskModalInvoice';
import './DuplicateTaskModalInvoices.scss';

export const DuplicateTaskModalInvoices = () => {
    const rootClassName = 'duplicate-task-modal-invoices';
    const { apiTask } = useDuplicateTaskModalContext();

    const invoices = useMemo(() => {
        const { props: taskProps } = apiTask;
        const { pickupInvoices = [], deliveryInvoices = [] } =
            taskProps as TwoPartTaskProps;
        const allInvoices = [...pickupInvoices, ...deliveryInvoices];
        return allInvoices;
    }, [apiTask]);

    const inventory = useMemo<Record<string, ApiInventoryItem[]>>(() => {
        const { props: taskProps } = apiTask;
        const { pickupInventory = [], deliveryInventory = [] } =
            taskProps as TwoPartTaskProps;
        const allInventory = [...pickupInventory, ...deliveryInventory];

        const inventoryMap = allInventory.reduce<
            Record<string, ApiInventoryItem[]>
        >((all, inventoryItem) => {
            const { invoice_number: invoiceNumber = 'other' } = inventoryItem;
            if (!all[invoiceNumber]) all[invoiceNumber] = [];
            all[invoiceNumber].push(inventoryItem);
            return all;
        }, {});

        return inventoryMap;
    }, [apiTask]);

    if (!invoices.length) return null;

    return (
        <div
            className={`${rootClassName} _d-flex _fd-column`}
            data-testid={rootClassName}
        >
            <FieldArray name="invoicesIds">
                {(invoiceRenderProps) => (
                    <>
                        {invoices.map((invoice) => (
                            <DuplicateTaskModalInvoice
                                key={invoice.invoiceId}
                                inventory={inventory[invoice.invoiceNumber]}
                                invoice={invoice}
                                invoiceRenderProps={invoiceRenderProps}
                            />
                        ))}
                    </>
                )}
            </FieldArray>
        </div>
    );
};
