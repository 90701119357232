import React from 'react';
import classNames from 'classnames';
import { IconButton } from '~/ui';
import { ManageStopControlListItemMetricsProps } from './types';

import './ManageStopControlListItemMetrics.scss';

export const ManageStopControlListItemMetrics = ({
    iconsData,
    className,
    'data-testid': dataTestId
}: ManageStopControlListItemMetricsProps) => {
    const ROOT_CLASS_NAME = 'manage-stop-control-list-item-metrics';
    const elementClassName = classNames(ROOT_CLASS_NAME, '_d-flex', className);
    const elementDataTestId = dataTestId || ROOT_CLASS_NAME;

    if (!iconsData?.length) return null;

    return (
        <div className={elementClassName} data-testid={elementDataTestId}>
            {iconsData.map(({ key, name, size, text }) => (
                <IconButton
                    disabled
                    key={key}
                    icon={name}
                    iconSize={size}
                    text={text}
                />
            ))}
        </div>
    );
};
