import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal, StopModalMenuButton } from '~/ui';
import { ChangeTaskStatusControlProps } from './types';
import { ModalSize } from '~/ui/components/Modal/types';
import { ChangeTaskStatusModalContent } from './ChangeTaskStatusModalContent';

export const ChangeTaskStatusControl = ({
    stopData
}: ChangeTaskStatusControlProps) => {
    const { t } = useTranslation('translation');

    const {
        showModal: showChangeTaskStatusModal,
        hideModal: hideChangeTaskStatusModal,
        modal: ChangeTaskStatusModal
    } = useModal();

    return (
        <>
            <StopModalMenuButton
                onClick={showChangeTaskStatusModal}
                buttonIcon="priority"
                buttonText={t('PlanStopsControl.button.changeTaskStatus')}
                data-testid="button-change-task-status"
            />
            <ChangeTaskStatusModal
                className="change-task-status-modal"
                variant={ModalSize.AUTO}
                hasAutoHide={false}
                hasCustomHeader
            >
                <ChangeTaskStatusModalContent
                    stopData={stopData}
                    onHideModal={hideChangeTaskStatusModal}
                />
            </ChangeTaskStatusModal>
        </>
    );
};
