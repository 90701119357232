import React from 'react';
import classNames from 'classnames';

import './ManageStopControl.scss';
import { ManageStopControlProps } from './types';

export const ManageStopControl = ({
    children,
    className,
    'data-testid': dataTestId
}: ManageStopControlProps) => {
    const ROOT_CLASS_NAME = 'managestopcontrol';
    const elementClassName = classNames(
        ROOT_CLASS_NAME,
        '_d-flex',
        '_fd-column',
        className
    );
    const elementDataTestId = dataTestId || ROOT_CLASS_NAME;

    return (
        <div className={elementClassName} data-testid={elementDataTestId}>
            {children}
        </div>
    );
};
