import axios from 'axios';
import { ApiAssignment, AxiosApiResponse } from '../types';
import { getAssignmentApiStatusParamErrors } from './validate';
import { AssignmentApiStatusParams } from './types';

/**
 * Implementations of API methods under the /assignment path
 *
 * @category API
 */
export default class AssignmentApi {
    /**
     * Path of the API endpoint
     */
    private static readonly path = '/assignment';

    static get(assignmentId: string): Promise<AxiosApiResponse<ApiAssignment>> {
        if (!assignmentId) {
            return Promise.reject('assignment id not provided');
        }
        return axios.get(`${this.path}/${assignmentId}`);
    }

    static getPortalInfo(
        assignmentId: string
    ): Promise<AxiosApiResponse<ApiAssignment>> {
        if (!assignmentId) {
            return Promise.reject('assignment id not provided');
        }
        return axios.get(`${this.path}/${assignmentId}/portal`);
    }

    static status({
        assignmentId,
        status,
        timestamp
    }: AssignmentApiStatusParams): Promise<AxiosApiResponse<ApiAssignment>> {
        const paramError = getAssignmentApiStatusParamErrors({
            assignmentId,
            status,
            timestamp
        });

        if (paramError) {
            return Promise.reject(paramError);
        }

        const payload = { status, timestamp };
        return axios.patch(`${this.path}/${assignmentId}/status`, payload);
    }

    static updateRevisedTimes({
        assignmentId,
        revisedStartServiceAt,
        revisedCompletedAt
    }: {
        assignmentId: string;
        revisedStartServiceAt?: string;
        revisedCompletedAt?: string;
    }): Promise<AxiosApiResponse<ApiAssignment>> {
        if (!assignmentId) {
            return Promise.reject('assignment id not provided');
        }

        if (!revisedStartServiceAt && !revisedCompletedAt) {
            return Promise.reject('At least one revised time must be provided');
        }

        return axios.patch(`${this.path}/${assignmentId}/revisedTimes`, {
            revisedStartServiceAt,
            revisedCompletedAt
        });
    }
}
