import { useMutation } from 'react-query';
import AssignmentApi from '~/api/AssignmentApi';
import { ApiAssignment, AxiosApiResponse } from '~/api/types';
import { AssignmentApiStatusParams } from '~/api/AssignmentApi/types';
import { getErrorMessage } from '~/utils/errorUtils';

export const useUpdateAssignmentStatus = () => {
    const {
        isLoading: isLoadingUpdateAssignmentStatus,
        mutateAsync: doUpdateAssignmentStatusAsync
    } = useMutation((params: AssignmentApiStatusParams): Promise<
        AxiosApiResponse<ApiAssignment>
    > => {
        return AssignmentApi.status(params);
    });

    const updateAssignmentStatus = (payload: AssignmentApiStatusParams) => {
        return doUpdateAssignmentStatusAsync(payload);
    };

    const updateAssignmentStatusInSequence = async (
        payload: AssignmentApiStatusParams[]
    ) => {
        const allResponses = [];
        for (const params of payload) {
            try {
                // eslint-disable-next-line no-await-in-loop
                const response = await doUpdateAssignmentStatusAsync(params);
                allResponses.push(response);
            } catch (error) {
                const message = getErrorMessage(error);
                console.error(message);
                throw error;
            }
        }

        return allResponses;
    };

    return {
        isLoadingUpdateAssignmentStatus,
        updateAssignmentStatus,
        updateAssignmentStatusInSequence
    };
};
