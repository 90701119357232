/**
 * The local storage key for the exported routes snapshot of the fresh data
 */
export const EXPORTED_ROUTES_STORAGE_KEY = 'exportedRoutes';

/**
 * The duration since export date-time to consider the export data fresh
 */
export const EXPORTED_ROUTES_EXPIRY_DURATION = { hours: 4 };

/**
 * The resolved exported routes data
 *
 * The `key` is expected to be a client-route ID
 * The `value` is expected to be an ISO Date-Time string
 */
export type ExportedRoutesData = Record<string, string>;
