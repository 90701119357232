import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';

import {
    selectExportedRoutes,
    setExportedRoutes
} from '~/reducers/exportedRoutesSlice';

import { convertDateTimeToUTCISOString } from '~/utils/date-utils';
import { EXPORTED_ROUTES_EXPIRY_DURATION, ExportedRoutesData } from './types';

export const useExportRoutesToStorage = () => {
    const dispatch = useDispatch();
    const exportsFromStore = useSelector(selectExportedRoutes);

    /**
     * Fresh exported routes data
     *
     * removes the expired exported routes data based on defined duration
     */
    const freshExports = useMemo<ExportedRoutesData>(() => {
        const now = DateTime.now();

        const fresh = Object.entries(
            exportsFromStore
        ).reduce<ExportedRoutesData>((compiled, exportedRouteData) => {
            const [clientRouteId, expiryDate] = exportedRouteData;
            const expiresAt = DateTime.fromISO(expiryDate || '').plus(
                EXPORTED_ROUTES_EXPIRY_DURATION
            );
            const isFresh = expiresAt.isValid && expiresAt > now;

            if (isFresh) {
                compiled[clientRouteId] = expiryDate as string;
            }

            return compiled;
        }, {});

        return fresh;
    }, [exportsFromStore]);

    /**
     * Sets the selected client route IDs as exported routes
     */
    const saveExportedRoutesToStorage = useCallback(
        (clientRouteIds: string[]) => {
            const now = DateTime.now();
            const exportedAt = convertDateTimeToUTCISOString(now);
            const updatedExports = clientRouteIds.reduce<
                Record<string, string>
            >((compiled, clientRouteId) => {
                compiled[clientRouteId] = exportedAt;
                return compiled;
            }, {});
            dispatch(setExportedRoutes({ ...freshExports, ...updatedExports }));
        },
        [freshExports, dispatch]
    );

    return { saveExportedRoutesToStorage, existingExports: freshExports };
};
