/**
 * the `allowed` white labeled domains list
 *
 * Define a custom domain in this object to allow the `wise` application logos
 * to be displayed while being loaded in an `iframe`
 *
 * To use custom application logos while in an iframe,
 * refer to the `customLogos` file
 */
export const whiteLabeledDomains: Record<string, string> = {
    intellishift: 'https://app.intellishift.com'
};
