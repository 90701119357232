/**
 * An enum of API parameter validation error messages
 */
export enum ApiParamError {
    GENERIC_INVALID_PARAM_STRING_DATE_ISO = 'Invalid parameter, must be a ISO 8601 date time string',
    GENERIC_INVALID_PARAM_STRING_UUID = 'Invalid parameter, must be a UUID string',
    GENERIC_INVALID_PARAM_INTEGER = 'Invalid parameter, must be an integer number',
    GENERIC_MISSING_PARAM = 'Missing parameter',
    INVALID_ASSIGNMENT_ID = 'Invalid `assignment ID` parameter, must be a UUID string',
    INVALID_ASSIGNMENT_STATUS = 'Invalid `assignment status` parameter',
    INVALID_DRIVER_ID = 'Invalid `driver ID` parameter, must be a UUID string',
    INVALID_TIMESTAMP = 'Invalid `timestamp` parameter, must be a ISO 8601 date-time string',
    MISSING_ASSIGNMENT_ID = 'Missing `assignment ID` parameter',
    MISSING_ASSIGNMENT_STATUS = 'Missing `assignment status` parameter',
    MISSING_DRIVER_ID = 'Missing `driver ID` parameter',
    MISSING_TIMESTAMP = 'Missing `timestamp` parameter',
    INVALID_DELIVERY_WINDOW = 'Invalid delivery window(s)',
    INVALID_PICKUP_WINDOW = 'Invalid pickup window(s)'
}
