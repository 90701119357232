/**
 * Get the correct error format from Error
 *
 * @returns {string}
 */
export const getErrorMessage = (error: unknown): string => {
    if (error instanceof Error) {
        return error.message;
    }
    return String(error);
};
