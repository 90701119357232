import { AssignedWebColor } from '~/api/types';
import { PlanRoute } from '~/data-classes';
import { planDataFactory } from '~/utils/data-factory';

export const getPlanRoutesAndMarkerColors = (
    planRoutesLevelData: Record<string, unknown>[]
) => {
    const planRoutesEffect = [] as PlanRoute[];
    const markerColors = {} as Record<string, AssignedWebColor>;

    planRoutesLevelData.forEach((routeLevelData, index) => {
        const planRoute = planDataFactory.makePlanRoute(routeLevelData, index);
        const { clientRouteId, colorCSS } = planRoute;
        planRoutesEffect.push(planRoute);
        markerColors[clientRouteId] = colorCSS;
    });

    return {
        planRoutesEffect,
        markerColors
    };
};
