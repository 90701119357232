import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiUserGroup } from '~/api/types';
import { resetOnLogout } from '~/reducers/common-actions';
import type { RootState } from '~/store';

export const userGroupsForCurrentUserSlice = createSlice({
    name: 'userGroupsForCurrentUser',
    initialState: [] as ApiUserGroup[],
    reducers: {
        setUserGroupsForCurrentUser: (
            state: ApiUserGroup[],
            action: PayloadAction<ApiUserGroup[]>
        ) => {
            return action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return [];
        });
    }
});

export const { setUserGroupsForCurrentUser } =
    userGroupsForCurrentUserSlice.actions;

export const selectUserGroupsForCurrentUser = (
    state: RootState
): ApiUserGroup[] => state.userGroupsForCurrentUser;

export default userGroupsForCurrentUserSlice.reducer;
