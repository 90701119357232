import { useQuery, UseQueryOptions } from 'react-query';
import { GetRoutesForDateParams, RoutesApi } from '~/api/RoutesApi';
import { ApiRoute } from '~/api/types';

import dateUtils from '~/utils/date-utils';

type UseGetRoutesReactQueryOptions = Omit<
    UseQueryOptions<ApiRoute[], Error>,
    'queryKey' | 'queryFn'
>;

const REACT_QUERY_KEY = 'routes';

export const useGetRoutes = (
    { date: routeDate }: GetRoutesForDateParams,
    reactQueryOptions: UseGetRoutesReactQueryOptions = {}
) => {
    const validatedRouteDate =
        routeDate && dateUtils.convertToISODateOnly(routeDate);

    const fetchRoutes = async (
        options: GetRoutesForDateParams
    ): Promise<ApiRoute[]> => {
        const {
            data: { data }
        } = await RoutesApi.getRoutesByDate(options);
        return data;
    };

    // do not run when the start date is not valid
    const defaultReactQueryOptions = {
        enabled: Boolean(validatedRouteDate)
    };

    // merge query options
    const mergedOptions: UseGetRoutesReactQueryOptions = {
        ...defaultReactQueryOptions,
        ...reactQueryOptions
    };

    // api options
    const apiOptions: GetRoutesForDateParams = {
        date: validatedRouteDate ?? ''
    };

    // return the full useQuery Object
    return useQuery<ApiRoute[], Error>(
        [REACT_QUERY_KEY, validatedRouteDate],
        () => fetchRoutes(apiOptions),
        mergedOptions
    );
};
