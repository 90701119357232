import { Dispatch, SetStateAction } from 'react';
import { ChangeTaskStatusStopData } from '../useChangeTaskStatusStopData/types';

export enum ChangeTaskStatusModalSelectedTaskAlert {
    PICKUP = 'selectTask.alert.pickup',
    DELIVERY = 'selectTask.alert.delivery'
}

export interface ChangeTaskStatusModalSelectedTaskProps {
    /**
     * the live stop data
     */
    stopData?: ChangeTaskStatusStopData;

    /**
     * The live stop revised completed date/time
     */
    selectedStopId?: string;

    /**
     * A `setter` to update the live stop revised arrival date/time
     */
    setSelectedStopId: Dispatch<SetStateAction<string | undefined>>;
}
