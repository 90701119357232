import { DateTime, Duration } from 'luxon';
import { convertDateTimeToUTCISOString } from '~/utils/date-utils';
import { AssignmentStatus, TaskTypeRecord } from '~/api/types';
import {
    ChangeTaskStatusPayload,
    GetAllChangeTaskStatusPayloadParams,
    GetChangeTaskStatusPayloadParams,
    GetChangeTaskStatusTimesParams,
    GetSingleChangeTaskStatusPayloadParams,
    GetTwoPartChangeTaskStatusPayloadParams,
    GetTwoPartPickupChangeTaskStatusPayloadParams,
    IsReadyChangeTaskStatusParams
} from './types';
import { LiveStop } from '~/data-classes';

export const isTwoPartStopData = (
    stopData?: TaskTypeRecord<LiveStop>
): stopData is Required<TaskTypeRecord<LiveStop>> => {
    if (!stopData) return false;

    const { pickup, delivery } = stopData;
    return Boolean(pickup && delivery);
};

export const isTwoPartPickupChangeRequired = (stopData: LiveStop) => {
    const { isAssigned, isInProgress } = stopData;

    return isAssigned || isInProgress;
};

export const getChangeTaskStatusStopData = (
    stopData: LiveStop[],
    selectedStopId?: string
) => {
    const pickupStopData = stopData.find((liveStop) => liveStop.isPickup);
    const deliveryStopData = stopData.find((liveStop) => liveStop.isDelivery);
    const selectedStopData =
        stopData.length === 1
            ? stopData[0]
            : stopData.find((liveStop) => liveStop.id === selectedStopId);

    return {
        pickup: pickupStopData,
        delivery: deliveryStopData,
        selected: selectedStopData
    };
};

const isTwoPartChangeTaskStatus = (
    stopData: TaskTypeRecord<LiveStop>,
    selectedStopData: LiveStop
) => {
    const isTwoPart = isTwoPartStopData(stopData);

    const isDeliverySelected = isTwoPart && selectedStopData.isDelivery;

    const isRequiredPickupChangeTaskStatus =
        isTwoPart && isTwoPartPickupChangeRequired(stopData.pickup);

    return isDeliverySelected && isRequiredPickupChangeTaskStatus;
};

const getPayloadAssigned = ({
    id: assignmentId,
    timestamp
}: GetChangeTaskStatusPayloadParams): ChangeTaskStatusPayload => {
    return {
        status: AssignmentStatus.ASSIGNED,
        assignmentId,
        timestamp
    };
};

const getPayloadInProgress = ({
    id: assignmentId,
    timestamp
}: GetChangeTaskStatusPayloadParams): ChangeTaskStatusPayload => {
    return {
        status: AssignmentStatus.IN_PROGRESS,
        assignmentId,
        timestamp
    };
};

const getPayloadCompleted = ({
    id: assignmentId,
    timestamp
}: GetChangeTaskStatusPayloadParams): ChangeTaskStatusPayload => {
    return {
        status: AssignmentStatus.COMPLETED,
        assignmentId,
        timestamp
    };
};

const getPayloadCanceled = ({
    id: assignmentId,
    timestamp
}: GetChangeTaskStatusPayloadParams): ChangeTaskStatusPayload => {
    return {
        status: AssignmentStatus.CANCELED,
        assignmentId,
        timestamp
    };
};

const getSingleChangeTaskStatusPayload = ({
    id,
    currentStatus,
    revisedStatus,
    revisedArrival,
    revisedCompleted
}: GetSingleChangeTaskStatusPayloadParams): ChangeTaskStatusPayload[] => {
    const isFromAssigned = currentStatus === AssignmentStatus.ASSIGNED;
    const isFromInProgress = currentStatus === AssignmentStatus.IN_PROGRESS;
    const isFromAllowedStatus = isFromAssigned || isFromInProgress;

    const isToAssigned = revisedStatus === AssignmentStatus.ASSIGNED;
    const isToCompleted = revisedStatus === AssignmentStatus.COMPLETED;
    const isToCanceled = revisedStatus === AssignmentStatus.CANCELED;

    const isUnarrive = isFromInProgress && isToAssigned;
    if (isUnarrive) {
        const now = DateTime.now();
        const timestamp = convertDateTimeToUTCISOString(now);
        return [getPayloadAssigned({ id, timestamp })];
    }

    const changeArrival = isFromAllowedStatus && revisedArrival;
    const changeCompletedTime =
        isFromAllowedStatus && isToCompleted && revisedCompleted;
    const changeCanceledTime =
        isFromAllowedStatus && isToCanceled && revisedCompleted;

    const payload = [
        changeArrival &&
            getPayloadInProgress({ id, timestamp: revisedArrival }),
        changeCompletedTime &&
            getPayloadCompleted({ id, timestamp: revisedCompleted }),
        changeCanceledTime &&
            getPayloadCanceled({ id, timestamp: revisedCompleted })
    ].filter(Boolean) as ChangeTaskStatusPayload[];

    return payload;
};

export const getTwoPartPickupChangeTaskStatusPayload = ({
    id,
    currentStatus,
    revisedArrival,
    revisedCompleted
}: GetTwoPartPickupChangeTaskStatusPayloadParams): ChangeTaskStatusPayload[] => {
    const isFromAssigned = currentStatus === AssignmentStatus.ASSIGNED;
    const isFromInProgress = currentStatus === AssignmentStatus.IN_PROGRESS;
    const isFromAllowedStatus = isFromAssigned || isFromInProgress;

    const changeArrival = isFromAssigned && revisedArrival;
    const changeCompletedTime = isFromAllowedStatus && revisedCompleted;

    const payload = [
        changeArrival &&
            getPayloadInProgress({ id, timestamp: revisedArrival }),
        changeCompletedTime &&
            getPayloadCompleted({ id, timestamp: revisedCompleted })
    ].filter(Boolean) as ChangeTaskStatusPayload[];

    return payload;
};

const getTwoPartChangeTaskStatusPayload = ({
    stopData,
    revisedStatus: deliveryRevisedStatus,
    revisedArrival: deliveryRevisedArrival,
    revisedCompleted: deliveryRevisedCompleted
}: GetTwoPartChangeTaskStatusPayloadParams): ChangeTaskStatusPayload[] => {
    const {
        pickup: { id: pickupId, status: pickupStatus },
        delivery: {
            id: deliveryId,
            status: deliveryStatus,
            serviceTime: deliveryServiceTime
        }
    } = stopData;

    const deliveryPayload = getSingleChangeTaskStatusPayload({
        id: deliveryId,
        currentStatus: deliveryStatus,
        revisedStatus: deliveryRevisedStatus,
        revisedArrival: deliveryRevisedArrival,
        revisedCompleted: deliveryRevisedCompleted
    });

    if (!deliveryPayload.length) return [];

    // pickup must be completed prior to changing the task status of the delivery
    // + revised pickup `arrival` and `completed` times will be calculated from delivery `arrival`
    // + delivery `arrival` time is confirmed to be a `string` once it passes `deliveryPayload`
    const deliveryArrivalDateTime = DateTime.fromISO(
        deliveryRevisedArrival as string
    );
    const serviceDuration = Duration.fromISO(deliveryServiceTime).toObject();
    const pickupRevisedArrival = convertDateTimeToUTCISOString(
        deliveryArrivalDateTime.minus(serviceDuration)
    );
    const pickupRevisedCompleted = convertDateTimeToUTCISOString(
        deliveryArrivalDateTime
    );

    const pickupPayload = getTwoPartPickupChangeTaskStatusPayload({
        id: pickupId,
        currentStatus: pickupStatus,
        revisedArrival: pickupRevisedArrival,
        revisedCompleted: pickupRevisedCompleted
    });

    return [...pickupPayload, ...deliveryPayload];
};

export const getChangeTaskStatusPayload = ({
    stopData,
    currentStatus,
    revisedStatus,
    revisedArrival,
    revisedCompleted
}: GetAllChangeTaskStatusPayloadParams): ChangeTaskStatusPayload[] => {
    const { selected: selectedStopData, ...twoPartStopData } = stopData;

    if (!selectedStopData) return [];

    const isTwoPartChange = isTwoPartChangeTaskStatus(
        twoPartStopData,
        selectedStopData
    );

    if (isTwoPartChange) {
        return getTwoPartChangeTaskStatusPayload({
            stopData: twoPartStopData as Required<TaskTypeRecord<LiveStop>>,
            revisedStatus,
            revisedArrival,
            revisedCompleted
        });
    }

    return getSingleChangeTaskStatusPayload({
        id: selectedStopData.id,
        currentStatus,
        revisedStatus,
        revisedArrival,
        revisedCompleted
    });
};

const isValidStopTimes = ({
    currentArrival,
    revisedStatus,
    revisedArrival,
    revisedCompleted
}: IsReadyChangeTaskStatusParams) => {
    // when changing to `in-progress`, the `arrival` time must be changed
    const isArrivalChanged = currentArrival !== revisedArrival;
    const isToInProgress = revisedStatus === AssignmentStatus.IN_PROGRESS;
    if (isToInProgress) return isArrivalChanged;

    // when changing both `arrival` and `completed` times
    // enforce a minimum of 1 minute difference
    // + the <input type="time" /> is not capable of displaying seconds
    // + by enforcing 1 minute, the UI and validation will match
    const revisedArrivalDateTime = DateTime.fromISO(revisedArrival);
    const revisedCompletedDateTime = DateTime.fromISO(revisedCompleted);
    const revisedTimesDiff =
        revisedCompletedDateTime.toMillis() - revisedArrivalDateTime.toMillis();
    const isDiffValid = revisedTimesDiff >= 60000;

    return isDiffValid;
};

export const isReadyChangeTaskStatus = ({
    id,
    currentStatus,
    currentArrival,
    revisedStatus,
    revisedArrival,
    revisedCompleted
}: IsReadyChangeTaskStatusParams) => {
    const isStopSelected = Boolean(id);

    // while the stop is `in-transit` to its destination, we cannot change the time
    const isFromAssigned = currentStatus === AssignmentStatus.ASSIGNED;
    const isToAssigned = revisedStatus === AssignmentStatus.ASSIGNED;
    const isStopInTransit = isFromAssigned && isToAssigned;

    if (!isStopSelected || isStopInTransit) return false;

    const isStopTimesChanged = isValidStopTimes({
        currentStatus,
        currentArrival,
        revisedStatus,
        revisedArrival,
        revisedCompleted
    });

    return isStopTimesChanged;
};

export const getChangeTaskStatusTimes = ({
    serviceTime,
    currentArrival,
    currentCompleted,
    revisedArrival,
    revisedCompleted
}: GetChangeTaskStatusTimesParams) => {
    const now = DateTime.now();
    const currentArrivalDateTime = currentArrival
        ? DateTime.fromISO(currentArrival)
        : now;
    const serviceDuration = serviceTime
        ? Duration.fromISO(serviceTime).toObject()
        : { hour: 1 };
    const defaultArrival = convertDateTimeToUTCISOString(now);
    const defaultCompleted = convertDateTimeToUTCISOString(
        currentArrivalDateTime.plus(serviceDuration)
    );

    const changedArrival = revisedArrival || currentArrival || defaultArrival;
    const changedCompleted =
        revisedCompleted || currentCompleted || defaultCompleted;

    return {
        changedArrival,
        changedCompleted
    };
};
