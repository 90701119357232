import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    resetSelectedMapStops,
    selectSelectedMapStops
} from '~/reducers/selectedMapStopsSlice';
import { selectDispatchedwithCompletedDrivers } from '~/reducers/liveDriversSlice';
import { mapStopIdsToLiveStops } from '~/hooks/useResequenceDriverTasks';

export const useLiveStopsControl = () => {
    const dispatch = useDispatch();
    const dispatchedDrivers = useSelector(selectDispatchedwithCompletedDrivers);
    const selectedMapStopsIds = useSelector(selectSelectedMapStops);

    const isMultiStopsSelected = selectedMapStopsIds.length > 1;

    const {
        driver,
        firstSelectedStop,
        selectedStops = [],
        unselectedStops = []
    } = useMemo(() => {
        return mapStopIdsToLiveStops({
            dispatchedDrivers,
            selectedMapStopsIds
        });
    }, [dispatchedDrivers, selectedMapStopsIds]);

    const isTwoPartTask = useMemo(() => {
        if (selectedStops.length === 2) {
            const { taskId: firstStopTaskId } = selectedStops[0];
            const { taskId: secondStopTaskId } = selectedStops[1];
            return firstStopTaskId === secondStopTaskId;
        }
        return false;
    }, [selectedStops]);

    const handleDeselectAll = useCallback(() => {
        dispatch(resetSelectedMapStops());
    }, [dispatch]);

    return {
        isMultiStopsSelected,
        driver,
        firstSelectedStop,
        selectedStops,
        unselectedStops,
        isTwoPartTask,
        handleDeselectAll
    };
};
