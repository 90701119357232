import { DateTime } from 'luxon';
import { GetDateTimeValuesProps } from './types';

export const DEFAULT_SELECTED_TIME = '00:00';

export const getDateTimeValues = ({
    initialTime,
    revisedTime
}: GetDateTimeValuesProps) => {
    const initialDateTime = DateTime.fromISO(initialTime || '');

    const initialSelectedDate = initialDateTime.isValid
        ? initialDateTime.toJSDate()
        : new Date();

    const initialSelectedTime = initialDateTime.isValid
        ? initialDateTime.toFormat('HH:mm')
        : DEFAULT_SELECTED_TIME;

    const revisedDateTime = DateTime.fromISO(revisedTime || '');

    const revisedSelectedDate = revisedDateTime.isValid
        ? revisedDateTime.toJSDate()
        : initialSelectedDate;

    const revisedSelectedTime = revisedDateTime.isValid
        ? revisedDateTime.toFormat('HH:mm')
        : initialSelectedTime;

    const defaultDateTime = initialDateTime.isValid
        ? initialDateTime
        : DateTime.now();

    const displayDateTime = revisedDateTime.isValid
        ? revisedDateTime
        : defaultDateTime;

    const displayDateTimeValue = displayDateTime
        .setLocale(navigator.language)
        .toLocaleString(DateTime.DATETIME_SHORT);

    return {
        initialDateTime,
        initialSelectedDate,
        initialSelectedTime,
        revisedDateTime,
        revisedSelectedDate,
        revisedSelectedTime,
        displayDateTime,
        displayDateTimeValue
    };
};
