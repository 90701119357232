import { useCallback, useMemo } from 'react';
import Supercluster from 'supercluster';

import { idUtils } from '~/utils/id-utils';
import { getUniqueDepots } from '~/utils/map-modes/depotUtils';
import { useMakeDepotMarkers } from '~/hooks/useMapMarkers/useMakeDepotMarkers';
import { ApiLiveStop, ApiTask, Coordinates } from '~/api/types';
import {
    HookOnDemandDispatchMarkerEventHandler,
    useIntermodalFeatures
} from '~/hooks';
import { EmittedEventHandler } from '~/components/MapPage/PlanMap/types';
import { useSelector } from 'react-redux';
import {
    selectCompletedDrivers,
    selectDispatchedDrivers
} from '~/reducers/liveDriversSlice';
import { selectSelectedDrawerCardData } from '~/reducers/selectedDrawerCardDataSlice';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';
import { useSelectedMapRoutes } from '~/components/MapPage/useSelectedMapRoutes';
import {
    useMakeLiveRouteLineAndDriverMarker,
    useMakeLiveStopMarkers
} from '~/hooks/useMapMarkers';

interface MakeLiveRoutesComponentsProps {
    onDemandDispatchMarkerEventHandler: HookOnDemandDispatchMarkerEventHandler;
    emittedEventHandler: EmittedEventHandler;
    liveStopsSuperClusters: Supercluster.AnyProps[];
    unassignedSuperClusters: Supercluster.AnyProps[];
    unassignedTasks: ApiTask[];
}

type LiveRoutesComponent = {
    liveRoutesStopMarkers: JSX.Element[];
    liveRoutesIsolatedRouteLines: JSX.Element[];
    liveRoutesDepotMarkers: JSX.Element[];
};

export const useMakeLiveRouteMarkers = () => {
    const dispatchedDrivers = useSelector(selectDispatchedDrivers);
    const completedDrivers = useSelector(selectCompletedDrivers);

    const { enableLiveDispatch } = useIntermodalFeatures();

    const liveDrivers = useMemo(() => {
        if (!enableLiveDispatch) return dispatchedDrivers;
        return [...dispatchedDrivers, ...completedDrivers];
    }, [dispatchedDrivers, completedDrivers, enableLiveDispatch]);

    // @TODO type PlanMapPropsContext https://wisesys.atlassian.net/browse/RP-840
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { routesLevelData } = usePlanMapPropsContext();
    const { selectedMapRoutes } = useSelectedMapRoutes({
        planRoutes: routesLevelData
    });

    const { data: selectedDrawerCard } = (useSelector(
        selectSelectedDrawerCardData
    ) ?? {}) as {
        data?: {
            cepLocation: Coordinates;
            schedule: ApiLiveStop[];
        };
    };
    const selectedDrawerRouteId = useSelector(selectSelectedDrawerCardId);

    const { makeLiveRoutesStopComponents } =
        useMakeLiveRouteLineAndDriverMarker();
    const { makeDepotMarkers } = useMakeDepotMarkers();
    const { makeLiveStopMarkers } = useMakeLiveStopMarkers();

    const selectedClientDriverIds = useMemo(() => {
        return selectedDrawerCard ? [selectedDrawerRouteId] : selectedMapRoutes;
    }, [selectedDrawerCard, selectedMapRoutes, selectedDrawerRouteId]);

    const makeLiveRoutesComponents = useCallback(
        ({
            onDemandDispatchMarkerEventHandler,
            emittedEventHandler,
            liveStopsSuperClusters,
            unassignedSuperClusters,
            unassignedTasks
        }: MakeLiveRoutesComponentsProps): LiveRoutesComponent => {
            const selectedDrivers = liveDrivers.filter((driver) => {
                return selectedClientDriverIds.includes(
                    idUtils.getCombinedId(driver.clientId, driver.id)
                );
            });

            const selectedDriversComponents = selectedDrivers.map(
                (selectedDriver) => {
                    return makeLiveRoutesStopComponents({
                        selectedDriver
                    });
                }
            );

            const uniqueDepots = getUniqueDepots(selectedDrivers);
            const depotMarkers = makeDepotMarkers(uniqueDepots);

            const areDriversSelected = Boolean(selectedDrivers.length);

            const stopMarkers = makeLiveStopMarkers({
                onDemandDispatchMarkerEventHandler,
                emittedEventHandler,
                liveStopsSuperClusters: areDriversSelected
                    ? liveStopsSuperClusters
                    : [],
                unassignedSuperClusters,
                unassignedTasks
            });

            const filteredArtefacts = selectedDriversComponents.reduce(
                (
                    allComponents: {
                        liveDriverMarkers: JSX.Element[];
                        liveRoutesIsolatedRouteLines: JSX.Element[];
                    },
                    currentDriverComponents: ReturnType<
                        typeof makeLiveRoutesStopComponents
                    >
                ) => {
                    const { routeLines, driverMarker } =
                        currentDriverComponents;
                    allComponents.liveDriverMarkers.push(driverMarker);
                    allComponents.liveRoutesIsolatedRouteLines.push(
                        ...routeLines
                    );
                    return allComponents;
                },
                {
                    liveDriverMarkers: [],
                    liveRoutesIsolatedRouteLines: []
                }
            );

            return {
                liveRoutesStopMarkers: [
                    ...filteredArtefacts.liveDriverMarkers,
                    ...stopMarkers
                ],
                liveRoutesIsolatedRouteLines:
                    filteredArtefacts.liveRoutesIsolatedRouteLines,
                liveRoutesDepotMarkers: depotMarkers
            };
        },
        [
            makeDepotMarkers,
            selectedClientDriverIds,
            liveDrivers,
            makeLiveRoutesStopComponents,
            makeLiveStopMarkers
        ]
    );

    return {
        makeLiveRoutesComponents
    };
};
