import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDatePicker, {
    ReactDatePickerCustomHeaderProps
} from 'react-datepicker';

import classNames from 'classnames';
import { IconButton, TooltipButton, Icon } from '~/ui';
import { IconSizes } from '~/ui/components/Icon/IconSizes';

import { DateTimePickerHeader } from '../DateTimePickerHeader';
import { DateTimePickerTimeInput } from '../DateTimePickerTimeInput';
import { DateTimePickerProps } from './types';
import './DateTimePicker.scss';

const ROOT_CLASS_NAME = 'date-time-picker';

/**
 * Custom `react-datepicker` header with custom options
 */
const CustomHeader = (props: ReactDatePickerCustomHeaderProps) => {
    const calendarHeaderClassName = `${ROOT_CLASS_NAME}__calendar-header`;

    return (
        <DateTimePickerHeader
            {...props}
            hideYearButtons
            className={calendarHeaderClassName}
            data-testid={calendarHeaderClassName}
        />
    );
};

export const DateTimePicker = ({
    title,
    id,
    startTime,
    buttonClearLabel,
    buttonApplyLabel,
    startLabel,
    endTimeLabel,
    endTime,
    selectedDate,
    onChangeStartTime,
    onChangeEndTime,
    onChangeDate,
    onClose,
    onClear,
    onApply,
    className,
    'data-testid': dataTestId
}: DateTimePickerProps) => {
    const elementClassName = classNames(ROOT_CLASS_NAME, className);
    const headerClassName = `${ROOT_CLASS_NAME}__header`;
    const bodyClassName = `${ROOT_CLASS_NAME}__body`;
    const footerClassName = `${ROOT_CLASS_NAME}__footer`;
    const titleClassName = `${ROOT_CLASS_NAME}__title`;
    const buttonCloseClassName = `${ROOT_CLASS_NAME}__button-close`;
    const buttonClearClassName = `${ROOT_CLASS_NAME}__button-clear`;
    const buttonApplyClassName = `${ROOT_CLASS_NAME}__button-apply`;
    const calendarClassName = `${ROOT_CLASS_NAME}__calendar`;
    const timeSelectClassName = `${ROOT_CLASS_NAME}__time-select`;
    const timeSelectStartClassName = `${ROOT_CLASS_NAME}__time-select-start`;
    const timeSelectEndClassName = `${ROOT_CLASS_NAME}__time-select-end`;

    const elementDataTestId = dataTestId || ROOT_CLASS_NAME;
    const buttonCloseDataTestId = `${elementDataTestId}__button-close`;
    const buttonClearDataTestId = `${elementDataTestId}__button-clear`;
    const buttonApplyDataTestId = `${elementDataTestId}__button-apply`;
    const timeSelectStartDataTestId = `${elementDataTestId}__time-select-start`;
    const timeSelectEndDataTestId = `${elementDataTestId}__time-select-end`;

    const showTimeSelect = Boolean(onChangeStartTime || onChangeEndTime);
    const showCalendar = Boolean(onChangeDate);

    const { t } = useTranslation(['common']);

    const labelStart = startLabel || t('start');
    const labelEndTime = endTimeLabel || t('end');
    const labelButtonClear = buttonClearLabel || t('clearSelection');
    const labelButtonApply = buttonApplyLabel || t('apply');

    const handleDateChange = useCallback(
        (value: Date | null) => {
            onChangeDate?.(value);
        },
        [onChangeDate]
    );

    /**
     * This function will clear the selected date if clicked again
     */
    const handleDateSelection = useCallback(
        (value: Date) => {
            const isSameDate = value?.toString() === selectedDate?.toString();
            if (isSameDate) handleDateChange(null);
        },
        [handleDateChange, selectedDate]
    );

    return (
        <div className={elementClassName} data-testid={elementDataTestId}>
            <div className={headerClassName}>
                <Icon icon="calendarLine" color="galaxy-300" />
                <div className={titleClassName}>{title}</div>
                <IconButton
                    className={buttonCloseClassName}
                    icon="iconCancel"
                    iconColor="galaxy-800"
                    iconSize={IconSizes.S}
                    onClick={onClose}
                    data-testid={buttonCloseDataTestId}
                />
            </div>
            <div className={bodyClassName}>
                {showTimeSelect && (
                    <div className={timeSelectClassName}>
                        {onChangeStartTime && (
                            <DateTimePickerTimeInput
                                label={labelStart}
                                value={startTime}
                                onChange={onChangeStartTime}
                                className={timeSelectStartClassName}
                                data-testid={timeSelectStartDataTestId}
                            />
                        )}
                        {onChangeEndTime && (
                            <DateTimePickerTimeInput
                                label={labelEndTime}
                                value={endTime}
                                onChange={onChangeEndTime}
                                className={timeSelectEndClassName}
                                data-testid={timeSelectEndDataTestId}
                            />
                        )}
                    </div>
                )}
                {showCalendar && (
                    <ReactDatePicker
                        id={id}
                        showPopperArrow={false}
                        calendarClassName={calendarClassName}
                        selected={selectedDate}
                        onChange={handleDateChange}
                        onSelect={handleDateSelection}
                        inline
                        renderCustomHeader={CustomHeader}
                    />
                )}
            </div>
            <div className={footerClassName}>
                <TooltipButton
                    onClick={onClear}
                    className={buttonClearClassName}
                    variant="link"
                    data-testid={buttonClearDataTestId}
                >
                    {labelButtonClear}
                </TooltipButton>
                <TooltipButton
                    onClick={onApply}
                    className={buttonApplyClassName}
                    variant="primary"
                    data-testid={buttonApplyDataTestId}
                >
                    {labelButtonApply}
                </TooltipButton>
            </div>
        </div>
    );
};
