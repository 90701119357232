import React from 'react';
import classNames from 'classnames';
import { Icon } from '~/ui';
import { AlertProps, AlertVariants } from './types';
import './alert.scss';

const Alert = ({
    variant,
    icon,
    iconColor = 'galaxy-400',
    iconSize = 's',
    className,
    children,
    'data-testid': dataTestId
}: AlertProps) => {
    const rootClassName = 'alert';
    const conditionalClasses = {
        [`${rootClassName}__has-icon`]: icon,
        [`${rootClassName}--${variant}`]: variant,
        [`${className}`]: className,
        [`${className}--${variant}`]: className && variant
    };
    const elementClassName = classNames(
        rootClassName,
        '_d-flex',
        '_ai-center',
        conditionalClasses
    );
    const detailsClassName = classNames(`${rootClassName}__details`, {
        [`${className}__details`]: className
    });

    const rootDataTestId = dataTestId || rootClassName;

    return (
        <div className={elementClassName} data-testid={rootDataTestId}>
            {icon && (
                <Icon
                    icon={icon}
                    color={iconColor}
                    size={iconSize}
                    data-testid={`${rootDataTestId}__icon`}
                />
            )}
            <div
                className={detailsClassName}
                data-testid={`${rootDataTestId}__details`}
            >
                {children}
            </div>
        </div>
    );
};

Alert.variants = AlertVariants;

export default Alert;
