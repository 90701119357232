import React from 'react';
import classNames from 'classnames';
import { ManageStopControlMenuProps } from './types';
import './ManageStopControlMenu.scss';

export const ManageStopControlMenu = ({
    children,
    className,
    'data-testid': dataTestId
}: ManageStopControlMenuProps) => {
    const ROOT_CLASS_NAME = 'managestopcontrol_menu';
    const elementClassName = classNames(
        ROOT_CLASS_NAME,
        '_fd-column',
        className
    );
    const elementDataTestId = dataTestId || ROOT_CLASS_NAME;

    return (
        <div className={elementClassName} data-testid={elementDataTestId}>
            {children}
        </div>
    );
};
