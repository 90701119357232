import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { syncOnLogin } from '~/reducers/common-actions';
import type { RootState } from '~/store';

/**
 * The exported route data
 *
 * The `string` value is expected to be an ISO Date-Time string
 */
type ExportedRouteData = string | undefined;

/**
 * The exported route state
 */
type ExportedRoutesState = Record<string, ExportedRouteData>;

interface ExportedRoutesUpdatePayload {
    /**
     * The client-route IDs to update the exported route data
     */
    clientRouteIds: string[];

    /**
     * The ISO Date-Time string when the route was exported
     */
    exportedAt: string;
}

/**
 * The initial exported route state
 */
export const initialState: ExportedRoutesState = {};

/**
 * Exported routes reducer actions
 */
export const exportedRoutesSlice = createSlice({
    name: 'exportedRoutes',
    initialState,
    reducers: {
        /**
         * Set exported routes data into state
         */
        setExportedRoutes: (
            state: ExportedRoutesState,
            action: PayloadAction<ExportedRoutesState>
        ): ExportedRoutesState => {
            const exportedRoutesPayload = action.payload;

            return {
                ...exportedRoutesPayload
            };
        },

        /**
         * Updates multiple exported routes data by ID into state
         */
        updateExportedRoutesById: (
            state: ExportedRoutesState,
            action: PayloadAction<ExportedRoutesUpdatePayload>
        ): ExportedRoutesState => {
            const { clientRouteIds, exportedAt } = action.payload;

            const updated = clientRouteIds.reduce<Record<string, string>>(
                (compiled, clientRouteId) => {
                    compiled[clientRouteId] = exportedAt;
                    return compiled;
                },
                {}
            );

            return {
                ...state,
                ...updated
            };
        },

        /**
         * Resets to a blank exported routes state
         */
        resetExportedRoutes: (): ExportedRoutesState => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(syncOnLogin, (state) => {
            // merge to initial state to ensure new keys for this slice
            // are synced with the current state
            return {
                ...initialState,
                ...state
            };
        });
    }
});

export const {
    setExportedRoutes,
    updateExportedRoutesById,
    resetExportedRoutes
} = exportedRoutesSlice.actions;

/**
 * Selects the current equipment state
 */
export const selectExportedRoutes = (state: RootState): ExportedRoutesState =>
    state.exportedRoutes;

/**
 * Selects a single exported route data from a given client route ID
 */
export const selectExportedRouteById =
    (clientRouteId: string) => (state: RootState) => {
        const exportedRouteData = state.exportedRoutes[clientRouteId];
        return exportedRouteData;
    };

/**
 * Selects specific exported routes from the current exported routes state
 */
export const selectExportedRoutesById =
    (clientRouteIds: string[]) => (state: RootState) => {
        const exportedRouteData = clientRouteIds.reduce<Record<string, string>>(
            (compiled, clientRouteId) => {
                const selectedExportedRoute =
                    state.exportedRoutes[clientRouteId];

                if (selectedExportedRoute) {
                    compiled[clientRouteId] = selectedExportedRoute;
                }

                return compiled;
            },
            {}
        );

        return exportedRouteData;
    };

export default exportedRoutesSlice.reducer;
