import LiveDriver from '../../data-classes/dispatched/LiveDriver';
import { sortBy, uniqWith } from 'lodash';
import { markerUtils } from '~/utils/map';
import { ApiLiveDriver } from '~/api/types';
import { LiveStop } from '~/data-classes';

export const getUniqueDepots = (
    selectedDrivers: ApiLiveDriver[] | LiveDriver[]
) => {
    const depots: LiveStop[] = [];
    selectedDrivers.forEach((driver: ApiLiveDriver | LiveDriver) => {
        const currentDriver =
            driver instanceof LiveDriver ? driver : new LiveDriver(driver);
        const { schedule } = currentDriver;
        const routeStops = sortBy(schedule, 'driverStopNumber');
        routeStops.forEach((stop) => {
            if (stop.isDepot) {
                depots.push(stop);
            }
        });
    });
    return uniqWith(depots, markerUtils.isDepotsWithTheSameCoordinates);
};
