import { useMemo } from 'react';
import root from 'window-or-global';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectMainClient } from '~/reducers/mainClientSlice';

import { useIsEmbeddedInIframe } from '~/hooks';
import { whiteLabeledDomains } from './whiteLabeledDomains';
import { partnerLogoQuery, wiseLogos, customLogos } from './customLogos';
import { UseAppLogoReturn } from './types';

export const useAppLogos = (): UseAppLogoReturn => {
    const mainClient = useSelector(selectMainClient);
    const isEmbeddedInIframe = useIsEmbeddedInIframe();

    const referrer = root.document?.referrer ?? '';
    const { customLogo } = mainClient?.preferences ?? {};

    const { search } = useLocation();

    const partnerLogo = useMemo(() => {
        const query = new URLSearchParams(search);
        return query.get(partnerLogoQuery);
    }, [search]);

    const isWhiteLabeled = useMemo(() => {
        const allowedWhiteLabeledDomains = Object.values(whiteLabeledDomains);
        return (
            isEmbeddedInIframe &&
            !allowedWhiteLabeledDomains.some(
                (domain) => referrer.indexOf(domain) === 0
            )
        );
    }, [isEmbeddedInIframe, referrer]);

    const appLogoData = useMemo(() => {
        const customLogoId = partnerLogo || customLogo;
        const hideLogos = isWhiteLabeled && !customLogoId;
        if (hideLogos) return;

        const customAppLogos = customLogoId && customLogos?.[customLogoId];
        const appLogoId = customAppLogos ? customLogoId : undefined;
        const appLogos = customAppLogos || wiseLogos;

        return { ...appLogos, partnerId: appLogoId };
    }, [isWhiteLabeled, partnerLogo, customLogo]);

    const { partnerId, login, navbar } = appLogoData || {};

    return {
        isWhiteLabeled,
        partnerId,
        login,
        navbar
    };
};
