/* eslint-disable camelcase */ // for API payloads using snake_case
import { isEqual } from 'lodash';
import {
    ApiClient,
    ApiUser,
    ApiUserGroup,
    UserRoles,
    UserPermissionFeatures,
    UserPermissionRoles
} from '~/api/types';
import userGroupsAPI from '~/api/user-groups';
import { UsersApi } from '~/api/UsersApi';
import { ADMIN_USER_PERMISSION_FEATURES } from '~/components/UsersAndPermissionsPage/types';

// @todo MCW-996 https://wisesys.atlassian.net/browse/MCW-996
// formalize user groups and permission types. These types currently contain
// the strict minimum definitions to make the type checks pass. More properties
// may exist.
type UserPermission = {
    client_id: string;
    permission: { role: string; feature: string }[];
};

type UserGroup = {
    name: string;
    client_id: string;
    client_access: Array<UserPermission>;
};

const getAdminPermissions = ({
    enableDriverBooking
}: {
    enableDriverBooking: boolean;
}) => {
    const permissions = ADMIN_USER_PERMISSION_FEATURES.map((feature) => {
        return {
            role: UserPermissionRoles.EDITOR,
            feature
        };
    });

    if (enableDriverBooking) {
        permissions.push({
            role: UserPermissionRoles.EDITOR,
            feature: UserPermissionFeatures.DRIVER_BOOKING
        });
    }

    return permissions;
};

/**
 * Generates one admin group for each client the user has access to with administrator role,
 * with all editor permissions on all core features
 */
const generateAdminGroups = (
    name: string,
    user: ApiUser,
    clients: ApiClient[]
): UserGroup[] => {
    const clientsById = clients.reduce<Record<string, ApiClient>>((res, c) => {
        res[c.id] = c;
        return res;
    }, {});

    return user.access
        .map((apiAccess) => {
            const { roles, client: clientId } = apiAccess;
            const client = clientsById[clientId];

            if (!client || !roles.includes(UserRoles.ADMINISTRATOR)) {
                return null;
            }

            const { enableDriverBooking } = client.preferences;
            const clientAccess: UserPermission = {
                client_id: clientId,
                permission: getAdminPermissions({ enableDriverBooking })
            };

            return {
                name,
                client_id: clientId,
                client_access: [clientAccess]
            };
        })
        .filter(Boolean) as unknown as UserGroup[];
};

/**
 * Compare the existing admin group with the newly created admin group,
 * and check if the user already has sufficient admin permissions
 */
const shouldUpdateExistingAdminUserGroup = (
    existingAdminGroup: ApiUserGroup,
    newAdminGroup: UserGroup
): boolean => {
    const featuresInExistingAdminGroup = existingAdminGroup.client_access
        .flatMap((client) =>
            client.permission.map((permission) => permission.feature)
        )
        .sort();
    const featuresInNewAdminGroup = newAdminGroup.client_access
        .flatMap((client) =>
            client.permission.map((permission) => permission.feature)
        )
        .sort();

    // consider the arrays are `equal` as long as both have the same items,
    // which could be in a different order
    const hasSameFeatures = isEqual(
        featuresInExistingAdminGroup,
        featuresInNewAdminGroup
    );

    return !hasSameFeatures;
};

/**
 * Get updated admin user group or use existing admin user group
 */
const getUpdatedAdminUserGroup = async ({
    existingAdminGroup,
    newAdminGroup
}: {
    existingAdminGroup: ApiUserGroup;
    newAdminGroup: UserGroup;
}): Promise<ApiUserGroup | undefined> => {
    const shouldUpdateExisting = shouldUpdateExistingAdminUserGroup(
        existingAdminGroup,
        newAdminGroup
    );

    const { id } = existingAdminGroup;
    const { client_access: clientAccess } = newAdminGroup;

    if (!shouldUpdateExisting) {
        return existingAdminGroup;
    }

    const payload = {
        id,
        client_access: clientAccess
    };
    const response = await userGroupsAPI.patch([payload]);

    return response?.data.data.successes[0];
};

/**
 * Set new admin user groups
 */
const setNewAdminUserGroups = async (
    newAdminGroup: UserGroup
): Promise<ApiUserGroup | undefined> => {
    const response = await userGroupsAPI.post([newAdminGroup]);
    return response?.data.data.successes[0];
};

/**
 * Adds the user to the admin group or creates an admin group for that user if no admin group exists.
 * This is a no-op if the user is already part of a group with sufficient admin permissions.
 * This is a no-op if the user does not have the administrator role in the RBAC system.
 */
export const migrateAdminUserRoleToPermissionGroup = async (
    userDetails: ApiUser,
    clients: ApiClient[]
): Promise<void[]> => {
    const adminGroupName = 'admin';
    const clientIds = userDetails.access
        .filter(({ roles }) => roles.includes(UserRoles.ADMINISTRATOR))
        .map(({ client }) => client);
    const userGroupResponse = clientIds.length
        ? await userGroupsAPI.get({
              client_id: clientIds,
              name: [adminGroupName],
              limit: clientIds.length
          })
        : null;
    const newAdminUserGroups = generateAdminGroups(
        adminGroupName,
        userDetails,
        clients
    );

    const queries = clientIds.map(async (clientId) => {
        const existingAdminGroup: ApiUserGroup | undefined =
            userGroupResponse?.data.data.successes.find(
                (userGroup: ApiUserGroup) => userGroup.client_id === clientId
            );
        const newAdminGroup = newAdminUserGroups.find(
            (userGroup: UserGroup) => userGroup.client_id === clientId
        );
        if (!newAdminGroup) {
            return;
        }

        const adminUserGroup = existingAdminGroup
            ? await getUpdatedAdminUserGroup({
                  existingAdminGroup,
                  newAdminGroup
              })
            : await setNewAdminUserGroups(newAdminGroup);

        if (!adminUserGroup) {
            return;
        }

        const associationResponse =
            await UsersApi.getUserToUserGroupAssociations({
                user_id: [userDetails.id],
                user_group_id: [adminUserGroup.id]
            });
        if (associationResponse?.data?.data?.successes?.length) {
            return;
        }
        await userGroupsAPI.addUserToUserGroup(
            userDetails.id,
            adminUserGroup.id
        );
    });
    return Promise.all(queries);
};
