export enum ActionTypes {
    MOVE_INVOICE_OR_INVENTORY = 'moveInvoiceOrInventory',
    MOVE_INVOICE = 'moveInvoice',
    MOVE_INVENTORY = 'moveInventory',
    SET_VOLUME_AND_WEIGHT = 'setVolumeAndWeight'
}

export enum AssignmentTypes {
    CREATE_TASK = 'createTask',
    ASSIGN_TASK_TO_DRIVER = 'assignTaskToDriver'
}

export enum ItemTypes {
    INVOICE = 'invoice',
    INVENTORY = 'inventory'
}

export interface DuplicateTaskModalFormProps {
    actionType: ActionTypes;
    assignmentType: AssignmentTypes;
    itemType: ItemTypes;
    invoicesIds: string[];
}
