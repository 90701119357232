import React from 'react';
import { ChangeTaskStatusModalAlertProps } from './types';
import { Alert } from '~/ui';
import { IconSizes } from '~/ui/components/Icon/IconSizes';
import './ChangeTaskStatusModalAlert.scss';

export const ChangeTaskStatusModalAlert = ({
    text,
    children,
    className,
    'data-testid': dataTestId
}: ChangeTaskStatusModalAlertProps) => {
    const ROOT_CLASS_NAME = 'change-task-status-modal-alert';
    const elementClassName = className || ROOT_CLASS_NAME;
    const elementDataTestId = dataTestId || ROOT_CLASS_NAME;

    return (
        <Alert
            icon="error"
            iconSize={IconSizes.L}
            iconColor="saturn-50"
            variant={Alert.variants.INFO}
            className={elementClassName}
            data-testid={elementDataTestId}
        >
            {text || children}
        </Alert>
    );
};
