import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useMapUtils, useOnDemandDispatchMarkerEventHandler } from '~/hooks';
import { selectDateOnly } from '~/reducers/selectedDateSlice';
import { selectCompletedDrivers } from '~/reducers/liveDriversSlice';
import { selectSelectedDrawerCardData } from '~/reducers/selectedDrawerCardDataSlice';
import { ApiLiveStop, Coordinates } from '~/api/types';
import { usePlanMapEventsContext } from '~/components/MapPage/PlanMap/PlanMapEventsContext';
import { useLiveStopsSuperClusters } from '~/components/MapPage/PlanMap/useLiveStopsSuperClusters';
import { getUniqueDepots } from '~/utils/map-modes/depotUtils';
import {
    useMakeLiveRouteLineAndDriverMarker,
    useMakeDepotMarkers,
    useMakeLiveStopMarkers
} from '~/hooks/useMapMarkers';

const useCompletedRouteStopMarkersAndLines = () => {
    const { isCompletedRouteMode } = useMapUtils();

    const selectedDate = useSelector(selectDateOnly);
    const completedDrivers = useSelector(selectCompletedDrivers);
    const { data: selectedDrawerCard } = (useSelector(
        selectSelectedDrawerCardData
    ) ?? {}) as {
        data?: {
            id: string;
            schedule?: ApiLiveStop[];
        };
    };

    const [routeStopMarkers, setRouteStopMarkers] = useState<JSX.Element[]>([]);
    const [depotStopMarkers, setDepotStopMarkers] = useState<JSX.Element[]>([]);
    const [routeLines, setSelectedRouteLines] = useState<JSX.Element[]>([]);
    const [routeStopCoordinates, setRouteStopCoordinates] = useState<
        Coordinates[]
    >([]);
    const { emittedEventHandler } = usePlanMapEventsContext();
    const onDemandDispatchMarkerEventHandler =
        useOnDemandDispatchMarkerEventHandler();
    const { superClusters } = useLiveStopsSuperClusters();

    const { makeLiveRoutesStopComponents } =
        useMakeLiveRouteLineAndDriverMarker();
    const { makeDepotMarkers } = useMakeDepotMarkers();
    const { makeLiveStopMarkers } = useMakeLiveStopMarkers();

    useEffect(() => {
        if (!selectedDate) {
            return;
        }
        setRouteStopMarkers([]);
        setSelectedRouteLines([]);
        setRouteStopCoordinates([]);
        setDepotStopMarkers([]);
    }, [selectedDate]);

    const { id: selectedDrawerCardId } = selectedDrawerCard ?? {};
    const selectedDriver = useMemo(() => {
        if (!selectedDrawerCardId) return;

        return completedDrivers.find(({ id }) => id === selectedDrawerCardId);
    }, [completedDrivers, selectedDrawerCardId]);

    useEffect(() => {
        if (
            !isCompletedRouteMode ||
            !selectedDriver ||
            !selectedDrawerCard?.schedule
        ) {
            return;
        }

        const { routeLines: selectedRouteLines } = makeLiveRoutesStopComponents(
            {
                selectedDriver
            }
        );
        const stopMarkers = makeLiveStopMarkers({
            onDemandDispatchMarkerEventHandler,
            emittedEventHandler,
            liveStopsSuperClusters: superClusters
        });

        const uniqueDepots = getUniqueDepots([selectedDriver]);
        const depotMarkers = makeDepotMarkers(uniqueDepots);

        setRouteStopMarkers(stopMarkers);
        setDepotStopMarkers(depotMarkers);
        setSelectedRouteLines(selectedRouteLines);

        const routeStopCoords = selectedDrawerCard.schedule.map(
            (item: ApiLiveStop) => {
                return item.location.location;
            }
        );
        setRouteStopCoordinates(routeStopCoords);
    }, [
        makeLiveRoutesStopComponents,
        makeDepotMarkers,
        makeLiveStopMarkers,
        emittedEventHandler,
        onDemandDispatchMarkerEventHandler,
        superClusters,
        isCompletedRouteMode,
        selectedDrawerCard,
        selectedDriver
    ]);

    return {
        depotStopMarkers,
        routeStopMarkers,
        routeStopCoordinates,
        routeLines
    };
};

export default useCompletedRouteStopMarkersAndLines;
