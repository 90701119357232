import React, { useMemo } from 'react';
import { uniq } from 'lodash';
import { useStopMarkerLabelsTooltip } from './useStopMarkerLabelsTooltip';
import { getFormattedLabels } from './utils';
import './StopMarkerNameAndLabels.scss';

interface StopMarkerNameAndLabelsProps {
    name?: string;
    labels?: string[] | null;
}

export const StopMarkerNameAndLabels = ({
    name,
    labels
}: StopMarkerNameAndLabelsProps) => {
    const rootClassName = 'stop-marker-name-and-labels';
    const uniqueLabels = useMemo(() => uniq(labels), [labels]);
    const formattedLabels = useMemo(
        () => getFormattedLabels(uniqueLabels),
        [uniqueLabels]
    );
    const { labelsTooltip, setTriggerRef } = useStopMarkerLabelsTooltip({
        labels: uniqueLabels
    });

    const isShowLabels = formattedLabels.length > 0;
    if (!name && !isShowLabels) return null;

    const renderFormattedLabels = () => {
        return formattedLabels.join(', ');
    };

    return (
        <div
            className={`${rootClassName} _d-flex _ai-center _jc-center`}
            data-testid={rootClassName}
        >
            {labelsTooltip}
            <span className={`${rootClassName}__name`} title={name}>
                {name}
            </span>
            {isShowLabels && (
                <div ref={setTriggerRef} className={`${rootClassName}__labels`}>
                    {renderFormattedLabels()}
                </div>
            )}
        </div>
    );
};
