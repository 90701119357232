const palette = {
    black: '#000000',
    white: '#FFFFFF',
    transparent: 'transparent',

    // primary colors
    milkyway: '#1F2923',
    ocean: '#5EA2FF',
    comet: '#FFFFFF',
    sky: '#82B5FF',
    mars: '#FF5670',
    earth: '#41BFAC',
    venus: '#FF824A',
    saturn: '#FFC775',
    neptune: '#316DBE',
    aurora: '#3FA1B8',
    meteor: '#3282FF',

    // neutral colors
    'galaxy-30': '#354B61',
    'galaxy-50': '#F6F8FB',
    'galaxy-60': '#7E95AB',
    'galaxy-80': '#C0C0C0',
    'galaxy-90': '#D9E3EC',
    'galaxy-99': '#F7FAFC',
    'galaxy-100': '#F0F4F8',
    'galaxy-200': '#D9E2EC',
    'galaxy-300': '#BCCCDC',
    'galaxy-300-alt': '#BDCBD9',
    'galaxy-400': '#A6B6C5',
    'galaxy-500': '#6E7882',
    'galaxy-500-alt': '#727A82',
    'galaxy-600': '#486581',
    'galaxy-600-alt': '#465E75',
    'galaxy-700': '#334E68',
    'galaxy-800': '#09101A',
    'galaxy-800-alt': '#101214',

    // supporting colors
    'mars-20': '#680012',
    'mars-40': '#BA162E',
    'mars-50': '#FFEDEB',
    'mars-50-alt': '#DE2C3C',
    'mars-60': '#FF525C',
    'mars-90': '#FFDAD8',
    'mars-95': '#FFEDEB',
    'mars-100': '#FFE5E9',
    'mars-200': '#FFBDBD',
    'mars-300': '#FF9B9B',
    'mars-400': '#FF5670',
    'mars-500': '#E12D39',
    'mars-600': '#CF1124',
    'mars-700': '#AB091E',
    'mars-800': '#610316',

    'earth-30': '#00531D',
    'earth-60': '#00A742',
    'earth-99': '#F6FFF1',
    'earth-100': '#EFFCF6',
    'earth-200': '#C6F7E2',
    'earth-250': '#C8E8EF',
    'earth-300': '#8EEDC7',
    'earth-400': '#41BFAC',
    'earth-500': '#27AB83',
    'earth-600': '#317D7C',
    'earth-700': '#0D645F',
    'earth-800': '#014D40',
    'earth-900': '#008A35',

    'venus-100': '#FFF4EF',
    'venus-200': '#FFDCCD',
    'venus-300': '#FFB290',
    'venus-400': '#FF824A',
    'venus-500': '#F35627',
    'venus-600': '#DE3A11',
    'venus-700': '#AD1D07',
    'venus-800': '#841003',

    'saturn-40': '#845400',
    'saturn-50': '#A56A00',
    'saturn-60': '#D98E0B',
    'saturn-95': '#FFEEDD',
    'saturn-99': '#FFFBFF',
    'saturn-100': '#FFFBF5',
    'saturn-200': '#FFECCF',
    'saturn-300': '#FDD8A3',
    'saturn-400': '#FFC775',
    'saturn-500': '#FFB140',
    'saturn-600': '#FFAE4F',
    'saturn-700': '#FFDA00',
    'saturn-800': '#8C5E1E',

    'neptune-100': '#F3F8FF',
    'neptune-200': '#DFECFF',
    'neptune-300': '#4191FF',
    'neptune-400': '#316DBE',
    'neptune-500': '#295FA7',
    'neptune-700': '#182941',
    'neptune-800': '#192949',
    'neptune-900': '#D4BDFC',
    'neptune-950': '#9747FF',

    'aurora-100': '#F5FDFF',
    'aurora-200': '#BFE9F3',
    'aurora-300': '#7CBFCF',
    'aurora-400': '#3FA1B8',
    'aurora-500': '#198BA6',

    'ocean-500': '#478AE5',

    'meteor-20': '#0D356A',
    'meteor-30': '#044595',
    'meteor-40': '#0A5AC3',
    'meteor-50': '#3282FF',
    'meteor-60': '#A4CAFF',
    'meteor-70': '#82B5FF',
    'meteor-70-alt': '#A7AFB8',
    'meteor-90': '#D4E5FF',
    'meteor-95': '#EDF3FF',
    'meteor-95-alt': '#EFF5FF',

    overlay: 'rgba(9, 16, 26, 0.2)' // galaxy-800, 20%
};

export default {
    palette
};
