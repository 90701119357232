import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectHasIsolatedRoutes,
    selectIsMultipleCardSelectEnabled,
    selectViewCardDetails,
    setHasIsolatedRoutes as updateHasIsolatedRoutes,
    setIsMultipleCardSelectEnabled as updateIsMultipleCardSelectEnabled,
    setViewCardDetails as updateViewCardDetails
} from '~/reducers/mapSettingsSlice';

export const useIsolatedRoutes = () => {
    const dispatch = useDispatch();

    const hasIsolatedRoutes = useSelector(selectHasIsolatedRoutes);

    const isMultipleCardSelectEnabled = useSelector(
        selectIsMultipleCardSelectEnabled
    );

    const viewCardDetails = useSelector(selectViewCardDetails);

    const setHasIsolatedRoutes = useCallback(
        (value: boolean) => {
            return dispatch(updateHasIsolatedRoutes(value));
        },
        [dispatch]
    );

    const setIsMultipleCardSelectEnabled = useCallback(
        (value: boolean) => {
            return dispatch(updateIsMultipleCardSelectEnabled(value));
        },
        [dispatch]
    );

    const setViewCardDetails = useCallback(
        (value: boolean) => {
            return dispatch(updateViewCardDetails(value));
        },
        [dispatch]
    );

    const isolateSingleRoute = useCallback(
        (isolate = true) => {
            setHasIsolatedRoutes(isolate);
            setIsMultipleCardSelectEnabled(false);
            setViewCardDetails(false);
        },
        [
            setIsMultipleCardSelectEnabled,
            setViewCardDetails,
            setHasIsolatedRoutes
        ]
    );

    const isolateMultipleRoutes = useCallback(
        (isolate = true) => {
            setHasIsolatedRoutes(isolate);
            setIsMultipleCardSelectEnabled(isolate);
            setViewCardDetails(isolate);
        },
        [
            setHasIsolatedRoutes,
            setViewCardDetails,
            setIsMultipleCardSelectEnabled
        ]
    );

    const isRouteMultiSelectInProgress =
        isMultipleCardSelectEnabled && !viewCardDetails;

    const isRouteMultiSelectInView =
        isMultipleCardSelectEnabled && viewCardDetails;

    return {
        hasIsolatedRoutes,
        isMultipleCardSelectEnabled,
        viewCardDetails,
        setHasIsolatedRoutes,
        setIsMultipleCardSelectEnabled,
        setViewCardDetails,
        isolateSingleRoute,
        isolateMultipleRoutes,
        isRouteMultiSelectInView,
        isRouteMultiSelectInProgress
    };
};
