import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useModal, DropDownMenu } from '~/ui';
import { DateTimePicker } from '~/ui/components/DateTimePicker';
import { DEFAULT_SELECTED_TIME, getDateTimeValues } from './utils';
import { ChangeTaskStatusModalReviseTimeFieldProps } from './types';
import './ChangeTaskStatusModalReviseTimeField.scss';
import { convertDateTimeToUTCISOString } from '~/utils/date-utils';

export const ChangeTaskStatusModalReviseTimeField = ({
    label,
    initialTime,
    revisedTime,
    setRevisedTime,
    'data-testid': dataTestId
}: ChangeTaskStatusModalReviseTimeFieldProps) => {
    const ROOT_CLASS_NAME = 'change-task-status-modal-revise-time-field';
    const elementDataTestId = dataTestId || ROOT_CLASS_NAME;

    const { t } = useTranslation('changeTaskStatus');

    const { showModal, hideModal, modal: Modal } = useModal();

    const [selectedTime, setSelectedTime] = useState(DEFAULT_SELECTED_TIME);
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

    const {
        initialSelectedDate,
        initialSelectedTime,
        revisedSelectedDate,
        revisedSelectedTime,
        displayDateTimeValue
    } = useMemo(() => {
        const resolvedDateTime = getDateTimeValues({
            initialTime,
            revisedTime
        });
        return resolvedDateTime;
    }, [initialTime, revisedTime]);

    useEffect(() => {
        setSelectedDate(revisedSelectedDate);
        setSelectedTime(revisedSelectedTime);
    }, [revisedSelectedDate, revisedSelectedTime]);

    const onChangeDate = useCallback(
        (value: Date | null) => {
            // prevent `null` values to be set
            // + a `null` value will be set when the currently selected date is deselected in `DateTimePicker`
            // + this ensuresthat `reviseTime` will always be set with a proper ISO string value
            if (value) setSelectedDate(value);
        },
        [setSelectedDate]
    );

    const onClose = useCallback(() => {
        setRevisedTime('');
        hideModal();
    }, [setRevisedTime, hideModal]);

    const onClear = useCallback(() => {
        setSelectedDate(initialSelectedDate);
        setSelectedTime(initialSelectedTime);
        onClose();
    }, [
        initialSelectedDate,
        initialSelectedTime,
        setSelectedTime,
        setSelectedDate,
        onClose
    ]);

    const onApply = useCallback(() => {
        const [hour, minute] = selectedTime.split(':');
        const newDateTime = DateTime.fromJSDate(selectedDate as Date).set({
            hour: Number(hour),
            minute: Number(minute),
            second: 0,
            millisecond: 0
        });
        const isoDateTime = convertDateTimeToUTCISOString(newDateTime);
        setRevisedTime(isoDateTime);
        hideModal();
    }, [selectedTime, selectedDate, setRevisedTime, hideModal]);

    return (
        <div className={ROOT_CLASS_NAME} data-testid={elementDataTestId}>
            <div
                className={`${ROOT_CLASS_NAME}__label`}
                data-testid={`${elementDataTestId}__label`}
            >
                {label}
            </div>
            <DropDownMenu
                title={{
                    name: displayDateTimeValue
                }}
                styles={{
                    innerContainer:
                        'dropdown-flex-space-between dropdown_outlined',
                    menuTitle: 'dropdown-selection',
                    menuTitleText: 'dropdown-title-text',
                    dropdownToggleColor: 'var(--color-galaxy-800)'
                }}
                toggle={showModal}
                icon="calendarLine"
                className={`${ROOT_CLASS_NAME}__dropdown`}
                data-testid={`${elementDataTestId}__dropdown`}
            >
                <Modal
                    title="filter"
                    variant="auto"
                    hasCloseButton
                    hasCustomHeader
                >
                    <DateTimePicker
                        title={label}
                        startLabel={t('reviseTime.time')}
                        startTime={selectedTime}
                        onChangeStartTime={setSelectedTime}
                        selectedDate={selectedDate}
                        onChangeDate={onChangeDate}
                        onClose={onClose}
                        onClear={onClear}
                        onApply={onApply}
                    />
                </Modal>
            </DropDownMenu>
        </div>
    );
};
