import { idUtils } from '~/utils/id-utils';
import { PlanClientRoute } from '~/api/types';
import { ReportFileActionTypes } from '~/data-classes/report/types/ReportFileActionTypes';

export const isExportAction = (type: ReportFileActionTypes | null) => {
    return type === ReportFileActionTypes.EXPORT;
};

export const isDownloadAction = (type: ReportFileActionTypes | null) => {
    return type === ReportFileActionTypes.DOWNLOAD;
};

export const getSelectedRoutesIds = (selectedMapRoutes: string[]) => {
    return selectedMapRoutes.map(
        (route: string) => idUtils.splitCombinedId(route)[1]
    );
};

export const getSelectedRoutesData = ({
    planRoutesLevelData,
    selectedMapRoutes
}: {
    planRoutesLevelData: PlanClientRoute[];
    selectedMapRoutes: string[];
}) => {
    const selectedRoutesIds = getSelectedRoutesIds(selectedMapRoutes);
    return planRoutesLevelData.filter(({ route }) =>
        selectedRoutesIds.includes(route?.routeId as string)
    );
};

interface IsReportVisibleParams {
    isCoreReport: boolean;
    isCustomReport: boolean;
    isUnknownCustomReport: boolean;
    showCoreReports: boolean;
    showCustomReports: boolean;
}

export const isReportVisible = ({
    isCoreReport,
    isCustomReport,
    isUnknownCustomReport,
    showCoreReports,
    showCustomReports
}: IsReportVisibleParams) => {
    const hasCustomReport = isCustomReport || isUnknownCustomReport;
    const shouldShowCoreReports = isCoreReport && showCoreReports;
    const shouldShowCustomReports = hasCustomReport && showCustomReports;

    return shouldShowCoreReports || shouldShowCustomReports;
};
