import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useDropDownMenuHandler } from '~/hooks';
import { DropDownMenu, DropDownMenuItem, Icon } from '~/ui';
import { IconSizes } from '~/ui/components/Icon/IconSizes';
import { ChangeTaskStatusModalAlert } from '../ChangeTaskStatusModalAlert';

import {
    ChangeTaskStatusModalReviseStatusProps,
    AssignmentStatusDropdownItem
} from './types';
import { AssignmentStatus } from '~/api/types';

import './ChangeTaskStatusModalReviseStatus.scss';

export const ChangeTaskStatusModalReviseStatus = ({
    currentStatus,
    revisedStatus,
    setRevisedStatus
}: ChangeTaskStatusModalReviseStatusProps) => {
    const ROOT_CLASS_NAME = 'change-task-status-modal-revise-status';

    const { t } = useTranslation('changeTaskStatus');

    const {
        ref: dropdownRef,
        isOpen,
        onToggle,
        onClose
    } = useDropDownMenuHandler();

    const dropdownMenuItemData = useMemo<
        Record<AssignmentStatus, AssignmentStatusDropdownItem>
    >(() => {
        const assignmentStatus = [
            {
                key: 'assigned',
                value: AssignmentStatus.ASSIGNED,
                label: t('reviseStatus.assigned')
            },
            {
                key: 'inProgress',
                value: AssignmentStatus.IN_PROGRESS,
                label: t('reviseStatus.inProgress')
            },
            {
                key: 'completed',
                value: AssignmentStatus.COMPLETED,
                label: t('reviseStatus.completed')
            },
            {
                key: 'canceled',
                value: AssignmentStatus.CANCELED,
                label: t('reviseStatus.canceled')
            }
        ];

        const assignmentStatusEntries = assignmentStatus.map(
            ({ key, value, label }) => {
                const entryValue: AssignmentStatusDropdownItem = {
                    key,
                    value,
                    label,
                    isSelected: value === revisedStatus,
                    isCurrent: value === currentStatus
                };
                return [value, entryValue];
            }
        );

        const assignmentStatusMap = Object.fromEntries(assignmentStatusEntries);

        return assignmentStatusMap;
    }, [t, currentStatus, revisedStatus]);

    const onChangeRevisedStatus = (value: AssignmentStatus) => {
        setRevisedStatus(value);
        onClose();
    };

    const showNoticeStatus = [
        AssignmentStatus.COMPLETED,
        AssignmentStatus.CANCELED
    ];

    const selectedStatus = Object.values(dropdownMenuItemData).find(
        (item) => item.isSelected
    );

    const showNotice =
        selectedStatus && showNoticeStatus.includes(selectedStatus?.value);

    const displayStatusValue = selectedStatus?.label;

    return (
        <div
            className={`${ROOT_CLASS_NAME} _fd-column`}
            data-testid={ROOT_CLASS_NAME}
        >
            <div className={`${ROOT_CLASS_NAME}__field`}>
                <div
                    className={`${ROOT_CLASS_NAME}__label`}
                    data-testid={`${ROOT_CLASS_NAME}__label`}
                >
                    {t('reviseStatus.title')}
                </div>
                <DropDownMenu
                    title={{
                        name: displayStatusValue
                    }}
                    placeholder={t('reviseStatus.placeholder')}
                    styles={{
                        innerContainer:
                            'dropdown-flex-space-between dropdown_outlined',
                        menuTitle: 'dropdown-selection',
                        menuTitleText: 'dropdown-title-text',
                        dropdownToggleColor: 'var(--color-galaxy-800)',
                        menuItems: `${ROOT_CLASS_NAME}__dropdown-items`
                    }}
                    ref={dropdownRef}
                    isOpen={isOpen}
                    toggle={onToggle}
                    close={onClose}
                    icon="chevronDown"
                    position="down-right"
                    className={`${ROOT_CLASS_NAME}__dropdown`}
                    data-testid={`${ROOT_CLASS_NAME}__dropdown`}
                >
                    {Object.values(dropdownMenuItemData).map(
                        ({ key, value, label, isSelected, isCurrent }) => {
                            const dropdownItemClassName = classNames(
                                `${ROOT_CLASS_NAME}__dropdown-item`,
                                {
                                    [`${ROOT_CLASS_NAME}__dropdown-item--selected`]:
                                        isSelected
                                }
                            );
                            const dropdownItemDataTestId = `${ROOT_CLASS_NAME}__dropdown-item--${key}`;
                            const showStatusState = isCurrent || isSelected;
                            return (
                                <DropDownMenuItem
                                    key={key}
                                    menuItem={{
                                        onClick: () =>
                                            onChangeRevisedStatus(value)
                                    }}
                                    className={dropdownItemClassName}
                                    data-testid={dropdownItemDataTestId}
                                >
                                    <div
                                        className={`${ROOT_CLASS_NAME}__status-label`}
                                        data-testid={`${ROOT_CLASS_NAME}__status-label`}
                                    >
                                        {label}
                                    </div>
                                    {showStatusState && (
                                        <div
                                            className={`${ROOT_CLASS_NAME}__status-state _d-flex _jc-space-between`}
                                        >
                                            {isCurrent && (
                                                <div
                                                    className={`${ROOT_CLASS_NAME}__current-status`}
                                                    data-testid={`${ROOT_CLASS_NAME}__current-status`}
                                                >
                                                    {t('reviseStatus.current')}
                                                </div>
                                            )}
                                            {isSelected && (
                                                <Icon
                                                    className="dropdown-icon"
                                                    icon="checkmark"
                                                    color="galaxy-800"
                                                    size={IconSizes.S}
                                                    data-testid={`${ROOT_CLASS_NAME}__selected-status`}
                                                />
                                            )}
                                        </div>
                                    )}
                                </DropDownMenuItem>
                            );
                        }
                    )}
                </DropDownMenu>
            </div>
            {showNotice && (
                <ChangeTaskStatusModalAlert>
                    {t('reviseStatus.alert', {
                        status: displayStatusValue
                    })}
                </ChangeTaskStatusModalAlert>
            )}
        </div>
    );
};
