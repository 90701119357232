import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { MapMarkerModes, MapRouteModes } from '~/hooks/useMapUtils';
import {
    setHasIsolatedRoutes,
    setMapMarkerMode,
    setMapRouteMode,
    setShouldFitPlanMapToBounds,
    setShowUnassignedTasks
} from '~/reducers/mapSettingsSlice';
import { resetRoutesDrawerSort } from '~/reducers/routesDrawerSortSlice';
import { resetRoutesDrawerFilter } from '~/reducers/routesDrawerFilterSlice';
import { resetMapDrawerSettings } from '~/reducers/mapDrawerSettingsSlice';
import { resetSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { resetSelectedTaskRowId } from '~/reducers/selectedTaskRowIdSlice';
import { resetSelectedMapRoutes } from '~/reducers/selectedMapRoutesSlice';
import { resetSelectedDrawerCardData } from '~/reducers/selectedDrawerCardDataSlice';
import { resetSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { resetTasksDrawerFilter } from '~/reducers/tasksDrawerFilterSlice';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';

export const useChangeMapRouteMode = () => {
    const dispatch = useDispatch();

    const changeMapRouteMode = useCallback(
        (mode: MapRouteModes) => {
            dispatch(setMapRouteMode(mode));
            dispatch(resetRoutesDrawerSort());
            dispatch(resetRoutesDrawerFilter());
            dispatch(resetMapDrawerSettings());
            dispatch(resetSelectedMapStops());
            dispatch(resetSelectedTaskRowId());
            dispatch(resetSelectedMapRoutes(null));
            dispatch(setShouldFitPlanMapToBounds(false));
            dispatch(resetSelectedDrawerCardData());
            dispatch(resetSelectedDrawerCardId());
            dispatch(setHasIsolatedRoutes(false));
            dispatch(
                setShowUnassignedTasks({
                    mode: mode as ConfigurableMapRouteMode,
                    value: false
                })
            );
            dispatch(setMapMarkerMode(MapMarkerModes.ROUTES));
            dispatch(resetTasksDrawerFilter());
        },
        [dispatch]
    );

    return { changeMapRouteMode };
};
