import { uniq } from 'lodash';
import { Task } from '~/data-classes';

const checkIfCoordinatesMatch = (a, b) => {
    return a.lat === b.lat && a.lng === b.lng;
};

const isDepotsWithTheSameCoordinates = (firstStop, secondStop) => {
    return checkIfCoordinatesMatch(
        firstStop.markerCoordinates || firstStop.location,
        secondStop.markerCoordinates || secondStop.location
    );
};

const checkIsDepotUnique = (depots, stop) => {
    return !depots.some((depot) => isDepotsWithTheSameCoordinates(depot, stop));
};

// https://tools.ietf.org/html/rfc7946#section-3.2
const getGeoJSONFeaturePoint = (lat, lng, properties = {}) => {
    return {
        type: 'Feature',
        properties,
        geometry: {
            type: 'Point',
            coordinates: [lng, lat]
        }
    };
};

/**
 * Groups Supercluster leaves by coordinates (lat, lng)
 * @param leaves array of supercluster leaves
 * @returns {*} {locationKey: [leaf.properties]}
 */
const groupLeavesByLocation = (leaves) => {
    return leaves.reduce((aggregator, leaf) => {
        const locationKey = leaf.geometry.coordinates.join('_');
        if (aggregator[locationKey]?.length) {
            aggregator[locationKey].push(leaf.properties);
        } else {
            aggregator[locationKey] = [leaf.properties];
        }
        return aggregator;
    }, {});
};

const groupUnassignedTasksByLocation = (unassignedTasks) => {
    return unassignedTasks.reduce((aggregator, taskData) => {
        const unassignedTask = new Task(taskData);
        const { isPickup, pickupLocationLatLng, deliveryLocationLatLng } =
            unassignedTask;
        const locationKey = isPickup
            ? `${pickupLocationLatLng.lng}_${pickupLocationLatLng.lat}`
            : `${deliveryLocationLatLng.lng}_${deliveryLocationLatLng.lat}`;
        if (!aggregator[locationKey]) {
            aggregator[locationKey] = [];
        }
        aggregator[locationKey].push(taskData);
        return aggregator;
    }, {});
};

const joinByLocation = (liveStopGroup, unassignedGroup) => {
    const locationKeys = uniq([
        ...Object.keys(liveStopGroup),
        ...Object.keys(unassignedGroup)
    ]);
    return locationKeys.reduce((aggregator, locationKey) => {
        const liveStops = liveStopGroup[locationKey];
        const unassignedTasks = unassignedGroup[locationKey];
        const values = {
            unassigned: [],
            live: []
        };
        if (liveStops?.length) {
            values.live = liveStops;
        }
        if (unassignedTasks?.length) {
            values.unassigned = unassignedTasks;
        }
        aggregator[locationKey] = values;
        return aggregator;
    }, {});
};

/**
 * Parses lat and lng from location key from leaves grouped by location coordinates
 * @param locationKey
 * @returns {{lng: number, lat: number}}
 */
const getLocationCoordinates = (locationKey) => {
    const [lng, lat] = locationKey.split('_').map((point) => Number(point));
    return { lng, lat };
};

const getTaskCoordinates = (taskData) => {
    const unassignedTask = new Task(taskData);
    const { isPickup, pickupLocationLatLng, deliveryLocationLatLng } =
        unassignedTask;
    if (isPickup) {
        return { lng: pickupLocationLatLng.lng, lat: pickupLocationLatLng.lat };
    }
    return {
        lng: deliveryLocationLatLng.lng,
        lat: deliveryLocationLatLng.lat
    };
};

/**
 *  Retuns task id for the purpose of map selection i.e. `selectedMapStopsSlice`
 * @param taskData ApiTask
 * @returns string
 */
const getTaskIdForSelection = (taskData) => {
    const task = new Task(taskData);
    const { isPickup, pickupStopData, deliveryStopData } = task;
    return isPickup
        ? pickupStopData.clientRouteTaskId
        : deliveryStopData.clientRouteTaskId;
};

export const markerUtils = {
    checkIfCoordinatesMatch,
    checkIsDepotUnique,
    getGeoJSONFeaturePoint,
    isDepotsWithTheSameCoordinates,
    groupLeavesByLocation,
    joinByLocation,
    groupUnassignedTasksByLocation,
    getLocationCoordinates,
    getTaskCoordinates,
    getTaskIdForSelection
};
