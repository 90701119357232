import React from 'react';
import { useTranslation } from 'react-i18next';
import { DuplicateTaskModalInventoryProps } from './types';
import { RouteCardMetric } from '~/ui';
import './DuplicateTaskModalInventory.scss';

export const DuplicateTaskModalInventory = ({
    inventory
}: DuplicateTaskModalInventoryProps) => {
    const rootClassName = 'duplicate-task-modal-inventory';
    const rootDataTestId = rootClassName;
    const {
        item_name: itemName,
        item_id: itemId,
        expected_quantity: expectedQuantity,
        unit_weight: unitWeight
    } = inventory;

    const { t } = useTranslation(['taskManagement', 'inventory']);

    return (
        <div
            className={`${rootClassName} _fd-column`}
            data-testid={rootDataTestId}
        >
            <div
                className={`${rootClassName}__item-name`}
                data-testid={`${rootClassName}__item-name`}
            >
                {itemName}
            </div>
            <div
                className={`${rootClassName}__details _d-flex _jc-space-between`}
            >
                <div
                    className={`${rootClassName}__item-id`}
                    data-testid={`${rootClassName}__item-id`}
                >
                    {t('inventory:ID')}: {itemId}
                </div>
                <div className={`${rootClassName}__stats _d-flex`}>
                    <RouteCardMetric
                        icon="iconBox2"
                        iconColor="galaxy-500"
                        value={expectedQuantity}
                        metric="planned-quantity"
                        tooltipText={t('inventory:plannedQuantity', {
                            count: expectedQuantity
                        })}
                        className={`${rootClassName}__item-planned-quantity`}
                        data-testid={`${rootClassName}__item-planned-quantity`}
                    />
                    <RouteCardMetric
                        icon="weightEmpty"
                        iconColor="galaxy-500"
                        value={unitWeight}
                        metric="weight"
                        tooltipText={t('inventory:weight', {
                            count: unitWeight
                        })}
                        className={`${rootClassName}__item-weight`}
                        data-testid={`${rootClassName}__item-weight`}
                    />
                </div>
            </div>
        </div>
    );
};
