import React, { useCallback } from 'react';
import { DepotMarker } from '~/ui';
import { LiveStop } from '~/data-classes';

export const useMakeDepotMarkers = () => {
    const makeDepotMarkers = useCallback((depotElements: LiveStop[]) => {
        return depotElements?.map(({ taskId, location }) => (
            <DepotMarker key={taskId} lat={location.lat} lng={location.lng} />
        ));
    }, []);

    return {
        makeDepotMarkers
    };
};
