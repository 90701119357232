import { useQuery, UseQueryOptions } from 'react-query';
import { RoutesApi } from '~/api/RoutesApi';
import { ApiRoute } from '~/api/types';

type UseGetRouteReactQueryOptions = Omit<
    UseQueryOptions<ApiRoute, Error>,
    'queryKey' | 'queryFn'
>;

const REACT_QUERY_KEY = 'route';

export const useGetRoute = (
    id: string,
    reactQueryOptions: UseGetRouteReactQueryOptions = {}
) => {
    const validId = Boolean(id);

    const fetchRoute = async (routeId: string): Promise<ApiRoute> => {
        const {
            data: { data }
        } = await RoutesApi.getRouteById(routeId);
        return data;
    };

    // do not run when the start date is not valid
    const defaultReactQueryOptions = {
        enabled: validId
    };

    // merge query options
    const mergedOptions: UseGetRouteReactQueryOptions = {
        ...defaultReactQueryOptions,
        ...reactQueryOptions
    };

    // return the full useQuery Object
    return useQuery<ApiRoute, Error>(
        [REACT_QUERY_KEY, id],
        () => fetchRoute(id),
        mergedOptions
    );
};
