import { UIElementWithChildren, IconData } from '../types';

/**
 * Enum of supported alert variants
 */
export enum AlertVariants {
    WARNING = 'warning',
    WARNING_DARK = 'warning-dark',
    DANGER = 'danger',
    DANGER_DARK = 'danger-dark',
    INFO = 'info'
}

export type AlertVariant = `${AlertVariants}`;

export interface AlertProps extends UIElementWithChildren, IconData {
    /**
     * Used to set the alert variant
     */
    variant?: AlertVariants | AlertVariant;
}
