import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import type { RootState } from '~/store';
import { initialStateCurrentUser, CurrentUserState } from './types';
import { ApiUser } from '~/api/types';

export const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState: initialStateCurrentUser,
    reducers: {
        /**
         * Set current user data into state
         */
        setCurrentUser: (
            state: CurrentUserState,
            action: PayloadAction<ApiUser>
        ) => {
            return action.payload;
        },

        /**
         * Resets to a initial current user state
         */
        resetCurrentUser: () => {
            return initialStateCurrentUser;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return initialStateCurrentUser;
        });
    }
});

export const { setCurrentUser, resetCurrentUser } = currentUserSlice.actions;

/**
 * Selects the current user state
 */
export const selectCurrentUser = (state: RootState): CurrentUserState =>
    state.currentUser;

export default currentUserSlice.reducer;
