/* eslint-disable react/destructuring-assignment */
import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { FlexLayout, Radio } from '~/ui/components';

import './RadioGroup.scss';

export interface RadioInputData<V extends string = string> {
    id: string;
    label: ReactNode;
    description?: string;
    value: V;
    disabled?: boolean;
    'data-testid'?: string;
}

export interface RadioGroupProps<V extends string = string> {
    radioData: readonly RadioInputData<V>[];
    name: string;
    label?: string;
    value: V;
    required?: boolean;
    onChange?: (v: V) => void;
    radioLabelClassName?: string;
    radioLabelDescriptionClassName?: string;
    radioInputGroupClassName?: string;
    radioInputLabelClassName?: string;
    radioInputClassName?: string;
    radioGroupLabelClassName?: string;
    className?: string;
    'data-testid'?: string;
    'radio-testid'?: string;
    'radioLabel-testid'?: string;
    'radioLabelDescription-testid'?: string;
    'radioGroupLabel-testid'?: string;
    'radioInputGroup-testid'?: string;
    'radioInputLabel-testid'?: string;
    'asterisk-testid'?: string;
}

const className = 'radio-group';
const radioLabelClassName = `${className}__radio-label`;
const radioLabelDescriptionClassName = `${className}__radio-label-description`;
const radioGroupLabelClassName = `${className}__label`;
const radioInputClassName = `${className}__radio-input`;
const radioInputGroupClassName = `${className}__radio-input-group`;
const radioInputLabelClassName = `${className}__radio-input-label`;

function RadioGroup<V extends string>({
    radioData,
    name,
    label,
    onChange,
    required,
    ...props
}: RadioGroupProps<V>): JSX.Element {
    return (
        <div
            className={classNames(
                className,
                '_d-flex',
                '_fd-column',
                props.className
            )}
            data-testid={props['data-testid']}
        >
            <FlexLayout flexDirection="row" sx={{ columnGap: '0.4rem' }}>
                {label ? (
                    <>
                        <span
                            className={classNames(
                                radioGroupLabelClassName,
                                '_d-flex',
                                '_ai-center',
                                props.radioGroupLabelClassName
                            )}
                            data-testid={props['radioGroupLabel-testid']}
                        >
                            {label}
                        </span>
                        {required ? (
                            <span
                                data-testid={props['asterisk-testid']}
                                className={`${className}__asterisk`}
                            >
                                *
                            </span>
                        ) : null}
                    </>
                ) : null}
            </FlexLayout>
            <FlexLayout
                className={classNames(
                    radioInputGroupClassName,
                    props.radioInputGroupClassName
                )}
                data-testid={props['radioInputGroup-testid']}
            >
                {radioData.map(({ id, value, disabled, ...radioInputData }) => {
                    const isChecked = props.value === value;

                    const labelClassName = classNames(
                        radioLabelClassName,
                        '_d-flex',
                        '_ai-center',
                        props.radioLabelClassName,
                        {
                            [`${radioLabelClassName}--disabled`]: disabled,
                            [`${props.radioLabelClassName}--disabled`]:
                                disabled && props.radioLabelClassName
                        }
                    );

                    const inputLabelClassName = classNames(
                        radioInputLabelClassName,
                        props.radioInputLabelClassName,
                        {
                            [`${radioInputLabelClassName}--checked`]: isChecked,
                            [`${props.radioInputLabelClassName}--checked`]:
                                isChecked && props.radioInputLabelClassName
                        }
                    );

                    return (
                        <label
                            className={labelClassName}
                            key={id}
                            data-testid={props['radioLabel-testid']}
                            htmlFor={id}
                        >
                            <Radio
                                id={id}
                                className={classNames(
                                    radioInputClassName,
                                    props.radioInputClassName
                                )}
                                name={name}
                                value={value}
                                onChange={(e) =>
                                    onChange?.(e.target.value as V)
                                }
                                checked={isChecked}
                                disabled={disabled}
                                data-testid={
                                    radioInputData['data-testid'] ||
                                    props['radio-testid']
                                }
                            />
                            <div className="_fd-column">
                                <span
                                    className={inputLabelClassName}
                                    data-testid={
                                        props['radioInputLabel-testid']
                                    }
                                >
                                    {radioInputData.label}
                                </span>
                                {radioInputData.description && (
                                    <span
                                        className={classNames(
                                            radioLabelDescriptionClassName,
                                            props.radioLabelDescriptionClassName
                                        )}
                                        data-testid={
                                            props[
                                                'radioLabelDescription-testid'
                                            ]
                                        }
                                    >
                                        {radioInputData.description}
                                    </span>
                                )}
                            </div>
                        </label>
                    );
                })}
            </FlexLayout>
        </div>
    );
}

export default RadioGroup;
