import { AssignmentDelay, AssignmentStatus } from '~/api/types';
import i18n from '~/i18n';

/**
 * Assignment Utilities
 *
 * @category Utils
 * @module utils/assignmentUtils
 */

/**
 * Mapping of assignment delay keys to localized label
 */
const LocalizedAssignmentDelayLabels = {
    EARLY: i18n.t('common:taskDelay.early'),
    ON_TIME: i18n.t('common:taskDelay.onTime'),
    AT_RISK: i18n.t('common:taskDelay.atRisk'),
    LATE: i18n.t('common:taskDelay.late'),
    PENDING: i18n.t('common:taskDelay.pending')
} as Record<string, string>;

/**
 * Retrieves the `AssignmentStatus` key using the status code
 */
export const getAssignmentCodeLabel = (code: AssignmentStatus): string => {
    const allKeys = Object.keys(AssignmentStatus);
    const valIndex = Object.values(AssignmentStatus).indexOf(code);
    return allKeys[valIndex];
};

/**
 * Retrieves the `AssignmentDelay` key using the delay code
 */
export const getDelayCodeLabel = (code: AssignmentDelay): string => {
    const allKeys = Object.keys(AssignmentDelay);
    const valIndex = Object.values(AssignmentDelay).indexOf(code);
    return allKeys[valIndex];
};

/**
 * Retrieves the localized `AssignmentDelay` label using the delay code
 */
export const getLocalizedAssignmentDelayLabel = (
    code: AssignmentDelay
): string => {
    const label = getDelayCodeLabel(code);
    return label
        ? LocalizedAssignmentDelayLabels[label]
        : LocalizedAssignmentDelayLabels.PENDING;
};

/**
 * Determines whether the assignment is assigned
 */
export const isAssignmentAssigned = (code?: number): boolean => {
    return code === AssignmentStatus.ASSIGNED;
};

/**
 * Determines whether the assignment is canceled
 */
export const isAssignmentCanceled = (code?: number): boolean => {
    return code === AssignmentStatus.CANCELED;
};

/**
 * Determines whether the assignment is completed
 */
export const isAssignmentCompleted = (code?: number): boolean => {
    return code === AssignmentStatus.COMPLETED;
};

/**
 * Determines whether the assignment is in-progress
 */
export const isAssignmentInProgress = (code?: number): boolean => {
    return code === AssignmentStatus.IN_PROGRESS;
};

/**
 * Determines whether the assignment is at-risk
 */
export const isAssignmentAtRisk = (code?: number): boolean => {
    return code === AssignmentDelay.AT_RISK;
};

/**
 * Determines whether the assignment is early
 */
export const isAssignmentEarly = (code?: number): boolean => {
    return code === AssignmentDelay.EARLY;
};

/**
 * Determines whether the assignment is late
 */
export const isAssignmentLate = (code?: number): boolean => {
    return code === AssignmentDelay.LATE;
};

/**
 * Determines whether the assignment is on-time
 */
export const isAssignmentOnTime = (code?: number): boolean => {
    return code === AssignmentDelay.ON_TIME;
};

/**
 * Retrieves the progress bar CSS variable corresponding to the provided assignment status label
 */
export const getProgressBarAssignmentColor = (
    assignmentLabel: string
): string => {
    return `var(--color-assignment-${assignmentLabel})`;
};

/**
 * Retrieves the progress bar CSS variable corresponding to the provided delay status label
 */
export const getProgressBarDelayColor = (
    assignmentLabel: string,
    completed = false
): string => {
    const color = `--color-status-${assignmentLabel}`;
    return completed ? `var(${color}-completed)` : `var(${color})`;
};
