/* eslint-disable camelcase */
import { IPrivateApiFields } from './CommonTypes';

/**
 * String enum of possible user roles.
 */
export enum UserRoles {
    SALES = 'sales',
    USER = 'user',
    ADMINISTRATOR = 'administrator'
}

/**
 * String enum of possible user permission features.
 *
 * These should match available features in Wise API
 *
 * @see {@link https://gitlab.com/wisesystems/wise-api/-/blob/stage_release/server/var/permissions.json permissions.json}
 */
export enum UserPermissionFeatures {
    DRIVER_BOOKING = 'driver_booking',
    DRIVER_MANAGEMENT = 'driver_management',
    PLANNING = 'planning',
    USER_MANAGEMENT = 'user_management',
    TASK_MANAGEMENT = 'task_management',
    COST_MANAGEMENT = 'cost_management',
    MOBILE_MERCHANDISER = 'mobile_merchandiser',
    MOBILE_MANAGER = 'mobile_manager',
    OPTIMIZATION_SETTINGS = 'optimization_settings'
}

/**
 * String enum of possible user permission roles.
 *
 * These should mostly matchup with available roles in WISE API
 *
 * @see {@link https://gitlab.com/wisesystems/wise-api/-/blob/stage_release/server/var/permissions.json permissions.json}
 */
export enum UserPermissionRoles {
    EDITOR = 'editor',
    VIEWER = 'viewer',
    NO_ACCESS = 'no_access'
}

/**
 * Type for API user access
 */
export type ApiUserAccess = IPrivateApiFields & {
    client: string;
    roles: UserRoles[];
};

/**
 * Type for API user
 */
export type ApiUser = IPrivateApiFields & {
    /**
     * the user's id
     */
    id: string;
    /**
     * the user's username
     */
    username: string;
    /**
     * the user's email address
     */
    email: string;
    /**
     * the user's firstname
     */
    firstname: string;
    /**
     * the user's lastname
     */
    lastname: string;
    /**
     * the related client's id
     */
    client: string;
    /**
     * the user's roles
     */
    roles: UserRoles[];
    /**
     * the user's access
     */
    access: ApiUserAccess[];
};

export interface UserRolesAndPermissions {
    /**
     * the user's role for the permission
     */
    role: UserPermissionRoles;
    /**
     * the user's feature access
     */
    feature: UserPermissionFeatures;
}

export interface UserClientAccess {
    /**
     * the user's client ID
     */
    client_id: string;
    /**
     * the user's permitted features and roles
     */
    permission: UserRolesAndPermissions[];
}

export interface ApiUserGroup {
    /**
     * the user group ID
     */
    id: string;
    /**
     * the user group client ID
     */
    client_id: string;
    /**
     * the user group owner ID
     */
    owner_id: string;
    /**
     * the user group name
     */
    name: string;
    /**
     * the user group client access
     */
    client_access: UserClientAccess[];
}
