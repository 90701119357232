import React from 'react';

import './OptimConfigTextInput.scss';
import classNames from 'classnames';

interface OptimConfigTextInputProps {
    /**
     * Input placeholder
     */
    placeholder?: string;
    /**
     * Unit of input
     */
    unitText?: string;
    /**
     * Error state of input
     */
    isErrorState?: boolean;
    /**
     * Input value
     */
    value: string | number;
    /**
     * Input onChange function
     */
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    /**
     * Input onBlur function
     */
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    /**
     * Input disabled
     */
    disabled?: boolean;
    /**
     * Input readonly
     */
    readOnly?: boolean;
    /**
     * Input name
     */
    name?: string;
    /**
     * Input type
     */
    type?: string;
    /**
     * Additional class name
     */
    className?: string;
}

function OptimConfigTextInput({
    placeholder,
    unitText,
    isErrorState = false,
    value,
    onChange,
    onBlur,
    disabled,
    readOnly = false,
    name = '',
    type = 'text',
    className = ''
}: OptimConfigTextInputProps) {
    return (
        <div
            className={classNames(
                'optim-config-text-input_container',
                className
            )}
        >
            <input
                type={type}
                className={classNames('optim-config-text-input_input', {
                    'optim-config-text-input--error': isErrorState
                })}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={disabled}
                readOnly={readOnly}
                name={name}
                data-testid="optim-config-text-input"
            />
            <div className="_d-flex _ai-center unit-text_container">
                {unitText && (
                    <span
                        className="unit-text_element _ai-center _ws-nowrap"
                        data-testid="unit-text"
                    >
                        {unitText}
                    </span>
                )}
            </div>
        </div>
    );
}

export default OptimConfigTextInput;
