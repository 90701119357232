import React, { useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { intersection } from 'lodash';

import { LiveStop, PlanStop } from '~/data-classes';
import { selectSelectedMapStops } from '~/reducers/selectedMapStopsSlice';

import { useMapUtils } from '~/hooks';
import { useClickOutside } from '~/ui/hooks';
import { StackPinMarkerPopup } from '~/ui';
import { StopMarkerList } from '~/ui/components/StackPinMarkerPopup/StopMarkerList';

import { StackPinMarkerProps } from './types';
import { ApiLiveStop, ApiTask, SocketScheduleItem } from '~/api/types';
import { unplannedCSS } from '~/utils/styleContants';
import { markerUtils } from '~/utils/map';
import { colorUtils } from '~/utils/color-utils';
import { idUtils } from '~/utils/id-utils';

import './StackPinMarker.scss';

const useIsStackMarkerSelected = (
    tasks: PlanStop[] | ApiTask[] | ApiLiveStop[]
) => {
    const { isPlanRouteMode } = useMapUtils();
    const selectedMapStops = useSelector(selectSelectedMapStops);

    return useMemo(() => {
        let taskIds = [];
        if (isPlanRouteMode) {
            taskIds = tasks.map((taskData) => {
                const { clientRouteTaskId } = taskData as PlanStop;
                if (!clientRouteTaskId) {
                    return markerUtils.getTaskIdForSelection(taskData);
                }
                return clientRouteTaskId;
            });
        } else {
            taskIds = tasks.map((taskData) => {
                const { stopNumber } = taskData as ApiLiveStop;
                if (stopNumber) {
                    return taskData.id;
                }
                return markerUtils.getTaskIdForSelection(taskData);
            });
        }
        return Boolean(intersection(taskIds, selectedMapStops).length);
    }, [tasks, isPlanRouteMode, selectedMapStops]);
};

export const StackPinMarker = ({
    numClustered,
    tasks,
    emittedEventHandler = () => {},
    onDemandDispatchMarkerEventHandler = () => {},
    isShowClickAllButton
}: StackPinMarkerProps) => {
    const { t } = useTranslation(['common']);
    const { isPlanRouteMode, isLiveRouteMode } = useMapUtils();
    const ref = useRef<HTMLSpanElement>(null);
    const [showStackPinPopup, setShowStackPinPopup] = useState(false);

    const isStackMarkerSelected = useIsStackMarkerSelected(tasks);

    const containsMultipleRoutes = useMemo(() => {
        if (isPlanRouteMode) {
            const sets = new Set(
                tasks.map((task) => {
                    const { clientRouteId } = task as PlanStop;
                    return clientRouteId;
                })
            );
            return sets.size >= 2;
        }
        const sets = new Set(
            tasks.map((task) => {
                const { euid, driver } = task as unknown as SocketScheduleItem;
                return euid || driver;
            })
        );
        return sets.size >= 2;
    }, [tasks, isPlanRouteMode]);

    const handleOnClick = () => {
        setShowStackPinPopup(!showStackPinPopup);
    };

    useClickOutside(ref, showStackPinPopup ? handleOnClick : null);

    const getClassName = () => {
        const boxClassName = 'stopmarker-body';

        const conditionalClasses = {
            'stopmarker-body--selected': showStackPinPopup,
            'stopmarker-body--highlighted': isStackMarkerSelected
        };
        return classNames(boxClassName, conditionalClasses);
    };

    const shouldShowGradient = containsMultipleRoutes;

    const colorStyle = useMemo(() => {
        if (shouldShowGradient) {
            return { color: 'white', backgroundColor: 'black' };
        }
        const task = tasks[0] as PlanStop;
        if (isPlanRouteMode) {
            if (task.isPlanned) {
                return task.colorCSS;
            }
            return unplannedCSS;
        }
        if (isLiveRouteMode) {
            const { clientId, driver } = task as unknown as LiveStop;
            return colorUtils.getWebColorsForId(
                idUtils.getCombinedId(clientId as string, driver as string)
            );
        }
        return unplannedCSS;
    }, [shouldShowGradient, tasks, isPlanRouteMode, isLiveRouteMode]);

    const getGradientBackgroundClassName = () => {
        return classNames('stopmarker-circle', {
            'gradient-background': shouldShowGradient
        });
    };

    return (
        <span
            ref={ref}
            className="stack-pin-marker"
            data-testid="stack-pin-marker"
        >
            {showStackPinPopup && (
                <StackPinMarkerPopup tasksCount={tasks.length}>
                    <StopMarkerList
                        tasks={tasks}
                        emittedEventHandler={emittedEventHandler}
                        onDemandDispatchMarkerEventHandler={
                            onDemandDispatchMarkerEventHandler
                        }
                        isShowClickAllButton={isShowClickAllButton}
                    />
                </StackPinMarkerPopup>
            )}
            <button
                type="button"
                className="stopmarker unassigned-stack-pin"
                data-testid="stop-marker"
                onClick={handleOnClick}
            >
                <div
                    className={getClassName()}
                    data-testid="default-stop-marker"
                >
                    <span
                        className={getGradientBackgroundClassName()}
                        data-testid="stop-marker-circle"
                        style={colorStyle}
                    />
                    <span
                        className="stopmarker-circle"
                        data-testid="stop-marker-circle"
                        style={colorStyle}
                    />
                    <span
                        className="stack-cluster-count"
                        data-testid="stack-cluster-count"
                    >
                        {t(
                            numClustered > 100
                                ? 'stackStopsPopup.moreClusteredStopsCount'
                                : 'stackStopsPopup.clusteredStopsCount',
                            {
                                count: numClustered
                            }
                        )}
                    </span>
                </div>
            </button>
        </span>
    );
};
