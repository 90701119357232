import React, { CSSProperties, MouseEventHandler } from 'react';
import classNames from 'classnames';
import { Icon, TooltipButton } from '~/ui';
import { TooltipPlacement } from '~/ui/components/Tooltip';
import './icon-button.scss';
import { IconSize } from '~/ui/components/Icon/IconSizes';
import { IconName } from '~/ui/components/Icon/IconNames';

type Props = {
    /** Used to set additional class names to the button */
    className?: string;
    /** Styling */
    style?: CSSProperties | undefined;
    /** Used to disable button */
    disabled?: boolean;
    /** Used to explicitly show / hide the button */
    visible?: boolean;
    /** Short text that can be used as an alternative to SVG icons */
    text?: string;
    /** Short text color */
    textColor?: string;
    /** Short text styling */
    textStyle?: CSSProperties | undefined;
    /** SVG icon string ID */
    icon?: IconName;
    /** SVG icon size */
    iconSize?: IconSize;
    /** SVG icon color */
    iconColor?: string;
    /** Used to add a tooltip for the button */
    tooltipMessage?: string;
    /** Determine placement position of the button tooltip */
    tooltipPlacement?: TooltipPlacement;
    tooltipContentSx?: CSSProperties;
    /** Attach a handler when clicking the button */
    onClick?: MouseEventHandler<HTMLButtonElement>;
    /** Attach a handler when the mouse enters the button */
    onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
    /** Attach a handler when the mouse leaves the button */
    onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
    /** Used to set the button variant */
    variant?: string;
    /** Test Id for unit testing */
    'data-testid'?: string;
};

function IconButton({
    className,
    style,
    disabled,
    visible = true,
    text,
    textColor,
    textStyle,
    icon,
    iconSize = 'm',
    iconColor,
    tooltipMessage = '',
    tooltipPlacement = 'bottom',
    tooltipContentSx,
    variant = 'default',
    onClick,
    onMouseEnter,
    onMouseLeave,
    ...extra
}: Props): JSX.Element | null {
    function getClassName() {
        const defaultClassName = 'icon-button';
        const conditionalClasses = {
            [`${defaultClassName}_${variant}`]: variant,
            'icon-button_text-over-icon': icon && text
        };
        return classNames(defaultClassName, conditionalClasses, className);
    }

    function getButtonContents() {
        if (icon && text) {
            return (
                <>
                    {getButtonIcon()}
                    {getButtonText()}
                </>
            );
        }

        if (icon) {
            return getButtonIcon();
        }

        return getButtonText();
    }

    function getButtonIcon() {
        return (
            <Icon
                disabled={disabled}
                icon={icon}
                size={iconSize}
                color={iconColor}
                data-testid={
                    extra['data-testid']
                        ? `${extra['data-testid']}_icon`
                        : 'icon-button_icon'
                }
            />
        );
    }

    function getButtonText() {
        return (
            <span
                className="icon-button_text"
                style={{ color: textColor, ...textStyle }}
                data-testid={
                    extra['data-testid']
                        ? `${extra['data-testid']}_text`
                        : 'icon-button_text'
                }
            >
                {text}
            </span>
        );
    }

    if (!icon && !text) return null;

    return (
        <TooltipButton
            className={getClassName()}
            style={style}
            disabled={disabled}
            visible={visible}
            tooltipMessage={tooltipMessage}
            tooltipPlacement={tooltipPlacement}
            tooltipContentSx={tooltipContentSx}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            data-testid={extra['data-testid'] || 'icon-button'}
        >
            {getButtonContents()}
        </TooltipButton>
    );
}

export default IconButton;
