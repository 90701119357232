import { DateTime } from 'luxon';
import { DownloadableReportItem } from './DownloadableReportItem';
import { ApiAnalyticsAggregateDriversDownload } from '~/api/types';
import { GeneratedReportItem } from './GeneratedReportItem';
import { getReportOrder } from './utils';

/**
 * DownloadableReports data class
 *
 * @category Data Classes
 *
 * @example
 * import { DownloadableReports } from '~/data-classes/report';
 *
 * const srcData = [];
 * const reports = new DownloadableReports(srcData);
 *
 */
export class DownloadableReports {
    /**
     * The API source data
     * @type {ApiAnalyticsAggregateDriversDownload[]}
     */
    private readonly initReports: ApiAnalyticsAggregateDriversDownload[];

    private readonly rawReports: Record<
        string,
        DownloadableReportItem | GeneratedReportItem
    >;

    private filtered: boolean;

    private filteredReports: (DownloadableReportItem | GeneratedReportItem)[];

    // No constructor JSDoc to avoid duplicates in generated docs
    // https://github.com/jsdoc/jsdoc/issues/1775
    constructor(initReports: ApiAnalyticsAggregateDriversDownload[]) {
        this.initReports = initReports;

        this.rawReports = this.initReports.reduce((rawReports, report) => {
            const reportItem = new DownloadableReportItem(report);
            rawReports[reportItem.key] = reportItem;
            return rawReports;
        }, {} as Record<string, DownloadableReportItem>);

        this.filteredReports = [];

        this.filtered = false;
    }

    get reports(): (DownloadableReportItem | GeneratedReportItem)[] {
        return this.filtered
            ? this.filteredReports
            : Object.values(this.rawReports);
    }

    get hasCoreReports(): boolean {
        return this.reports.some((report) => report.isCoreReport);
    }

    get hasCustomReports(): boolean {
        return this.reports.some((report) => report.isCustomReport);
    }

    get hasUnknownReports(): boolean {
        return this.reports.some((report) => report.isUnknownCustomReport);
    }

    addGeneratedReportItem(generatedReports: GeneratedReportItem[]): this {
        // reset the filter, in-case previously filtered
        this.filtered = false;
        generatedReports.forEach((report) => {
            this.rawReports[report.key] = report;
        });
        return this;
    }

    filterReportsByDates(filterReportDates: DateTime[] = []): this {
        const filteredReports = Object.values(this.rawReports).filter(
            (report) => report.isWithinReportDates(filterReportDates)
        );

        this.filteredReports = filteredReports;
        this.filtered = true;
        return this;
    }

    groupReportsByDate(): Record<
        string,
        (DownloadableReportItem | GeneratedReportItem)[]
    > | null {
        const reportsByDate = this.reports.reduce((allReports, report) => {
            const reportDate = report.reportDate.toISODate();

            if (!allReports[reportDate]) {
                allReports[reportDate] = {};
            }

            allReports[reportDate][report.type] = report;

            return allReports;
        }, {} as Record<string, Record<string, DownloadableReportItem | GeneratedReportItem>>);

        if (!Object.keys(reportsByDate).length) return null;

        const sortedReports = Object.entries(reportsByDate).reduce(
            (allReports, [key, reportsByType]) => {
                const updatedOrder = getReportOrder(Object.keys(reportsByType));

                const orderedReports = updatedOrder
                    .map((type) => reportsByType[type])
                    .filter(Boolean);

                allReports[key] = orderedReports;

                return allReports;
            },
            {} as Record<
                string,
                (DownloadableReportItem | GeneratedReportItem)[]
            >
        );

        return sortedReports;
    }

    groupReportsByType(): Record<
        string,
        (DownloadableReportItem | GeneratedReportItem)[]
    > | null {
        const reportsByType = this.reports.reduce((allReports, report) => {
            const reportType = report.type;

            if (!allReports[reportType]) {
                allReports[reportType] = [];
            }

            allReports[reportType].push(report);

            return allReports;
        }, {} as Record<string, (DownloadableReportItem | GeneratedReportItem)[]>);

        if (!Object.keys(reportsByType).length) return null;

        const updatedOrder = getReportOrder(Object.keys(reportsByType));

        const orderedReports = updatedOrder.reduce((all, type) => {
            if (reportsByType[type]) {
                all[type] = reportsByType[type];
            }
            return all;
        }, {} as Record<string, (DownloadableReportItem | GeneratedReportItem)[]>);

        return orderedReports;
    }

    /**
     * Serializes this class back to JSON
     * @returns {ApiAnalyticsAggregateDriversDownload[]}
     */
    toJSON(): ApiAnalyticsAggregateDriversDownload[] {
        return this.initReports;
    }
}
