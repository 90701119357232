import React from 'react';
import classNames from 'classnames';
import { IconButton, Text, TextOverflowTooltip } from '~/ui';
import { ManageStopControlListItemMetrics } from './ManageStopControlListItemMetrics';
import './ManageStopControlListItem.scss';
import { ManageStopControlListItemProps } from './types';

export const ManageStopControlListItem = ({
    text,
    iconButtonText,
    iconButtonTextColor,
    iconButtonTextStyle,
    tooltipPlacement = 'top',
    className,
    iconButtonStyle,
    taskTypeText,
    metricsIconsData = [],
    'data-testid': dataTestId
}: ManageStopControlListItemProps) => {
    const ROOT_CLASS_NAME = 'manage-stop-control-list-item';
    const elementClassName = classNames(
        ROOT_CLASS_NAME,
        '_d-flex',
        '_p-relative',
        className
    );

    const isWithMetrics = Boolean(metricsIconsData.length);
    const textMetricsClassName = classNames(
        `${ROOT_CLASS_NAME}__text-metrics`,
        '_fd-column',
        {
            '_jc-center': !isWithMetrics,
            [`${className}__text-metrics`]: className
        }
    );
    const textClassName = classNames(
        `${ROOT_CLASS_NAME}__text`,
        'stopitem-title',
        '_text-3',
        '_to-ellipsis',
        {
            [`${className}__text`]: className
        }
    );
    const taskTypeClassName = classNames(
        `${ROOT_CLASS_NAME}__task-type`,
        '_p-absolute',
        {
            [`${className}__task-type`]: className
        }
    );

    const elementDataTestId = dataTestId || ROOT_CLASS_NAME;
    const iconButtonDataTestId = `${elementDataTestId}__icon-button`;
    const textMetricsDataTestId = `${elementDataTestId}__text-metrics`;
    const textDataTestId = `${elementDataTestId}__text`;
    const taskTypeDataTestId = `${elementDataTestId}__text`;

    return (
        <div className={elementClassName} data-testid={elementDataTestId}>
            <IconButton
                disabled
                style={iconButtonStyle}
                text={iconButtonText}
                textColor={iconButtonTextColor}
                textStyle={iconButtonTextStyle}
                data-testid={iconButtonDataTestId}
            />
            <div
                className={textMetricsClassName}
                data-testid={textMetricsDataTestId}
            >
                <TextOverflowTooltip
                    placement={tooltipPlacement}
                    content={text}
                >
                    <Text
                        className={textClassName}
                        variant="12-normal"
                        data-testid={textDataTestId}
                    >
                        {text}
                    </Text>
                </TextOverflowTooltip>
                <ManageStopControlListItemMetrics
                    iconsData={metricsIconsData}
                />
            </div>
            {taskTypeText && (
                <div
                    className={taskTypeClassName}
                    data-testid={taskTypeDataTestId}
                >
                    <span>{taskTypeText}</span>
                </div>
            )}
        </div>
    );
};
