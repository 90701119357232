import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useReassignPlanRouteTasks } from '~/components/MapPage/useReassignPlanRouteTasks';
import { getReassignPlanPayload } from '../utils';
import { PlanRoute, PlanStop } from '~/data-classes';
import { RouteTripSummary } from '~/components/MapPageDrawers/SelectedCardsDrawer/types';
import { useGetRoute } from '~/hooks/useGetRoute';

type UseReassignPlanStopsProps = {
    selectedRouteId: string;
    selectedTripId: string;
    routes: PlanRoute[];
    selectedStops: PlanStop[];
};

const useReassignPlanStops = ({
    selectedRouteId,
    routes,
    selectedStops,
    selectedTripId
}: UseReassignPlanStopsProps) => {
    const { t } = useTranslation('translation');
    const { reassignPlanRouteTasks } = useReassignPlanRouteTasks();
    const { data: selectedRoute } = useGetRoute(selectedRouteId);

    const selectedPlanRoute = useMemo(() => {
        return routes.find(({ routeId }) => selectedRouteId === routeId);
    }, [selectedRouteId, routes]);

    const { hasMultipleTrips, trips } = useMemo(() => {
        const routeTrips = (selectedPlanRoute?.trips || []).map(
            (trip: RouteTripSummary, index: number) => {
                return {
                    ...trip,
                    formattedName: t('TripName', { number: index + 1 })
                };
            }
        );
        return { hasMultipleTrips: routeTrips.length > 1, trips: routeTrips };
    }, [selectedPlanRoute, t]);

    const needsNewTrip = useMemo(() => {
        const targetRouteIsDispatched = Boolean(selectedRoute?.startTime);
        const isExplicitlyTargettingTrip = Boolean(selectedTripId);
        return targetRouteIsDispatched && !isExplicitlyTargettingTrip;
    }, [selectedRoute, selectedTripId]);

    const reassignStops = useCallback(() => {
        const payload = getReassignPlanPayload({
            selectedRoute: selectedPlanRoute,
            selectedStops,
            selectedTripId
        });
        if (!payload) {
            return;
        }

        const {
            taskIds,
            taskIdsByRouteId,
            driverId,
            selectedRouteId: targetRouteId,
            oldRouteId,
            clientId
        } = payload;

        reassignPlanRouteTasks({
            clientId,
            driverId,
            oldRouteId,
            targetRouteId,
            taskIds,
            taskIdsByRouteId,
            needsNewTrip
        });
    }, [
        reassignPlanRouteTasks,
        selectedPlanRoute,
        selectedStops,
        selectedTripId,
        needsNewTrip
    ]);

    return { reassignStops, showTrips: hasMultipleTrips, routeTrips: trips };
};

export default useReassignPlanStops;
