import React from 'react';
import _ from 'lodash';

import { markerMaker, markerUtils } from '~/utils/map';
import LiveDriver from '~/data-classes/dispatched/LiveDriver';
import { RouteLine, StackPinMarker } from '~/ui';
import { planRouteUtils } from '~/utils/plan-route-utils';
import { idUtils } from '~/utils/id-utils';
import { routeHasMultipleTrips } from '~/utils/tripUtils';

const makeRouteLine = ({
    allPlannedStops,
    clientId,
    driverId,
    mapInstance,
    routeId,
    uniqueKey
}) => {
    const routeStops = _.sortBy(
        allPlannedStops.filter((stop) => stop.routeId === routeId),
        'stopNumber'
    );

    const [firstStop] = routeStops;
    const liveDriverJSON = firstStop && {
        clientId,
        id: driverId,
        stats: {
            currentStop: 0
        },
        cepLocation: firstStop.markerCoordinates,
        schedule: routeStops.map(({ isDepot, markerCoordinates }) => ({
            isDepot,
            location: {
                location: markerCoordinates
            }
        }))
    };

    if (!liveDriverJSON || !mapInstance) return null;

    const liveDriver = new LiveDriver(liveDriverJSON);
    const key =
        uniqueKey || idUtils.getCombinedId(clientId, routeId, liveDriver.id);

    return (
        <RouteLine
            key={key}
            colorCSS={firstStop._colorCSS}
            driver={liveDriver}
            mapInstance={mapInstance}
        />
    );
};

export const makeRouteLines = ({ allPlannedStops, mapInstance, routePlans }) =>
    routePlans
        .filter((routePlan) => routePlan.isPlanned)
        .flatMap((routePlan, index) => {
            const { clientId, clientRouteId, driverId, routeId, trips } =
                routePlan;
            const uniqueKey = idUtils.getUniqueKeyForDuplicatedItem(
                idUtils.getCombinedId(clientId, driverId, routeId),
                index
            );

            if (!routeHasMultipleTrips(trips)) {
                return makeRouteLine({
                    allPlannedStops,
                    clientId,
                    driverId,
                    mapInstance,
                    routeId,
                    uniqueKey
                });
            }

            const { clientRouteIds } = planRouteUtils.getSelectedMapRoutes({
                planRoutes: [routePlan],
                selectedMapRoutes: [clientRouteId]
            });
            const routePlanStops = allPlannedStops.filter((stop) =>
                clientRouteIds.includes(stop.clientRouteId)
            );
            const schedulesByTrip =
                planRouteUtils.splitScheduleByTrips(routePlanStops);

            return schedulesByTrip.map((tripPlanStops, idx) => {
                const { routeId: tripRouteId } = tripPlanStops[0];
                const getTripIndex = idx + index + 1;
                const tripUniqueKey = idUtils.getUniqueKeyForDuplicatedItem(
                    idUtils.getCombinedId(clientId, driverId, tripRouteId),
                    getTripIndex
                );

                return makeRouteLine({
                    clientId,
                    driverId,
                    mapInstance,
                    allPlannedStops: tripPlanStops,
                    routeId: tripRouteId,
                    uniqueKey: tripUniqueKey
                });
            });
        })
        .filter(Boolean);

function makeMarkers(superCluster, geoJSONFeatures, emittedEventHandler, t) {
    const markers = [];
    const leaves = [];
    for (const geoJSONFeature of geoJSONFeatures) {
        const isCluster = geoJSONFeature.properties.cluster;
        if (isCluster) {
            leaves.push(
                ...superCluster.getLeaves(geoJSONFeature.id, Infinity, 0)
            );
        } else {
            leaves.push(geoJSONFeature);
        }
    }

    const groupedByLocation = markerUtils.groupLeavesByLocation(leaves);

    for (const locationKey in groupedByLocation) {
        const tasks = groupedByLocation[locationKey];
        if (tasks.length < 2) {
            const marker = markerMaker.makeStopMarker(
                { properties: groupedByLocation[locationKey][0] },
                emittedEventHandler,
                t
            );
            markers.push(marker);
        } else {
            const numClustered = tasks.length;
            const { lat, lng } =
                markerUtils.getLocationCoordinates(locationKey);
            const id = idUtils.getCombinedId(numClustered, locationKey);

            const marker = (
                <StackPinMarker
                    key={id}
                    numClustered={numClustered}
                    emittedEventHandler={emittedEventHandler}
                    tasks={tasks}
                    lat={lat}
                    lng={lng}
                />
            );
            markers.push(marker);
        }
    }
    return markers;
}

export default makeMarkers;
