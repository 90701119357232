import React from 'react';
import classNames from 'classnames';

import './routecard.scss';
import { UIElementWithChildren } from '../types';

interface RouteCardProps extends UIElementWithChildren {
    /**
     * Whether this route is selected
     */
    isRouteSelected?: boolean;

    /**
     * Whether this route is hidden
     */
    isRouteHidden?: boolean;

    /**
     * handler when the mouse enters the route card
     *
     * @todo
     * is this really needed? currently used to set hover styles. CSS can do this natively with `:hover`
     */
    onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;

    /**
     * handler when the mouse leaves the route card
     *
     * @todo
     * is this really needed? currently used to set hover styles. CSS can do this natively with `:hover`
     */
    onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
}

const RouteCard = React.forwardRef<HTMLDivElement, RouteCardProps>(
    function RouteCard(
        {
            className,
            children,
            isRouteSelected,
            isRouteHidden,
            onMouseEnter,
            onMouseLeave,
            ...extra
        },
        ref
    ) {
        const ROOT_CLASS_NAME = 'routecard';
        const conditionalClasses = {
            routecard_selected: isRouteSelected,
            routecard_off: isRouteHidden,
            [`${className}`]: className,
            [`${className}--selected`]: className && isRouteSelected,
            [`${className}--hidden`]: className && isRouteHidden
        };
        const elementClassName = classNames(
            ROOT_CLASS_NAME,
            '_d-flex',
            conditionalClasses
        );
        const elementDataTestId = extra['data-testid'] || ROOT_CLASS_NAME;

        return (
            <div
                className={elementClassName}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                ref={ref}
                data-testid={elementDataTestId}
            >
                {children}
            </div>
        );
    }
);

export default RouteCard;
