import { IconSizes } from '~/ui/components/Icon/IconSizes';
import { AppLogos } from './types';

/**
 * The specific query string parameter that represents a `customLogo`
 */
export const partnerLogoQuery = 'partner';

/**
 * Identifies that the application logos will be using the default
 */
export const wiseLogoId = 'wise';

/**
 * Wise application logos
 */
export const wiseLogos: AppLogos = {
    login: {
        icon: 'wiseLogo',
        iconColor: 'white',
        iconSize: IconSizes.XXL,
        iconStyle: { width: '30rem', height: '10rem' }
    },
    navbar: {
        icon: 'wiseSystems',
        iconColor: 'white',
        iconSize: IconSizes.XXL
    }
};

/**
 * Partner custom application logos
 *
 * the `key` should match the `customLogo` client preference
 *
 * partner custom logos must conform to the following:
 * + logo files must be in `SVG` format
 * + for best results, logo aspect ratios must either be `square` or `landscape`
 */
export const customLogos: Record<string, AppLogos> = {
    intempo: {
        login: {
            icon: 'intempoLogo',
            iconColor: 'white',
            iconSize: IconSizes.XXL,
            iconStyle: { width: 'auto', height: '10rem' }
        },
        navbar: {
            icon: 'intempoLogo',
            iconColor: 'white',
            iconSize: IconSizes.XXL
        }
    }
};
