/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import React from 'react';

import Icon, { IconProps } from '../Icon/Icon';
import { filterNilFromObj } from '~/utils/object-utils';

import './radio.scss';

export const radioContainerTestId = 'radio-containerTestId';
export const checkedRadioTestId = 'radio-checkedTestId';
export const unCheckedRadioTestId = 'radio-uncheckedTestId';
export const radioInputTestId = 'radio-inputTestId';

type InputProps = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>;

interface RadioIconProps extends Omit<IconProps, 'icon'> {
    checked?: boolean;
}

const RadioIcon = ({ className, checked, ...props }: RadioIconProps) => {
    const elementClassName = classNames(className, {
        'radio--checked': checked,
        'radio--unchecked': !checked
    });
    const elementDataTestId = checked
        ? checkedRadioTestId
        : unCheckedRadioTestId;
    const elementIcon = checked ? 'radioChecked' : 'radioUnchecked';

    return (
        <Icon
            {...props}
            icon={elementIcon}
            className={elementClassName}
            data-testid={elementDataTestId}
        />
    );
};

const Radio = React.forwardRef<HTMLInputElement, InputProps>(function Radio(
    { className, checked, defaultChecked, ...props },
    ref
) {
    // ensure the `defaultChecked` is set, instead of `checked`
    // + `checked` and `defaultChecked` cannot work at the same time
    const isRadioIconChecked = checked || defaultChecked;
    const inputProps = {
        ...props,
        defaultChecked: isRadioIconChecked
    };
    const cleanInputProps = filterNilFromObj(inputProps);

    return (
        <div className="radio-container" data-testid={radioContainerTestId}>
            <input
                ref={ref}
                type="radio"
                data-testid={radioInputTestId}
                {...cleanInputProps}
                className="radio__input"
            />
            <RadioIcon
                aria-hidden="true"
                className={classNames(className, 'radio__icon')}
                checked={isRadioIconChecked}
            />
        </div>
    );
});

export default Radio;
