import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMapUtils } from '~/hooks';
import { selectSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { LiveStop, OnDemandDispatchStop, PlanStop } from '~/data-classes';
import { useIsPlanStopSelected } from '../useIsPlanStopSelected';

interface Props {
    /**
     * plan stop or live stop data
     */
    stopData: PlanStop | LiveStop | OnDemandDispatchStop;
}
export const useIsStopSelected = ({ stopData }: Props) => {
    const { isPlanRouteMode } = useMapUtils();
    const { isPlanStopSelected } = useIsPlanStopSelected();
    const selectedMapStopIds = useSelector(selectSelectedMapStops);

    // PlanStops use 'clientRouteTaskId' and LiveStops use 'id'
    const stopId =
        stopData instanceof PlanStop ? stopData.clientRouteTaskId : stopData.id;

    return useMemo<boolean>(() => {
        if (isPlanRouteMode) return isPlanStopSelected(stopId);

        return selectedMapStopIds.includes(stopId);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        isPlanRouteMode,
        isPlanStopSelected,
        selectedMapStopIds,
        stopId,
        stopData
    ]);
};
