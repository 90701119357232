import { useEffect } from 'react';

import { useIsolatedRoutes, useMapUtils } from '~/hooks';

import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';

import { useDispatchedEquipmentMarkers } from './useDispatchedEquipmentMarkers';
import { useDispatchedRouteMarkers } from './useDispatchedRouteMarkers';
import { useDispatchedDriverObjects } from './useDispatchedDriverObjects';
import { useDispatchedUnassignedTasks } from './useDispatchedUnassignedTasks';
import {
    UseDispatchedModeMapMarkersProps,
    UseDispatchedModeMapMarkersReturnValues
} from './types';

const useDispatchedModeMapMarkers = ({
    stopLevelCoordinatesRef,
    driverCoordinatesRef,
    unassignedSuperClusters,
    equipmentSuperClusters,
    liveStopsSuperClusters
}: UseDispatchedModeMapMarkersProps): UseDispatchedModeMapMarkersReturnValues => {
    // @TODO type PlanMapPropsContext https://wisesys.atlassian.net/browse/RP-840
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { mapInstance } = usePlanMapPropsContext();
    const { isDispatchedRouteMode } = useMapUtils();

    const { isRouteMultiSelectInProgress } = useIsolatedRoutes();

    const { equipmentMarkers } = useDispatchedEquipmentMarkers({
        equipmentSuperClusters
    });

    const {
        routeMarkers: driverMarkers,
        routeLines: driverLines,
        routeMarkerCoordinates: driverMarkerCoordinates
    } = useDispatchedRouteMarkers();

    const { unassignedTasks, unassignedTaskMarkerCoordinates } =
        useDispatchedUnassignedTasks();

    const {
        depotStopMarkers,
        routeStopMarkers: dispatchedStopMarkers,
        routeLines: selectedRouteLines,
        routeStopCoordinates: dispatchedStopCoordinates
    } = useDispatchedDriverObjects({
        liveStopsSuperClusters,
        unassignedSuperClusters,
        unassignedTasks
    });

    useEffect(() => {
        if (!isDispatchedRouteMode || !mapInstance) {
            return;
        }
        driverCoordinatesRef.current = driverMarkerCoordinates;
    }, [
        driverCoordinatesRef,
        driverMarkerCoordinates,
        isDispatchedRouteMode,
        mapInstance
    ]);

    useEffect(() => {
        if (!isDispatchedRouteMode || isRouteMultiSelectInProgress) {
            return;
        }

        stopLevelCoordinatesRef.current = [
            ...dispatchedStopCoordinates,
            ...unassignedTaskMarkerCoordinates
        ];
    }, [
        stopLevelCoordinatesRef,
        dispatchedStopCoordinates,
        unassignedTaskMarkerCoordinates,
        isDispatchedRouteMode,
        isRouteMultiSelectInProgress
    ]);

    return {
        routeMarkers: driverMarkers,
        depotStopMarkers,
        routeStopMarkers: dispatchedStopMarkers,
        routeLines: driverLines,
        selectedRouteLines,
        routeMarkerCoordinates: driverMarkerCoordinates,
        equipmentMarkers
    };
};

export default useDispatchedModeMapMarkers;
