interface RootPersistConfig {
    /**
     * The redux persist key
     *
     * This will be used to set the local storage key which follows the format: `persist:{key}`
     */
    key: string;
    /**
     * An array of redux slices that will not be persisted between sessions and browser refreshes
     */
    blacklist?: string[];
    /**
     * An array of redux slices that will be persisted between sessions and browser refreshes
     */
    whitelist?: string[];
}

/**
 * Redux slices in this array will not persist their current state
 * between sessions and browser refreshes
 */
export const blacklist = [
    'activeClients',
    'addOperationalStop',
    'addTask',
    'appGlobals',
    'currentUser',
    'customerSearchResults',
    'driversByClient',
    'equipment',
    'loadTasks',
    'processIndicator',
    'schedulerProgress',
    'selectedBreakDetails',
    'selectedMapRoutes',
    'tasks',
    'taskUpload',
    'uploadResults',
    'vehicles',
    'webSolution'
];

/**
 * Redux slices in this array will persist their current state
 * between sessions and browser refreshes
 *
 * Only include slices that require the state to be persisted between sessions
 *
 * Do not persist slices into local storage that contain sensitive, private,
 * and/or confidential information about the user and client.
 */
export const whitelist = [
    'exportedRoutes',
    'equipmentTable',
    'liveDispatchTable',
    'mainClient',
    'mapSettings',
    'selectedDate'
];

export const rootPersistConfig: RootPersistConfig = {
    key: 'root',
    blacklist
};
