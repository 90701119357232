import React, { SyntheticEvent, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMapUtils } from '~/hooks';
import { LiveStop, PlanStop } from '~/data-classes';
import {
    selectShowStopLabel,
    selectShowStopNumber
} from '~/reducers/mapSettingsSlice';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { useIsStopSelected } from '~/ui/components/StopMarker/useIsStopSelected';
import {
    EmittedEventHandler,
    OnDemandDispatchMarkerEventHandler
} from '~/ui/components/LiveStopMarker/types';
import { useGetStopEventData } from './useGetStopEventData';
import { useGetDriverColor } from '~/ui/components/LiveStopMarker/useGetDriverColor';
import { StopMarkerNameAndLabels } from '~/ui/components/StopMarker/StopMarkerNameAndLabels';
import { ApiLiveStop } from '~/api/types';

import './AssignedStopMarker.scss';

interface AssignedStopMarkerProps {
    task: PlanStop | LiveStop | ApiLiveStop;
    emittedEventHandler: EmittedEventHandler;
    onDemandDispatchMarkerEventHandler: OnDemandDispatchMarkerEventHandler;
}

export const AssignedStopMarker = ({
    task,
    emittedEventHandler,
    onDemandDispatchMarkerEventHandler
}: AssignedStopMarkerProps) => {
    const { t } = useTranslation('common');
    const rootClassName = 'assigned-stop-marker';
    const { mapRouteMode, isPlanRouteMode } = useMapUtils();
    const isShowStopLabel = useSelector(
        selectShowStopLabel(mapRouteMode as ConfigurableMapRouteMode)
    );

    const {
        driver,
        stopName,
        isPlanned,
        isCompleted,
        isInProgress,
        isCanceled,
        labels,
        driverStopNumber,
        colorCSS,
        isPickup
    } = useMemo(() => {
        if (isPlanRouteMode) {
            const {
                driverStopNumber: stopNumber,
                colorCSS: colors,
                stopName: name,
                labels: labs,
                isPlanned: planned,
                isPickup: pickup
            } = task as PlanStop;
            return {
                stopName: name,
                driverStopNumber: stopNumber,
                isPickup: pickup,
                colorCSS: colors,
                isPlanned: planned,
                labels: labs,
                isCompleted: false,
                isInProgress: false,
                isCanceled: false,
                driver: ''
            };
        }
        const {
            driver: assignedDriver,
            driverStopNumber: stopNumber,
            isCompleted: completed,
            isCanceled: cancelled,
            isInProgress: progress,
            stopName: name,
            labels: labs,
            isPickup: pickup
        } = new LiveStop(task as ApiLiveStop);
        return {
            driver: assignedDriver,
            driverStopNumber: stopNumber,
            isCompleted: completed,
            isCanceled: cancelled,
            isInProgress: progress,
            stopName: name,
            labels: labs,
            isPickup: pickup,
            isPlanned: false,
            colorCSS: undefined
        };
    }, [task, isPlanRouteMode]);

    const taskType = useMemo(() => {
        if (isPlanRouteMode && !isPlanned) {
            return isPickup
                ? t('unassignedTaskIcons.pickup')
                : t('unassignedTaskIcons.delivery');
        }
        return '';
    }, [isPlanRouteMode, isPickup, isPlanned, t]);

    const driverColor = useGetDriverColor(driver);
    const isSelected = useIsStopSelected({ stopData: task as PlanStop });
    const showStopNumber = useSelector(
        selectShowStopNumber(mapRouteMode as ConfigurableMapRouteMode)
    );

    const isInteractive = !(isCanceled || isInProgress);
    const isDisabled = !isInteractive;
    const isClickable = isInteractive && !isCompleted;
    const { backgroundColor } = driverColor || {};
    const isStopCompletedStyle = {
        borderColor: backgroundColor,
        color: backgroundColor
    };

    const { emitEvent } = useGetStopEventData({
        stop: task as LiveStop | PlanStop,
        emittedEventHandler,
        onDemandDispatchMarkerEventHandler,
        isSelected
    });

    const getClassName = () => {
        return classNames(rootClassName, {
            'stopmarker--selected': isSelected
        });
    };

    const getMarkerStyle = () => {
        if (isCompleted) return isStopCompletedStyle;
        return colorCSS || driverColor;
    };

    const handleOnClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        if (!isClickable) return;

        emitEvent();
    };

    return (
        <div className={`${rootClassName}__container`}>
            <button
                type="button"
                className={getClassName()}
                onClick={handleOnClick}
                data-testid={rootClassName}
                disabled={isDisabled}
            >
                <div className={`${rootClassName}__body`}>
                    <div
                        className="_p-relative"
                        data-testid="default-stop-marker"
                    >
                        <span
                            className={`${rootClassName}__circle`}
                            data-testid="stop-marker-circle"
                            style={getMarkerStyle()}
                        >
                            {showStopNumber && driverStopNumber}
                            {taskType}
                        </span>
                    </div>
                </div>
            </button>
            {isShowStopLabel && (
                <StopMarkerNameAndLabels name={stopName} labels={labels} />
            )}
        </div>
    );
};
