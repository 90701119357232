import React from 'react';
import classNames from 'classnames';
import { Icon, Text, TooltipButton } from '~/ui';
import './ManageStopControlModal.scss';
import { ManageStopControlModalProps } from './types';

export const ManageStopControlModal = ({
    modalTitle,
    onClickTitle = () => {},
    children,
    className,
    'data-testid': dataTestId
}: ManageStopControlModalProps) => {
    const ROOT_CLASS_NAME = 'managestopcontrol_modal';
    const elmentClassName = classNames(
        ROOT_CLASS_NAME,
        '_fd-column',
        className
    );
    const modalHeaderClassName = classNames(`${ROOT_CLASS_NAME}-header`, {
        [`${className}-header`]: className
    });
    const modalContentClassName = classNames(
        `${ROOT_CLASS_NAME}-content`,
        '_fd-column',
        {
            [`${className}-content`]: className
        }
    );

    const elementDataTestId = dataTestId || ROOT_CLASS_NAME;
    const modalHeaderTestId = `${elementDataTestId}-header`;
    const modalHeaderButtonTestId = `${elementDataTestId}-header_button`;
    const modalHeaderTitleTestId = `${elementDataTestId}-header_title`;
    const modalContentTestId = `${elementDataTestId}-content`;

    return (
        <div className={elmentClassName} data-testid={elementDataTestId}>
            {modalTitle && (
                <div
                    className={modalHeaderClassName}
                    data-testid={modalHeaderTestId}
                >
                    <TooltipButton
                        onClick={onClickTitle}
                        className="button_clear"
                        data-testid={modalHeaderButtonTestId}
                    >
                        <Icon
                            icon="chevronDown"
                            color="galaxy-800"
                            className="_t-rotate-90"
                        />
                        <Text
                            className="_text-2"
                            variant="14-normal"
                            color="galaxy-800"
                            data-testid={modalHeaderTitleTestId}
                        >
                            {modalTitle}
                        </Text>
                    </TooltipButton>
                </div>
            )}
            {children && (
                <div
                    className={modalContentClassName}
                    data-testid={modalContentTestId}
                >
                    {children}
                </div>
            )}
        </div>
    );
};
