import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { useIntermodalFeatures } from '~/hooks';
import {
    GetMultiLiveStopActionsParams,
    GetSingleLiveStopActionsParams,
    LiveStopsControlAvailableActions
} from './types';

export const useLiveStopsControlActions = () => {
    const { enableEndOfShift } = useIntermodalFeatures();
    const mainClient = useSelector(selectMainClient);
    const enableCancel = Boolean(
        mainClient?.preferences?.showDispatchCancelButton
    );

    const getSingleLiveStopActions = useCallback(
        ({
            selectedStop
        }: GetSingleLiveStopActionsParams): LiveStopsControlAvailableActions => {
            const { isAssigned, isInProgress } = selectedStop;

            const showResequence = isAssigned;
            const showUnassign = isAssigned;
            const showCancel = enableCancel && isAssigned;
            const showChangeTaskStatus = isAssigned || isInProgress;
            const showMarkEndOfShift = enableEndOfShift;

            return {
                showResequence,
                showUnassign,
                showCancel,
                showChangeTaskStatus,
                showMarkEndOfShift
            };
        },
        [enableEndOfShift, enableCancel]
    );

    const getMultiLiveStopActions = useCallback(
        ({
            selectedStops,
            isTwoPartTask
        }: GetMultiLiveStopActionsParams): LiveStopsControlAvailableActions => {
            const isAllAssigned = selectedStops.every(
                ({ isAssigned }) => isAssigned
            );
            const isAssignedOrInProgress = selectedStops.some(
                ({ isAssigned, isInProgress }) => isAssigned || isInProgress
            );

            const showResequence = !isTwoPartTask && isAllAssigned;
            const showUnassign = isAllAssigned;
            const showCancel = enableCancel && isAllAssigned;
            const showChangeTaskStatus =
                isTwoPartTask && isAssignedOrInProgress;
            const showMarkEndOfShift = isTwoPartTask && enableEndOfShift;

            return {
                showResequence,
                showUnassign,
                showCancel,
                showChangeTaskStatus,
                showMarkEndOfShift
            };
        },
        [enableEndOfShift, enableCancel]
    );

    return {
        getSingleLiveStopActions,
        getMultiLiveStopActions
    };
};
