import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, TooltipButton } from '~/ui';

import { useToastContext } from '~/hooks';
import { getErrorMessage } from '~/utils/errorUtils';

import { ChangeTaskStatusModalReviseStatus } from '../ChangeTaskStatusModalReviseStatus';
import { ChangeTaskStatusModalReviseTime } from '../ChangeTaskStatusModalReviseTime';
import { ChangeTaskStatusModalSelectedTask } from '../ChangeTaskStatusModalSelectedTask';
import { useLiveStopsControl } from '../../useLiveStopsControl';
import { useChangeTaskStatusStopData } from '../useChangeTaskStatusStopData';
import { useChangeTaskStatus } from '../useChangeTaskStatus';

import { AssignmentStatus } from '~/api/types';
import { ChangeTaskStatusStopData } from '../useChangeTaskStatusStopData/types';
import { ChangeTaskStatusModalContentProps } from './types';

import './ChangeTaskStatusModalContent.scss';

export const ChangeTaskStatusModalContent = ({
    stopData,
    onHideModal
}: ChangeTaskStatusModalContentProps) => {
    const ROOT_CLASS_NAME = 'change-task-status-modal-content';

    const [selectedStopId, setSelectedStopId] = useState<string | undefined>();

    const { handleDeselectAll } = useLiveStopsControl();

    const {
        isChangeTaskStatusReady,
        changeTaskStatusPayload,
        taskTypeStopData,
        currentArrival,
        currentCompleted,
        currentStatus,
        revisedStatus,
        setRevisedStatus,
        revisedArrival,
        setRevisedArrival,
        revisedCompleted,
        setRevisedCompleted
    } = useChangeTaskStatusStopData({ stopData, selectedStopId });

    const { changeTaskStatus } = useChangeTaskStatus();

    const { addToast } = useToastContext();

    const { t } = useTranslation('changeTaskStatus');

    const handleClickChangeTaskStatus = useCallback(async () => {
        const params = {
            stopData: taskTypeStopData as ChangeTaskStatusStopData,
            status: revisedStatus as AssignmentStatus,
            payload: changeTaskStatusPayload
        };

        const taskName = taskTypeStopData?.selected?.stopName || '';

        const statusMap: Record<AssignmentStatus, string> = {
            [AssignmentStatus.ASSIGNED]: t('reviseStatus.assigned'),
            [AssignmentStatus.IN_PROGRESS]: t('reviseStatus.inProgress'),
            [AssignmentStatus.COMPLETED]: t('reviseStatus.completed'),
            [AssignmentStatus.CANCELED]: t('reviseStatus.canceled')
        };
        const status = statusMap[params.status];

        try {
            await changeTaskStatus(params);
            addToast({
                message: t('toast.successChangeTaskStatus', {
                    taskName,
                    status
                }),
                variant: 'success'
            });
            onHideModal();
            handleDeselectAll();
        } catch (error) {
            const message = getErrorMessage(error);
            console.error(message);
            addToast({
                message,
                variant: 'error'
            });
        }
    }, [
        changeTaskStatusPayload,
        taskTypeStopData,
        revisedStatus,
        handleDeselectAll,
        changeTaskStatus,
        onHideModal,
        addToast,
        t
    ]);

    return (
        <div
            className={`${ROOT_CLASS_NAME} _fd-column`}
            data-testid={ROOT_CLASS_NAME}
        >
            <div
                className={`${ROOT_CLASS_NAME}__header _d-flex _jc-space-between _ai-center`}
                data-testid={`${ROOT_CLASS_NAME}__header`}
            >
                <div
                    className={`${ROOT_CLASS_NAME}__title`}
                    data-testid={`${ROOT_CLASS_NAME}__title`}
                >
                    {t('title')}
                </div>
                <IconButton
                    className={`${ROOT_CLASS_NAME}__close icon-wrapper`}
                    data-testid={`${ROOT_CLASS_NAME}__close`}
                    icon="cancel"
                    iconColor="galaxy-800"
                    onClick={onHideModal}
                />
            </div>
            <div
                className={`${ROOT_CLASS_NAME}__body _fd-column`}
                data-testid={`${ROOT_CLASS_NAME}__body`}
            >
                <ChangeTaskStatusModalSelectedTask
                    stopData={taskTypeStopData}
                    selectedStopId={selectedStopId}
                    setSelectedStopId={setSelectedStopId}
                />
                <ChangeTaskStatusModalReviseStatus
                    currentStatus={currentStatus}
                    revisedStatus={revisedStatus}
                    setRevisedStatus={setRevisedStatus}
                />
                <ChangeTaskStatusModalReviseTime
                    status={revisedStatus}
                    currentArrival={currentArrival}
                    currentCompleted={currentCompleted}
                    revisedArrival={revisedArrival}
                    revisedCompleted={revisedCompleted}
                    setRevisedArrival={setRevisedArrival}
                    setRevisedCompleted={setRevisedCompleted}
                />
            </div>
            <div
                className={`${ROOT_CLASS_NAME}__footer _d-flex _jc-flex-end`}
                data-testid={`${ROOT_CLASS_NAME}__footer`}
            >
                <TooltipButton
                    className="modal-button"
                    variant={TooltipButton.variants.SECONDARY_ALT}
                    size={TooltipButton.sizes.LARGE}
                    onClick={onHideModal}
                    data-testid={`${ROOT_CLASS_NAME}__cancel`}
                >
                    {t('button.cancel')}
                </TooltipButton>
                <TooltipButton
                    className="modal-button"
                    variant={TooltipButton.variants.PRIMARY}
                    size={TooltipButton.sizes.LARGE}
                    disabled={!isChangeTaskStatusReady}
                    onClick={handleClickChangeTaskStatus}
                    data-testid={`${ROOT_CLASS_NAME}__confirm`}
                >
                    {t('button.confirm')}
                </TooltipButton>
            </div>
        </div>
    );
};
