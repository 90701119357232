import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectShowUnassignedTasks } from '~/reducers/mapSettingsSlice';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { selectSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
import { selectTasksV2ApiPaginationOptions } from '~/reducers/tasksV2Slice';

import {
    useIntermodalFeatures,
    useIsolatedRoutes,
    useLiveDispatchTasks,
    useLiveDispatchUnassignedTasksV2,
    useMapUtils
} from '~/hooks';
import { DEFAULT_PAGINATION_OPTIONS } from '~/hooks/useLiveDispatchTasks';
import { markerUtils } from '~/utils/map';

import { UseDispatchedUnassignedTaskReturnValue } from './types';

export const useDispatchedUnassignedTasks =
    (): UseDispatchedUnassignedTaskReturnValue => {
        const { mapRouteMode, isDispatchedRouteMode } = useMapUtils();
        const selectedTaskIds = useSelector(selectSelectedTaskIds);
        const tasksV2ApiPaginationOptions = useSelector(
            selectTasksV2ApiPaginationOptions
        );
        const { isRouteMultiSelectInProgress } = useIsolatedRoutes();

        const showUnassignedTasks = useSelector(
            selectShowUnassignedTasks(
                mapRouteMode as unknown as ConfigurableMapRouteMode
            )
        );
        const { enableLiveDispatch } = useIntermodalFeatures();

        const { unassignedTasks: allUnassignedTasks } = useLiveDispatchTasks({
            selectedTaskIds,
            apiPaginationOptions: DEFAULT_PAGINATION_OPTIONS
        });

        const { unassignedTasks: filteredUnassignedTasks } =
            useLiveDispatchUnassignedTasksV2({
                selectedTaskIds,
                apiPaginationOptions: tasksV2ApiPaginationOptions
            });

        const unassignedTasks = useMemo(
            () =>
                enableLiveDispatch
                    ? filteredUnassignedTasks
                    : allUnassignedTasks,
            [enableLiveDispatch, filteredUnassignedTasks, allUnassignedTasks]
        );

        return useMemo(() => {
            if (
                !isDispatchedRouteMode ||
                !showUnassignedTasks ||
                !unassignedTasks.length ||
                isRouteMultiSelectInProgress
            ) {
                return {
                    unassignedTasks: [],
                    unassignedTaskMarkerCoordinates: []
                };
            }

            const unassignedTaskMarkerCoordinates = unassignedTasks
                .flat(Infinity)
                .map((taskData) => {
                    return markerUtils.getTaskCoordinates(taskData);
                });

            return {
                unassignedTasks,
                unassignedTaskMarkerCoordinates
            };
        }, [
            isRouteMultiSelectInProgress,
            isDispatchedRouteMode,
            showUnassignedTasks,
            unassignedTasks
        ]);
    };
