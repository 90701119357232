import {
    isTwoPartStopData,
    isTwoPartPickupChangeRequired
} from '../useChangeTaskStatusStopData/utils';
import { ChangeTaskStatusStopData } from '../useChangeTaskStatusStopData/types';
import { ChangeTaskStatusModalSelectedTaskAlert } from './types';

const getChangeTaskStatusModalSelectedTaskAlert = (
    stopData?: ChangeTaskStatusStopData,
    selectedStopId?: string
) => {
    const isTwoPartStop = isTwoPartStopData(stopData);
    if (!isTwoPartStop) return;

    const { pickup: pickupStopData, delivery: deliveryStopData } = stopData;

    const isPickupChangeRequired =
        isTwoPartPickupChangeRequired(pickupStopData);

    const isDeliverySelected = deliveryStopData.id === selectedStopId;

    if (!isPickupChangeRequired) {
        return ChangeTaskStatusModalSelectedTaskAlert.PICKUP;
    }

    if (isDeliverySelected && isPickupChangeRequired) {
        return ChangeTaskStatusModalSelectedTaskAlert.DELIVERY;
    }
};

export const getChangeTaskStatusModalPreselectedTask = (
    stopData?: ChangeTaskStatusStopData,
    selectedStopId?: string
) => {
    let preselectedStopId;
    const alert = getChangeTaskStatusModalSelectedTaskAlert(
        stopData,
        selectedStopId
    );

    if (alert === ChangeTaskStatusModalSelectedTaskAlert.PICKUP) {
        preselectedStopId = stopData?.delivery?.id;
    }

    return {
        alert,
        preselectedStopId
    };
};
