/* eslint-disable camelcase */

export enum ApiInventoryItemType {
    PICKUP = 'pickup',
    DELIVERY = 'delivery'
}

export enum ApiInventoryItemStatus {
    CONFIRMED = 'confirmed',
    UNCONFIRMED = 'unconfirmed',
    CANCELLED = 'cancelled',
    TRY_LATER = 'try-later',
    PARTIAL = 'partial'
}

export interface ApiInventoryItem {
    id?: string;
    task_id?: string;
    assignment_id?: string;
    client_id?: string;
    type?: ApiInventoryItemType;
    item_id?: string;
    item_name?: string;
    item_detail?: string;
    order_id?: string;
    barcode_id?: string;
    /**
     * the inventory item expected quantity
     *
     * this is the quantity that is planned to be delivered or picked-up
     */
    expected_quantity?: number;
    /**
     * the inventory item actual quantity
     *
     * This is the quantity that is delivered or picked-up.
     * This is expected to be set when the `status` is `confirmed` or `partial`.
     *
     * This can be lower than `expected_quantity`, in which case an `inventory excemption` will be flagged for the task
     */
    actual_quantity?: number;
    unit_price?: number;
    unit_weight?: number;
    unit_type?: string;
    dimensions?: string;
    status?: ApiInventoryItemStatus;
    reason_code?: string;
    /**
     * the inventory item invoice ID
     *
     * This is a WISE invoice UUID
     */
    invoice_id?: string;
    /**
     * the inventory item invoice number
     *
     * This is a client provided value
     */
    invoice_number?: string;
    /**
     * Date in ISO format
     */
    scanned_at?: string;

    /**
     * Date in ISO format
     */
    scanned_delivered_at?: string;
    signature_url?: string;
    signature_skipped?: boolean;
    signatory_name?: string;
    photo_url?: string;
    photo_urls?: string[];
    notes?: string;
    props?: Record<string, unknown>;

    /**
     * Date in ISO format
     */
    updated_at?: string;
    dropoff_location?: string;
}
