/**
 * Available downloadable report categories
 */
export enum DownloadableReportCategories {
    /**
     * Standard or Core report category
     *
     * reports categorized as `core` are standard reports available to clients
     */
    CORE = 'core',

    /**
     * Custom report category
     *
     * reports categorized as `custom` are non-standard reports available to select clients
     */
    CUSTOM = 'custom',

    /**
     * Unknown report category
     *
     * custom reports that are currently not mapped to localized data
     *
     * the application still needs to display these.
     * but, it should be clear that these will need additional configuration later
     */
    UNKNOWN = 'unknown'
}
