import { useMemo } from 'react';
import Supercluster from 'supercluster';
import { useIntermodalFeatures, useMapUtils } from '~/hooks';
import { useSelector } from 'react-redux';
import { markerUtils } from '~/utils/map';
import {
    selectDispatchedDrivers,
    selectCompletedDrivers
} from '~/reducers/liveDriversSlice';
import { selectSelectedMapRoutes } from '~/reducers/selectedMapRoutesSlice';

import constants from '~/utils/constants';
import taskUtils from '~/utils/task-utils';
import { LiveStop } from '~/data-classes';

const clusterRadius = constants.mapOptionSettings.DEFAULT_CLUSTER_RADIUS;
const maxZoom = constants.mapOptionSettings.MAX_ZOOM;

export const useLiveStopsSuperClusters = () => {
    const { isStopsClustersMode, isDispatchedRouteMode } = useMapUtils();
    const { enableLiveDispatch } = useIntermodalFeatures();
    const dispatchedDrivers = useSelector(selectDispatchedDrivers);
    const completedDrivers = useSelector(selectCompletedDrivers);
    const selectedMapRoutes = useSelector(selectSelectedMapRoutes);

    const liveDrivers = useMemo(() => {
        if (enableLiveDispatch && isDispatchedRouteMode) {
            return [...dispatchedDrivers, ...completedDrivers];
        }

        return isDispatchedRouteMode ? dispatchedDrivers : completedDrivers;
    }, [
        enableLiveDispatch,
        isDispatchedRouteMode,
        completedDrivers,
        dispatchedDrivers
    ]);

    const filteredDrivers = useMemo(() => {
        return liveDrivers.filter((driver) => {
            const { clientId, id } = driver;
            const mapRouteId = `${clientId}_${id}`;
            return selectedMapRoutes.includes(mapRouteId);
        });
    }, [liveDrivers, selectedMapRoutes]);

    const liveSchedules = useMemo(() => {
        if (!isStopsClustersMode || !filteredDrivers.length) {
            return [];
        }

        return filteredDrivers.flatMap((liveDriver) => {
            const { clientId } = liveDriver;
            return liveDriver.schedule
                .filter((liveStop: LiveStop) => !liveStop.isDepot)
                .map(
                    (liveStop: {
                        location: { location?: { lat: number; lng: number } };
                    }) => {
                        const { location } = liveStop;
                        const payload = {
                            ...liveStop,
                            clientId
                        };
                        const { lat, lng } =
                            taskUtils.getTaskLocationLatLng(location);
                        return markerUtils.getGeoJSONFeaturePoint(
                            lat,
                            lng,
                            payload
                        );
                    }
                );
        });
    }, [filteredDrivers, isStopsClustersMode]);

    const superClusters = useMemo(() => {
        const superClusterEffects = [];
        const superCluster = new Supercluster({
            radius: clusterRadius,
            maxZoom
        });
        superCluster.load(liveSchedules);
        superClusterEffects.push(superCluster);
        return superClusterEffects;
    }, [liveSchedules]);

    return {
        superClusters
    };
};
